/**
 * To validate string is valid url.
 * @param {string} value
 */
export function isValidUrl(value) {
  try {
    return Boolean(new URL(value));
  } catch (error) {
    return false;
  }
}
