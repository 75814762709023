import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Snackbar,
  Typography,
} from "@tsc/component-library/lib/components";

import { removeNotification, selectNotification } from "../notificationSlice";

const Notifications = () => {
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();
  const handleClose = useCallback(
    () => dispatch(removeNotification()),
    [dispatch]
  );

  return notification ? (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!notification}
      autoHideDuration={notification.autoHide ? 3000 : null}
      onClose={handleClose}
      action={notification?.action}
      sx={{ top: "88px !important" }}
    >
      <Alert
        variant="filled"
        severity={notification.severity ?? "info"}
        onClose={notification.closable ? handleClose : null}
        sx={{ width: "460px" }}
      >
        <Typography variant="h6">{notification.title}</Typography>
        <Typography variant="body2">{notification.message}</Typography>
      </Alert>
    </Snackbar>
  ) : null;
};

export default Notifications;
