import { STAKEHOLDERS_SORT_BY } from "enums/stakeholderSortBy";

import api from "api";
import {
  addNotification,
  serverErrorNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";

const API_ROUTE = "stakeholders/api/v1/stakeholders";

const tagProvider = (result, error, arg) => {
  if (error) {
    return [];
  }
  return [{ type: "stakeholders", id: arg.stakeholderId }];
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStakeholders: build.query({
      query: (args) => {
        const params = { ...args?.params };
        if (!params.sortBy) {
          params.sortBy = params.name
            ? STAKEHOLDERS_SORT_BY.SEARCH_RELEVANCE
            : STAKEHOLDERS_SORT_BY.NAME;
        }
        return { url: API_ROUTE, params: params };
      },
    }),
    getStakeholderById: build.query({
      query: ({ stakeholderId, params }) => ({
        url: `${API_ROUTE}/${stakeholderId}`,
        params: params,
      }),
      providesTags: tagProvider,
    }),
    reingestAttribute: build.mutation({
      query: ({ stakeholderId, stakeholderAttributeId }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholderAttributes/${stakeholderAttributeId}/reingest`,
        method: "POST",
      }),
      invalidatesTags: tagProvider,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message:
                "Re-ingestion of stakeholder attributes has been initiated",
              severity: SEVERITY.SUCCESS,
              autoHide: true,
            })
          );
        } catch (error) {
          dispatch(serverErrorNotification(error.message));
        }
      },
    }),
    unlinkAttribute: build.mutation({
      query: ({ stakeholderId, stakeholderAttributeId }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholderAttributes/${stakeholderAttributeId}`,
        method: "DELETE",
      }),
      invalidatesTags: tagProvider,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Attribute unlinked",
              severity: SEVERITY.SUCCESS,
              autoHide: true,
            })
          );
        } catch (error) {
          dispatch(serverErrorNotification(error.message));
        }
      },
    }),
    patchStakeholder: build.mutation({
      query: ({ stakeholderId, stakeholder }) => ({
        url: `${API_ROUTE}/${stakeholderId}`,
        method: "PATCH",
        body: stakeholder,
      }),
      invalidatesTags: tagProvider,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Stakeholder updated",
              severity: SEVERITY.SUCCESS,
              autoHide: true,
            })
          );
        } catch (error) {
          dispatch(serverErrorNotification(error.message));
        }
      },
    }),
    upsertStakeholderAttribute: build.mutation({
      query: ({ stakeholderId, stakeholderAttribute }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholder-attribute`,
        method: "POST",
        body: stakeholderAttribute,
      }),
      invalidatesTags: tagProvider,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Stakeholder attribute updated",
              severity: SEVERITY.SUCCESS,
              autoHide: true,
            })
          );
        } catch (error) {
          dispatch(serverErrorNotification(error.message));
        }
      },
    }),
    linkAttributeToExistingStakeholder: build.mutation({
      query: ({ stakeholderId, newStakeholderId, stakeholderAttributeId }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholderAttributes/${stakeholderAttributeId}`,
        method: "PATCH",
        body: { newStakeholderId: newStakeholderId },
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) {
          return [];
        }
        return [
          { type: "stakeholders", id: arg.stakeholderId },
          { type: "stakeholders", id: arg.newStakeholderId },
        ];
      },
    }),
    linkAttributeToNewStakeholder: build.mutation({
      query: ({ stakeholderId, stakeholderAttributeId, type }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholderAttributes/${stakeholderAttributeId}/recreate`,
        method: "POST",
        body: { type: type },
      }),
      invalidatesTags: tagProvider,
    }),
    deleteStakeholderAttribute: build.mutation({
      query: ({ stakeholderId, stakeholderAttributeId, type }) => ({
        url: `${API_ROUTE}/${stakeholderId}/stakeholderAttributes/${stakeholderAttributeId}`,
        method: "DELETE",
      }),
      invalidatesTags: tagProvider,
    }),
  }),
});

export const {
  useGetStakeholdersQuery,
  useGetStakeholderByIdQuery,
  useReingestAttributeMutation,
  useUnlinkAttributeMutation,
  usePatchStakeholderMutation,
  useUpsertStakeholderAttributeMutation,
  useLinkAttributeToExistingStakeholderMutation,
  useLinkAttributeToNewStakeholderMutation,
  useDeleteStakeholderAttributeMutation,
} = extendedApi;
