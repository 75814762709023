/* eslint-disable no-restricted-globals */
export const isLocal = () =>
  location.hostname === "localhost" ||
  location.hostname === "127.0.0.1" ||
  location.hostname.includes("192.168");

export const isDev = () =>
  location.hostname.includes("genfive.dev") ||
  location.hostname.includes("genie.dev") ||
  location.hostname.includes("genfive-admin-dev");

export const isUat = () =>
  location.hostname.includes("genfive.uat") ||
  location.hostname.includes("genie.uat") ||
  location.hostname.includes("genfive-admin-uat");

export const isProd = () =>
  location.hostname.includes("genfive.tsc") ||
  location.hostname.includes("genie.tsc") ||
  location.hostname.includes("genfive-admin-prod");

export const backendAddress = () => {
  if (isLocal() && !!process.env.REACT_APP_BACKEND_URL)
    return process.env.REACT_APP_BACKEND_URL;
  if (isLocal()) return "http://localhost:8080";
  if (isDev()) return "https://api.genfive.dev.tsc.ai";
  if (isUat()) return "https://api.genfive.uat.tsc.ai";
  return "https://api.genfive.tsc.ai";
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const isFirefox =
  navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
export const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);

export const getRedirectPath = () =>
  `${window.location.pathname ?? ""}
  ${window.location.search ?? ""}
  ${window.location.hash ?? ""}`;

export const bounded = (num, min, max) => Math.min(Math.max(num, min), max);
