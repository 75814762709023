import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilterAutocomplete,
  FormControlLabel,
  IconButton,
  MaterialSymbol,
  Radio,
  RadioGroup,
  Typography,
} from "@tsc/component-library/lib/components";
import { SYMBOL_SIZES } from "@tsc/component-library/lib/components/MaterialSymbol/MaterialSymbol";
import { STAKEHOLDER_TYPE_LABEL } from "enums/stakeholderType";

import {
  useLinkAttributeToExistingStakeholderMutation,
  useLinkAttributeToNewStakeholderMutation,
} from "api/stakeholders";
import {
  addNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";
import StakeholderIdDropdown from "features/stakeholders/StakeholderIdDropdown/StakeholderIdDropdown";

const LINK_ATTRIBUTE_OPTION = {
  NEW_STAKEHOLDER: "NEW_STAKEHOLDER",
  EXISTING_STAKEHOLDER: "EXISTING_STAKEHOLDER",
};

const UnlinkAttributeDialog = ({
  stakeholder,
  stakeholderAttribute,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [linkOption, setLinkOption] = useState(
    LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER
  );
  const [selectedStakeholderId, setSelectedStakeholderId] = useState(null);
  const [selectedStakeholderType, setSelectedStakeholderType] = useState(null);
  const isFormValid =
    linkOption === LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER
      ? !!selectedStakeholderId
      : !!selectedStakeholderType;

  const [
    linkToExistingStakeholder,
    { isLoading: isLinkingToExistingStakeholder },
  ] = useLinkAttributeToExistingStakeholderMutation();
  const [linkToNewStakeholder, { isLoading: isLinkingToNewStakeholder }] =
    useLinkAttributeToNewStakeholderMutation();
  const isLoading = isLinkingToExistingStakeholder || isLinkingToNewStakeholder;

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const handleConfirm = async () => {
    try {
      const relinkAttribute =
        linkOption === LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER
          ? linkToExistingStakeholder
          : linkToNewStakeholder;
      const response = await relinkAttribute({
        stakeholderId: stakeholder.id,
        stakeholderAttributeId: stakeholderAttribute.id,
        newStakeholderId: selectedStakeholderId,
        type: selectedStakeholderType,
      }).unwrap();

      dispatch(
        addNotification({
          message: "Attribute updated",
          severity: SEVERITY.SUCCESS,
          autoHide: true,
        })
      );

      onClose();

      const newStakeholderId =
        linkOption === LINK_ATTRIBUTE_OPTION.NEW_STAKEHOLDER
          ? response.id
          : selectedStakeholderId;
      window.open(`/stakeholders/${newStakeholderId}`, "_blank");
    } catch (error) {}
  };

  useEffect(() => {
    if (open) {
      setLinkOption(LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER);
      setSelectedStakeholderId(null);
      setSelectedStakeholderType(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Do you want to unlink this source?
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={handleClose}
          disabled={isLoading}
        >
          <MaterialSymbol symbol="close" size={SYMBOL_SIZES.SMALL} />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ width: 600 }}>
        <Typography>
          You are about to unlink this source
          <Typography fontWeight="600" component="span">
            {` [${stakeholderAttribute?.source ?? ""}:${
              stakeholderAttribute?.sourceId ?? ""
            }] `}
          </Typography>
          from the stakeholder.
        </Typography>

        <Typography mt={2}>
          Choose another stakeholder to link this source to:
        </Typography>

        <RadioGroup
          row
          value={linkOption}
          onChange={(event, value) => setLinkOption(value)}
          sx={{ pt: 0, pb: 0.5 }}
        >
          <FormControlLabel
            value={LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER}
            control={<Radio />}
            label="Select existing Stakeholder"
          />
          <FormControlLabel
            value={LINK_ATTRIBUTE_OPTION.NEW_STAKEHOLDER}
            control={<Radio />}
            label="Create new Stakeholder"
          />
        </RadioGroup>

        {linkOption === LINK_ATTRIBUTE_OPTION.EXISTING_STAKEHOLDER && (
          <StakeholderIdDropdown
            disabled={isLoading}
            label="Stakeholder"
            value={selectedStakeholderId}
            onChange={(value) => setSelectedStakeholderId(value)}
            filterOptions={(options) =>
              options?.filter((option) => option !== stakeholder?.id)
            }
          />
        )}

        {linkOption === LINK_ATTRIBUTE_OPTION.NEW_STAKEHOLDER && (
          <FilterAutocomplete
            boxProps={{ width: "100%" }}
            label="Stakeholder Type"
            placeholder=""
            options={Object.keys(STAKEHOLDER_TYPE_LABEL)}
            getOptionLabel={(key) => STAKEHOLDER_TYPE_LABEL[key] || ""}
            value={selectedStakeholderType}
            onChange={(_, value) => setSelectedStakeholderType(value)}
          />
        )}

        <Alert severity="info" sx={{ mt: 1.5 }}>
          Connections and contact details will also be moved to the selected
          stakeholder.
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={isLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={isLoading || !isFormValid}
          startIcon={
            isLoading ? <CircularProgress size={14} color="inherit" /> : null
          }
        >
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnlinkAttributeDialog;
