import React from "react";
import { RouterProvider } from "react-router";
import {
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
} from "@tsc/component-library/lib/components";
import lightTheme from "@tsc/component-library/lib/lightTheme";

import "configurations/mui-x.js";

import { router } from "./configurations/router";
import Notifications from "./features/notifications/Notifications/Notifications";

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline>
        <Notifications />
        <RouterProvider router={router} />
      </CssBaseline>
      <GlobalStyles styles={{ body: { backgroundColor: "#fafafa" } }} />
    </ThemeProvider>
  );
};

export default App;
