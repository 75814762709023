import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputSearch,
  Stack,
} from "@tsc/component-library/lib/components";
import { pick } from "lodash";

import DropdownIssueType from "features/issues/IssueFilter/DropdownIssueType";
import DropdownOrganization from "features/issues/IssueFilter/DropdownOrganization";
import DropdownSortBy from "features/issues/IssueFilter/DropdownSortBy";

const DEFAULT_FILTER = {
  name: "",
  sortBy: null,
  type: null,
  organizationId: null,
};

const IssueFilter = ({ parentFilters, disabledSearch, onSearch }) => {
  const [filters, setFilters] = useState({
    ...DEFAULT_FILTER,
    ...pick(parentFilters, ["name", "sortBy", "type", "organizationId"]),
  });

  const handleChange = (value) => {
    setFilters((prevValue) => ({ ...prevValue, ...value }));
  };

  const handleSearch = () => {
    onSearch({ ...filters, page: 1 });
  };

  return (
    <Stack p={2} spacing={2}>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems="end"
        spacing={1}
      >
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={1}>
          <InputSearch
            fullWidth
            value={filters.name}
            onChange={(e) => handleChange({ name: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === "Enter" && filters.name !== parentFilters?.name) {
                e.preventDefault();
                handleSearch();
              }
            }}
            size="small"
            placeholder="Search issues"
          />
        </Box>
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <DropdownSortBy
            value={filters.sortBy}
            onChange={(_, sortBy) => handleChange({ sortBy })}
          />
        </Box>
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <DropdownIssueType
            value={filters.type}
            onChange={(_, type) => {
              if (type === "libraryIssue") {
                handleChange({ type, organizationId: null });
                return;
              }
              handleChange({ type });
            }}
          />
        </Box>
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <DropdownOrganization
            disabled={filters.type === "libraryIssue"}
            value={filters.organizationId}
            onChange={(_, organizationId) => handleChange({ organizationId })}
          />
        </Box>
        <Box width={{ xs: "100%", lg: "104px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <Button
            disabled={disabledSearch}
            variant="contained"
            size="small"
            fullWidth
            onClick={handleSearch}
            sx={{ height: "40px" }}
            endIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

IssueFilter.defaultProps = {
  parentFilters: null,
  disabledSearch: false,
  onSearch: () => {},
};

export default IssueFilter;
