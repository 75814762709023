import { SvgIcon } from "@tsc/component-library/lib/components";

import { ReactComponent as TSCLogoText } from "assets/vectors/tsc_logo_text.svg";

export default function TSCLogo() {
  return (
    <SvgIcon
      component={TSCLogoText}
      viewBox="0 0 67 39"
      sx={{ width: "160px", height: "96px", ml: "-8px", my: "32px" }}
    />
  );
}
