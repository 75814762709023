import React, { forwardRef } from "react";
import { SvgIcon } from "@tsc/component-library/lib/components";

const StakeholderCuratedIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path
        d="M8.81159 21.8652L7.02709 18.8577L3.63859 18.1152L3.96934 14.627L1.67334 12L3.96934 9.37298L3.63859 5.88474L7.02709 5.14223L8.81159 2.13474L12.0001 3.48848L15.1886 2.13474L16.9731 5.14223L20.3616 5.88474L20.0308 9.37298L22.3268 12L20.0308 14.627L20.3616 18.1152L16.9731 18.8577L15.1886 21.8652L12.0001 20.5115L8.81159 21.8652ZM9.45009 19.95L12.0001 18.8692L14.5808 19.95L16.0001 17.55L18.7501 16.9192L18.5001 14.1L20.3501 12L18.5001 9.86924L18.7501 7.04998L16.0001 6.44999L14.5501 4.04998L12.0001 5.13073L9.41934 4.04998L8.00009 6.44999L5.25009 7.04998L5.50009 9.86924L3.65009 12L5.50009 14.1L5.25009 16.95L8.00009 17.55L9.45009 19.95ZM10.9501 15.2037L16.2538 9.89998L15.2001 8.81548L10.9501 13.0655L8.80009 10.9462L7.74634 12L10.9501 15.2037Z"
        fill="#00B5FE"
      />
    </SvgIcon>
  );
};

export default forwardRef(StakeholderCuratedIcon);
