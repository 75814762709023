import { createSlice } from "@reduxjs/toolkit";

export const SEVERITY = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};

const initialState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, { payload: notification }) => {
      state.notifications = [...state.notifications, notification];
    },
    removeNotification: (state) => {
      const [, ...notifications] = state.notifications;
      state.notifications = notifications;
    },
    serverErrorNotification: (state, { payload }) => {
      state.notifications = [
        ...state.notifications,
        {
          title: "An error occured",
          message:
            payload?.message ?? "An unexpected error occured, we're on it.",
          severity: SEVERITY.ERROR,
        },
      ];
    },
    accessDeniedNotification: (state, { payload }) => {
      state.notifications = [
        ...state.notifications,
        {
          title: "Access Denied",
          message:
            payload?.message ??
            "You are not authorized to perform this action.",
          severity: SEVERITY.ERROR,
        },
      ];
    },
  },
});

export const {
  addNotification,
  removeNotification,
  serverErrorNotification,
  accessDeniedNotification,
} = notificationSlice.actions;

export const selectNotification = (state) =>
  state.notifications.notifications[0] ?? null;

export default notificationSlice.reducer;
