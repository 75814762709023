import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import axios from "configurations/axios";
import { addNotification } from "features/notifications/notificationSlice";
import { backendAddress } from "utilities/env";

const Generation = () => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const handleGenerate = () => {
    setLoading(true);
    axios
      .post(`${backendAddress()}/stakeholders/api/v1/generate`, {
        description,
      })
      .then((response) => {
        setProfiles(response?.data?.data ?? []);
      })
      .catch((error) => {
        dispatch(addNotification(error.message));
      })
      .finally(() => setLoading(false));
  };
  return (
    <Page>
      <SubPage
        empty={false}
        emptyMessage="We couldn't find any stakeholder matching your search criteria. Please
          adjust your filters or reach out to the Tech
          Team for assistance."
      >
        <Stack gap={2}>
          <Stack direction="row" gap={2} alignItems="center">
            <TextField
              fullWidth
              placeholder="Type your stakeholder name here"
              onChange={(e) => {
                setDescription(e.target.value);
                setProfiles([]);
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") handleGenerate();
              }}
            />
            <Button
              variant="contained"
              onClick={handleGenerate}
              disabled={!description.length || loading}
            >
              Generate
            </Button>

            <Typography
              color={description.length ? "text.primary" : "text.disabled"}
              sx={{ textWrap: "nowrap" }}
            >
              {description.length ? (
                <a
                  href={`https://www.google.com/search?q=${description}`}
                  target="blank"
                >
                  Google it
                </a>
              ) : (
                "Google it"
              )}
            </Typography>
          </Stack>
          {loading ? (
            <LinearProgress />
          ) : (
            <Typography variant="body1">{`${profiles.length} results`}</Typography>
          )}
          {profiles.map((profile, i) => (
            <Box key={`${profile.name} - ${profile.headline}`}>
              <Stack direction="row" gap={2}>
                <Box minWidth="100px" width="100px" textAlign="center">
                  <img
                    src={profile?.photos?.length ? profile?.photos[0].url : ""}
                    alt={profile.name}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  ></img>
                </Box>
                <Box flexGrow={1}>
                  <Typography variant="h6">
                    {profile.name}
                    <Typography variant="body1" component="span">
                      {" "}
                      - {profile.headline}
                    </Typography>
                  </Typography>
                  <Typography variant="body1">{profile.description}</Typography>
                  <Typography variant="caption" component="span">
                    {profile.explanation}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography variant="caption" component="span">
                        All photos:
                      </Typography>
                    </Box>
                    {profile?.photos?.map((photo) => (
                      <Box px={0.5}>
                        <img
                          src={photo?.url}
                          alt={profile.name}
                          style={{ maxWidth: "24px", maxHeight: "24px" }}
                        ></img>
                      </Box>
                    ))}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography variant="caption" component="span">
                        All sources:
                      </Typography>
                    </Box>
                    {profile?.sources?.map((source) => (
                      <Box px={0.5}>
                        <Typography variant="caption" component="span">
                          <a href={source.url}>{source.source}</a>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box sx={{ alignSelf: "center" }}>
                  <Button disabled variant={i === 0 ? "contained" : "outlined"}>
                    Create
                  </Button>
                </Box>
              </Stack>
              <Divider fullWidth orientation="horizontal" sx={{ mt: 2 }} />
            </Box>
          ))}
        </Stack>
      </SubPage>
    </Page>
  );
};

export default Generation;
