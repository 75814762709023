import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "@firebase/auth";

import { auth } from "configurations/firebase";

import { selectUser, selectUserLoaded, setUser } from "../authenticationSlice";
import AuthenticationMethods from "../SignInMethods/AuthenticationMethods";
const Authenticated = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userLoaded = useSelector(selectUserLoaded);
  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        const {
          displayName,
          email,
          emailVerified,
          photoURL,
          uid,
          isAnonymous,
        } = user;
        dispatch(
          setUser({
            displayName,
            email,
            emailVerified,
            photoURL,
            uid,
            isAnonymous,
          })
        );
      } else {
        dispatch(setUser(null));
      }
    });
  }, [dispatch]);

  if (!user && userLoaded) {
    return <AuthenticationMethods />;
  } else if (user && userLoaded) {
    return children;
  }
};

export default Authenticated;
