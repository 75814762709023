import React from "react";
import {
  Autocomplete,
  TextField,
  useTheme,
} from "@tsc/component-library/lib/components";

const SORT_OPTIONS = {
  "issues.name": "Alphabet",
  "issues.updatedAt": "Last modified",
};

const DropdownSortBy = ({ value, onChange, disabled }) => {
  const theme = useTheme();

  return (
    <Autocomplete
      value={value}
      options={Object.keys(SORT_OPTIONS)}
      getOptionLabel={(option) => SORT_OPTIONS[option]}
      onChange={onChange}
      disabled={disabled}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label="Sort by"
          InputLabelProps={{
            ...params.InputLabelProps,
            ...theme.components.MuiTextField.defaultProps.InputLabelProps,
          }}
        />
      )}
    />
  );
};

export default DropdownSortBy;
