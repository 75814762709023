import React from "react";
import {
  Box,
  Chip,
  ChipsSkeleton,
  MaterialSymbol,
} from "@tsc/component-library/lib/components";

export const StakeholderVisibilityChip = ({
  stakeholder,
  loading,
  ...otherProps
}) => {
  if (loading || !stakeholder) {
    return <ChipsSkeleton noOfChip={1} height={32} />;
  }

  return (
    <Chip
      label={stakeholder?.isVisible ? "Visible" : "Hidden"}
      variant={stakeholder?.isVisible ? "filled" : "outlined"}
      icon={
        <Box display="flex" fontSize="24px">
          {stakeholder?.isVisible ? (
            <MaterialSymbol symbol="visibility" />
          ) : (
            <MaterialSymbol symbol="visibility_off" />
          )}
        </Box>
      }
      {...otherProps}
    />
  );
};

export default StakeholderVisibilityChip;
