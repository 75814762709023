import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "auth/api/v1/organizations";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationRoles: build.query({
      query: ({ organizationId } = {}) =>
        `${API_ROUTE}/${organizationId}/roles`,
      providesTags: providesTagsList(TAG_TYPES.ORGANIZATION_ROLE),
    }),
    createOrganizationRole: build.mutation({
      query: ({ organizationId, role }) => ({
        url: `${API_ROUTE}/${organizationId}/roles`,
        method: "POST",
        body: role,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION_ROLE),
    }),
    updateOrganizationRole: build.mutation({
      query: ({ organizationId, roleId, role }) => ({
        url: `${API_ROUTE}/${organizationId}/roles/${roleId}`,
        method: "PUT",
        body: role,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION_ROLE),
    }),
    deleteOrganizationRole: build.mutation({
      query: ({ organizationId, roleId }) => ({
        url: `${API_ROUTE}/${organizationId}/roles/${roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION_ROLE),
    }),
  }),
});

export const {
  useGetOrganizationRolesQuery,
  useCreateOrganizationRoleMutation,
  useUpdateOrganizationRoleMutation,
  useDeleteOrganizationRoleMutation,
} = extendedApi;
