import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

import InputNumber from "components/Forms/InputNumber";

const SLIDER_MARKS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];

const IssuePromptForm = ({ index, disabled, onDelete }) => {
  const { control, getValues } = useFormContext();
  const prompt = getValues().issuePrompts[index];
  const formState = control.getFieldState(`issuePrompts.${index}`);

  return (
    <Paper elevation={4} sx={{ p: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" fontWeight={700}>
          <Controller
            name={`issuePrompts.${index}.selected`}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={disabled}
                checked={field.value ?? false}
              />
            )}
          />
          Prompt
          {prompt?.id && " - " + prompt.id}
          <IconButton
            color="error"
            disabled={disabled}
            onClick={() => onDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Typography>
        <Box ml={1.5}>
          <Controller
            name={`issuePrompts.${index}.active`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={field.value}
                    disabled={disabled}
                    {...field}
                  />
                }
                label={field.value ? "Publish" : "Unpublish"}
                labelPlacement="start"
              />
            )}
          />
        </Box>
      </Stack>

      <Controller
        name={`issuePrompts.${index}.prompt`}
        rules={{ required: "Definition is required." }}
        control={control}
        render={({ field }) => (
          <TextField
            label="Definition"
            fullWidth
            sx={{ mt: 1 }}
            multiline
            maxRows={5}
            error={!!formState?.error?.prompt}
            helperText={formState?.error?.prompt?.message}
            disabled={disabled}
            {...field}
          />
        )}
      />

      <Typography variant="subtitle2" mt={2}>
        Sensitivity
      </Typography>
      <Controller
        name={`issuePrompts.${index}.sensitivity`}
        control={control}
        render={({ field }) => (
          <Slider
            sx={{ mt: "11px" }}
            valueLabelDisplay="auto"
            marks={SLIDER_MARKS}
            step={0.01}
            max={1}
            disabled={disabled}
            {...field}
          />
        )}
      />
      <Controller
        name={`issuePrompts.${index}.sensitivity`}
        control={control}
        render={({ field }) => (
          <InputNumber
            label="Value"
            fullWidth
            sx={{ mt: 1 }}
            inputProps={{ min: 0, max: 1 }}
            decimal={2}
            disabled={disabled}
            {...field}
          />
        )}
      />
    </Paper>
  );
};

export default IssuePromptForm;
