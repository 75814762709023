import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@tsc/component-library/lib/components";
import { keyBy } from "lodash";

const SelectDropDown = ({ value, onChange, options }) => {
  const optionValues = options.map((item) => item.value);
  const labelMap = keyBy(options, "value");

  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <Select
        value={value || ""}
        onChange={handleChange}
        variant="outlined"
        displayEmpty
      >
        {optionValues.map((option) => (
          <MenuItem key={option} value={option}>
            <Typography variant="subtitle2">
              {labelMap[option].label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropDown;
