const { uniqWith, orderBy } = require("lodash");

export const getUniqMediaIssues = (mediaIssues) => {
  return uniqWith(
    mediaIssues,
    (mediaIssue1, mediaIssue2) =>
      mediaIssue1.issue.id === mediaIssue2.issue.id &&
      mediaIssue1.issue.organizationId === mediaIssue2.issue.organizationId
  );
};

export const sortMediaIssues = (mediaIssues, organizationsDictionary) => {
  return orderBy(
    mediaIssues,
    [
      (mediaIssue) =>
        organizationsDictionary?.[mediaIssue.issue.organizationId]?.name,
      "issue.name",
      "score",
    ],
    ["asc", "asc", "desc"]
  );
};
