import React, { useMemo } from "react";
import { Box, Tooltip } from "@tsc/component-library/lib/components";
import { PREVIEW_TYPES_CONFIG } from "enums/issuePromptPreviewType";
import { isEmpty } from "lodash";

import ExportCsvButton from "components/ExportCsvButton/ExportCsvButton";

const CSV_HEADER = [
  { label: "Preview Type", key: "previewType" },
  { label: "Prompt", key: "prompt" },
  { label: "Sensitivity", key: "sensitivity" },
  { label: "Media ID", key: "id" },
  { label: "Media URL", key: "url" },
  { label: "Title", key: "title" },
  { label: "Content", key: "content" },
  { label: "Score", key: "score" },
];

const ExportPreviewMedia = ({ media, previewType, previewedPrompts }) => {
  const data = useMemo(() => {
    if (isEmpty(media)) {
      return [];
    }
    return media.map((mediaItem) => ({
      ...mediaItem,
      content: mediaItem.content.replaceAll('"', '""'),
      previewType: PREVIEW_TYPES_CONFIG[previewType].label,
      prompt: previewedPrompts[0].prompt,
      sensitivity: previewedPrompts[0].sensitivity,
    }));
  }, [media, previewType, previewedPrompts]);

  return (
    <Box textAlign="right">
      <Tooltip
        title={
          previewedPrompts?.length !== 1 &&
          "Retricted to 1 preview prompt only."
        }
      >
        <span>
          <ExportCsvButton
            header={CSV_HEADER}
            data={data}
            disabled={previewedPrompts?.length !== 1}
          />
        </span>
      </Tooltip>
    </Box>
  );
};

export default ExportPreviewMedia;
