import { Box } from "@tsc/component-library/lib/components";

export default function SignInLayout({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Box width="568px" my={5}>
        {children}
      </Box>
    </Box>
  );
}
