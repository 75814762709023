import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  Box,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { TAGGING_LOGIC, TAGGING_LOGIC_LABEL } from "enums/issueTaggingLogic";
import { isEmpty } from "lodash";

import AddPromptButton from "features/issues/IssueForm/AddPromptButton";
import DropdownOrganization from "features/issues/IssueForm/DropdownOrganization";
import IssuePromptActions from "features/issues/IssueForm/IssuePromptActions";
import IssuePromptForm from "features/issues/IssueForm/IssuePromptForm";

const DEFAULT_ISSUE_PROMPT = { prompt: "", sensitivity: 0.7, version: 0 };

const IssueForm = ({
  issue,
  disabled,
  onPreview,
  hasSaveLibraryIssuePermission,
}) => {
  const { control, formState, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "issuePrompts",
  });

  const handleAddPrompt = () => {
    const newPrompt = {
      ...DEFAULT_ISSUE_PROMPT,
      ...(issue?.id ? { issueId: issue.id } : {}),
    };

    append(newPrompt);
  };

  const handleDeletePrompt = (index) => {
    remove(index);
  };

  const handlePreviewPrompts = () => {
    onPreview();
  };

  const handleSelectAllPrompt = (isSelected) => {
    const prompts = getValues().issuePrompts ?? [];
    prompts.forEach((_, index) =>
      setValue(`issuePrompts.${index}.selected`, isSelected)
    );
  };

  const renderPrompts = (issuePrompts) => {
    return (
      <Stack gap={2}>
        {issuePrompts.map((issuePrompt, index) => (
          <IssuePromptForm
            key={issuePrompt.id}
            index={index}
            disabled={disabled}
            onDelete={handleDeletePrompt}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Stack
      gap={2}
      px={2}
      my={2}
      sx={{
        position: "relative",
        height: "calc(100% - 32px)",
        overflow: "auto",
      }}
    >
      {issue?.id && (
        <Box>
          <Typography variant="subtitle2" fontWeight={700}>
            Issue ID
          </Typography>
          <Typography>{issue.id}</Typography>
        </Box>
      )}

      <Box>
        <Typography variant="subtitle2" fontWeight={700}>
          <Typography variant="span" color="error">
            *
          </Typography>
          Issue Tagging Logic
        </Typography>
        <Controller
          name="taggingLogic"
          rules={{ required: "Tagging logic is required" }}
          control={control}
          render={({ field }) => (
            <RadioGroup row {...field}>
              <FormControlLabel
                value={TAGGING_LOGIC.PBC}
                label={TAGGING_LOGIC_LABEL[TAGGING_LOGIC.PBC]}
                control={<Radio disabled={!!issue?.id} />}
              />
            </RadioGroup>
          )}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2" fontWeight={700}>
          <Typography variant="span" color="error">
            *
          </Typography>
          Issue Name
        </Typography>
        <Controller
          name="name"
          rules={{
            required: "Issue name is required",
            maxLength: { value: 64, message: "Max. 64 characters" },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2" fontWeight={700}>
          Issue Category
        </Typography>
        <Controller
          name="category"
          rules={{
            maxLength: { value: 32, message: "Max. 32 characters" },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              multiline
              error={!!formState.errors.category}
              helperText={formState.errors.category?.message}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2" fontWeight={700}>
          <Typography variant="span" color="error">
            *
          </Typography>
          Organization
        </Typography>
        <Controller
          name="organizationId"
          control={control}
          rules={{
            required: "Organization is required",
          }}
          render={({ field }) => (
            <DropdownOrganization
              {...field}
              isCreating={!issue?.id}
              disabled={disabled || issue?.id}
              disableClearable={!hasSaveLibraryIssuePermission}
              error={!!formState.errors.organizationId}
              helperText={formState.errors.organizationId?.message}
              onChange={(_, value) => field.onChange(value)}
            />
          )}
        />
      </Box>

      <Divider />

      {!isEmpty(fields) && (
        <Box>
          <Paper
            elevation={0}
            sx={{
              position: "sticky",
              pl: 2,
              mb: 1,
              top: 0,
              zIndex: 2,
            }}
          >
            <IssuePromptActions
              disabled={disabled}
              onPreview={handlePreviewPrompts}
              onSelectAll={handleSelectAllPrompt}
            />
          </Paper>
          {renderPrompts(fields)}
        </Box>
      )}

      <AddPromptButton onClick={handleAddPrompt} disabled={disabled} />
    </Stack>
  );
};

IssueForm.defaultProps = {
  issue: null,
  disabled: false,
  onPreview: () => {},
};

export default IssueForm;
