import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@tsc/component-library/lib/components";

/**
 * This feature is copy from https://mui.com/material-ui/react-button-group/#split-button.
 * @param {object} props Properties of SplitButton component.
 * @param {{ label: string, disabled: boolean, onClick: () => {} }[]} props.options Available actions of SplitButton component.
 * @param {"contained" | "outlined" | "text" | string} props.variant Variant of MUI ButtonGroup component. default: `contained`
 * @param {boolean} props.disabled Disable SplitButton component. default: `false`
 */
const SplitButton = ({ options, variant, disabled }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { label, onClick } = options[selectedIndex];

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant={variant} ref={anchorRef} disabled={disabled}>
        <Button onClick={onClick}>{label}</Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map(({ label, disabled }, index) => (
                    <MenuItem
                      key={label}
                      selected={index === selectedIndex}
                      disabled={disabled}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

SplitButton.defaultProps = {
  options: [],
  variant: "contained",
  disabled: false,
};

export default SplitButton;
