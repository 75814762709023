import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "auth/api/v1/organizations";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationUsers: build.query({
      query: ({ organizationId, objectType, objectId }) =>
        `${API_ROUTE}/${organizationId}/${objectType}/${objectId}/users`,
      providesTags: providesTagsList(TAG_TYPES.ORGANIZATION_USER),
    }),
    updateOrganizationUser: build.mutation({
      query: ({ organizationId, objectType, objectId, userId, user }) => ({
        url: `${API_ROUTE}/${organizationId}/${objectType}/${objectId}/users/${userId}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION_USER),
    }),
  }),
});

export const {
  useGetOrganizationUsersQuery,
  useUpdateOrganizationUserMutation,
} = extendedApi;
