const NotFoundIcon = (props) => {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.771 31.4242L81.9781 31.2926C81.9781 26.1498 84.8748 25.1373 87.9543 24.3021C91.0337 23.4669 92.419 17.8143 97.7702 17.4926C100.93 17.3025 102.588 18.1158 103.96 19.163C103.962 19.1648 103.962 19.1667 103.964 19.1667C107.402 22.8666 110.362 26.9848 112.771 31.4242Z"
        fill="white"
      />
      <path
        d="M24.9341 69.6369L0.788234 69.741C0.375797 67.22 0.125514 64.675 0.038929 62.1219C5.32792 61.3726 4.78696 57.1692 11.103 57.5511C15.7889 57.8326 17.0061 62.7835 19.7018 63.5145C22.3974 64.2455 24.9341 65.1347 24.9341 69.6369Z"
        fill="white"
      />
      <g clipPath="url(#clip0_302_32445)">
        <path
          d="M41.4597 42.2657L27.5481 98.3179C26.9619 100.675 27.1377 103.158 28.0502 105.409C28.9627 107.66 30.565 109.565 32.6271 110.848C37.9338 114.149 46.7069 117.721 60.1228 118.13C74.3434 118.565 83.8033 114.479 89.3974 110.771C91.3744 109.46 92.897 107.568 93.7564 105.357C94.6158 103.146 94.77 100.722 94.1977 98.4199L80.2603 42.2657"
          fill="#F4F4F4"
        />
        <path
          d="M60.8598 44.8692C79.3044 44.8692 94.2566 39.8269 94.2566 33.6069C94.2566 27.3869 79.3044 22.3445 60.8598 22.3445C42.4153 22.3445 27.463 27.3869 27.463 33.6069C27.463 39.8269 42.4153 44.8692 60.8598 44.8692Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M60.8598 45.0561C51.9193 45.0561 43.5114 43.8812 37.185 41.7471C30.7951 39.5922 27.276 36.7012 27.276 33.6064C27.276 30.5116 30.7948 27.6205 37.185 25.4656C43.5114 23.3322 51.9193 22.1567 60.8598 22.1567C69.8004 22.1567 78.2081 23.3317 84.5347 25.4656C90.9244 27.6205 94.4436 30.5116 94.4436 33.6064C94.4436 36.7012 90.9247 39.5922 84.5347 41.7471C78.2081 43.8812 69.8002 45.0561 60.8598 45.0561ZM60.8598 22.5315C51.9588 22.5315 43.5934 23.6994 37.3043 25.8203C31.0785 27.9198 27.6498 30.6851 27.6498 33.6069C27.6498 36.5286 31.0785 39.2939 37.3043 41.3934C43.5934 43.5143 51.959 44.6823 60.8598 44.6823C69.7607 44.6823 78.1261 43.5143 84.4153 41.3934C90.641 39.2939 94.0697 36.5286 94.0697 33.6069C94.0697 30.6851 90.641 27.9198 84.4153 25.8203C78.1261 23.6994 69.7607 22.5315 60.8598 22.5315Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M60.8598 40.5788C85.0935 40.5788 104.739 35.1743 104.739 28.5075C104.739 21.8407 85.0935 16.4362 60.8598 16.4362C36.6261 16.4362 16.9808 21.8407 16.9808 28.5075C16.9808 35.1743 36.6261 40.5788 60.8598 40.5788Z"
          fill="#00B5FE"
        />
        <path
          d="M60.8598 40.7657C49.123 40.7657 38.0863 39.5077 29.783 37.2233C21.4068 34.919 16.7938 31.8237 16.7938 28.507C16.7938 25.1903 21.4068 22.0955 29.783 19.7907C38.0863 17.5063 49.123 16.2484 60.8598 16.2484C72.5967 16.2484 83.6332 17.5063 91.9365 19.7907C100.313 22.095 104.926 25.1904 104.926 28.507C104.926 31.8236 100.313 34.9185 91.9365 37.2233C83.6332 39.5077 72.5966 40.7657 60.8598 40.7657ZM60.8598 16.6231C49.1556 16.6231 38.1541 17.8764 29.8821 20.1521C25.8708 21.2555 22.7247 22.5386 20.5308 23.9655C18.2991 25.417 17.1676 26.9451 17.1676 28.5075C17.1676 30.0698 18.2991 31.5981 20.5308 33.0495C22.7247 34.4764 25.8708 35.7595 29.8821 36.8631C38.1541 39.139 49.1549 40.3919 60.8598 40.3919C72.5648 40.3919 83.5654 39.1387 91.8374 36.8631C95.8487 35.7595 98.9948 34.4764 101.189 33.0495C103.42 31.5981 104.552 30.0698 104.552 28.5075C104.552 26.9451 103.42 25.417 101.189 23.9655C98.9948 22.5386 95.8487 21.2555 91.8374 20.1521C83.5654 17.8764 72.5639 16.6237 60.8598 16.6237V16.6231Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M81.0491 21.4719C81.0491 26.8084 71.8001 28.0655 62.1552 28.2939C51.6472 28.5427 40.671 26.7268 40.671 21.4719C40.6861 16.1273 42.8199 11.0067 46.6045 7.23276C50.3891 3.45884 55.5157 1.33957 60.8604 1.33957C66.2051 1.33957 71.3317 3.45884 75.1163 7.23276C78.9009 11.0067 81.0347 16.1273 81.0498 21.4719H81.0491Z"
          fill="white"
        />
        <path
          d="M78.4287 20.4155C78.3802 20.4155 78.3335 20.3966 78.2987 20.3628C78.2638 20.329 78.2435 20.2829 78.242 20.2344C78.1853 18.3188 77.8067 16.4264 77.1223 14.6364C77.1045 14.5901 77.1059 14.5387 77.1261 14.4934C77.1463 14.4481 77.1837 14.4127 77.23 14.395C77.2763 14.3773 77.3277 14.3787 77.373 14.3989C77.4183 14.4191 77.4537 14.4564 77.4714 14.5027C78.1707 16.3319 78.5575 18.2655 78.6157 20.2229C78.6172 20.2724 78.599 20.3205 78.5651 20.3567C78.5311 20.3928 78.4842 20.4139 78.4347 20.4155H78.4287Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M75.6369 11.503C75.6058 11.5031 75.5751 11.4954 75.5478 11.4805C75.5204 11.4657 75.4972 11.4442 75.4802 11.4181C73.0137 7.65302 69.1747 4.99955 64.7809 4.0228C64.7337 4.01112 64.6928 3.98146 64.6671 3.94014C64.6414 3.89883 64.6328 3.8491 64.6432 3.80155C64.6535 3.75399 64.682 3.71236 64.7226 3.68549C64.7632 3.65862 64.8127 3.64865 64.8605 3.65768C69.3501 4.65559 73.2728 7.3668 75.7932 11.2139C75.8116 11.2421 75.822 11.2748 75.8234 11.3084C75.8248 11.342 75.8171 11.3754 75.8011 11.405C75.7851 11.4346 75.7614 11.4593 75.7325 11.4766C75.7036 11.4938 75.6706 11.503 75.6369 11.503Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M60.1976 28.5038C51.2714 28.5038 44.4759 27.0026 41.8133 24.4039C40.9309 23.5422 40.4836 22.5557 40.4836 21.4718C40.4761 18.7911 40.9976 16.1353 42.0182 13.6565C43.0388 11.1778 44.5385 8.92475 46.4313 7.02656C48.3242 5.12838 50.573 3.62234 53.0489 2.59475C55.5248 1.56716 58.1791 1.03822 60.8598 1.03822C63.5404 1.03822 66.1947 1.56716 68.6706 2.59475C71.1465 3.62234 73.3953 5.12838 75.2882 7.02656C77.181 8.92475 78.6807 11.1778 79.7013 13.6565C80.722 16.1353 81.2434 18.7911 81.2359 21.4718C81.2359 23.989 79.2289 25.8416 75.2705 26.9779C72.2248 27.8523 67.9362 28.3438 62.1595 28.4805C61.4952 28.496 60.8412 28.5038 60.1976 28.5038ZM60.8594 1.46908C55.5562 1.47506 50.472 3.58436 46.7221 7.33423C42.9722 11.0841 40.8628 16.1683 40.8568 21.4715C40.8568 22.4509 41.2663 23.3474 42.074 24.1361C44.8571 26.8541 52.3616 28.338 62.1502 28.1068C70.1833 27.9169 80.863 27.022 80.863 21.4719C80.857 16.1687 78.7476 11.0843 74.9976 7.33444C71.2475 3.58454 66.1631 1.47533 60.8598 1.46956L60.8594 1.46908Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M28.1886 30.1232C29.6365 30.1232 30.8103 28.9495 30.8103 27.5015C30.8103 26.0536 29.6365 24.8799 28.1886 24.8799C26.7407 24.8799 25.5669 26.0536 25.5669 27.5015C25.5669 28.9495 26.7407 30.1232 28.1886 30.1232Z"
          fill="white"
        />
        <path
          d="M28.1886 30.3101C27.6331 30.3101 27.0901 30.1454 26.6282 29.8368C26.1663 29.5282 25.8064 29.0896 25.5938 28.5764C25.3812 28.0631 25.3256 27.4984 25.434 26.9536C25.5423 26.4088 25.8098 25.9084 26.2026 25.5156C26.5954 25.1228 27.0958 24.8553 27.6407 24.7469C28.1855 24.6385 28.7502 24.6942 29.2634 24.9067C29.7766 25.1193 30.2152 25.4793 30.5238 25.9412C30.8325 26.403 30.9972 26.9461 30.9972 27.5015C30.9963 28.2462 30.7001 28.9601 30.1736 29.4866C29.6471 30.0131 28.9332 30.3093 28.1886 30.3101ZM28.1886 25.0666C27.707 25.0666 27.2362 25.2094 26.8358 25.477C26.4354 25.7445 26.1233 26.1248 25.939 26.5697C25.7547 27.0147 25.7065 27.5042 25.8004 27.9766C25.8944 28.4489 26.1263 28.8828 26.4668 29.2233C26.8074 29.5638 27.2412 29.7957 27.7136 29.8897C28.1859 29.9836 28.6755 29.9354 29.1204 29.7511C29.5653 29.5668 29.9456 29.2547 30.2132 28.8543C30.4807 28.4539 30.6235 27.9831 30.6235 27.5015C30.6229 26.856 30.3661 26.237 29.9096 25.7805C29.4531 25.324 28.8342 25.0673 28.1886 25.0666Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M60.8598 37.6314C62.3078 37.6314 63.4815 36.4576 63.4815 35.0097C63.4815 33.5618 62.3078 32.388 60.8598 32.388C59.4119 32.388 58.2382 33.5618 58.2382 35.0097C58.2382 36.4576 59.4119 37.6314 60.8598 37.6314Z"
          fill="white"
        />
        <path
          d="M60.8598 37.8183C60.3044 37.8183 59.7613 37.6536 59.2995 37.345C58.8376 37.0364 58.4776 36.5977 58.265 36.0845C58.0525 35.5713 57.9968 35.0066 58.1052 34.4618C58.2136 33.917 58.4811 33.4165 58.8739 33.0237C59.2666 32.6309 59.7671 32.3634 60.3119 32.2551C60.8567 32.1467 61.4214 32.2023 61.9346 32.4149C62.4478 32.6275 62.8865 32.9875 63.1951 33.4493C63.5037 33.9112 63.6684 34.4542 63.6684 35.0097C63.6676 35.7543 63.3714 36.4682 62.8449 36.9947C62.3183 37.5213 61.6045 37.8174 60.8598 37.8183ZM60.8598 32.5749C60.3783 32.5749 59.9076 32.7177 59.5072 32.9853C59.1068 33.2528 58.7947 33.6331 58.6104 34.078C58.4261 34.5229 58.3779 35.0124 58.4719 35.4847C58.5658 35.957 58.7977 36.3908 59.1382 36.7313C59.4787 37.0718 59.9125 37.3037 60.3848 37.3977C60.8571 37.4916 61.3467 37.4434 61.7916 37.2591C62.2365 37.0748 62.6167 36.7628 62.8843 36.3624C63.1518 35.962 63.2946 35.4913 63.2946 35.0097C63.2939 34.3642 63.0372 33.7453 62.5807 33.2888C62.1243 32.8324 61.5054 32.5756 60.8598 32.5749Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M93.5253 30.1232C94.9732 30.1232 96.147 28.9495 96.147 27.5015C96.147 26.0536 94.9732 24.8799 93.5253 24.8799C92.0774 24.8799 90.9036 26.0536 90.9036 27.5015C90.9036 28.9495 92.0774 30.1232 93.5253 30.1232Z"
          fill="white"
        />
        <path
          d="M93.5253 30.3101C92.9698 30.3101 92.4268 30.1454 91.9649 29.8368C91.503 29.5282 91.143 29.0896 90.9305 28.5764C90.7179 28.0631 90.6623 27.4984 90.7706 26.9536C90.879 26.4088 91.1465 25.9084 91.5393 25.5156C91.9321 25.1228 92.4325 24.8553 92.9774 24.7469C93.5222 24.6385 94.0869 24.6942 94.6001 24.9067C95.1133 25.1193 95.5519 25.4793 95.8605 25.9412C96.1692 26.403 96.3339 26.9461 96.3339 27.5015C96.333 28.2462 96.0368 28.9601 95.5103 29.4866C94.9838 30.0131 94.2699 30.3093 93.5253 30.3101ZM93.5253 25.0666C93.0437 25.0666 92.5729 25.2094 92.1725 25.477C91.7721 25.7445 91.46 26.1248 91.2757 26.5697C91.0914 27.0147 91.0432 27.5042 91.1371 27.9766C91.2311 28.4489 91.463 28.8828 91.8035 29.2233C92.1441 29.5638 92.5779 29.7957 93.0502 29.8897C93.5226 29.9836 94.0122 29.9354 94.4571 29.7511C94.902 29.5668 95.2823 29.2547 95.5498 28.8543C95.8174 28.4539 95.9602 27.9831 95.9602 27.5015C95.9596 26.856 95.7028 26.237 95.2463 25.7805C94.7898 25.324 94.1709 25.0673 93.5253 25.0666Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M79.5925 35.0097C81.0405 35.0097 82.2142 33.8359 82.2142 32.388C82.2142 30.9401 81.0405 29.7663 79.5925 29.7663C78.1446 29.7663 76.9709 30.9401 76.9709 32.388C76.9709 33.8359 78.1446 35.0097 79.5925 35.0097Z"
          fill="white"
        />
        <path
          d="M79.5925 35.1966C79.0371 35.1966 78.494 35.0319 78.0322 34.7233C77.5703 34.4147 77.2103 33.976 76.9977 33.4628C76.7852 32.9496 76.7295 32.3849 76.8379 31.8401C76.9463 31.2953 77.2138 30.7948 77.6066 30.402C77.9994 30.0093 78.4998 29.7418 79.0446 29.6334C79.5894 29.525 80.1541 29.5806 80.6673 29.7932C81.1805 30.0058 81.6192 30.3658 81.9278 30.8276C82.2364 31.2895 82.4011 31.8325 82.4011 32.388C82.4003 33.1326 82.1041 33.8465 81.5776 34.3731C81.051 34.8996 80.3372 35.1958 79.5925 35.1966ZM79.5925 29.9531C79.111 29.9531 78.6402 30.0958 78.2397 30.3634C77.8393 30.6309 77.5272 31.0112 77.3428 31.4561C77.1585 31.901 77.1103 32.3906 77.2042 32.863C77.2982 33.3353 77.53 33.7692 77.8706 34.1097C78.2111 34.4503 78.645 34.6822 79.1173 34.7761C79.5896 34.8701 80.0792 34.8219 80.5241 34.6376C80.9691 34.4533 81.3494 34.1412 81.6169 33.7408C81.8845 33.3404 82.0273 32.8696 82.0273 32.388C82.0267 31.7425 81.7699 31.1235 81.3135 30.667C80.857 30.2105 80.2381 29.9538 79.5925 29.9531Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M42.127 35.3666C43.5749 35.3666 44.7487 34.1928 44.7487 32.7449C44.7487 31.297 43.5749 30.1232 42.127 30.1232C40.6791 30.1232 39.5053 31.297 39.5053 32.7449C39.5053 34.1928 40.6791 35.3666 42.127 35.3666Z"
          fill="white"
        />
        <path
          d="M42.127 35.5537C41.5715 35.5537 41.0285 35.3889 40.5666 35.0803C40.1047 34.7717 39.7447 34.3331 39.5322 33.8199C39.3196 33.3067 39.264 32.742 39.3723 32.1971C39.4807 31.6523 39.7482 31.1519 40.141 30.7591C40.5338 30.3663 41.0342 30.0988 41.579 29.9904C42.1239 29.8821 42.6886 29.9377 43.2018 30.1503C43.715 30.3628 44.1536 30.7228 44.4622 31.1847C44.7709 31.6466 44.9356 32.1896 44.9356 32.7451C44.9347 33.4897 44.6385 34.2036 44.112 34.7301C43.5855 35.2566 42.8716 35.5528 42.127 35.5537ZM42.127 30.3101C41.6454 30.31 41.1745 30.4527 40.774 30.7201C40.3734 30.9876 40.0612 31.3678 39.8768 31.8127C39.6924 32.2577 39.6441 32.7473 39.7379 33.2197C39.8318 33.6921 40.0637 34.126 40.4042 34.4666C40.7447 34.8072 41.1786 35.0392 41.6509 35.1332C42.1233 35.2272 42.6129 35.179 43.0579 34.9947C43.5029 34.8104 43.8832 34.4983 44.1508 34.0978C44.4183 33.6973 44.5611 33.2265 44.5611 32.7449C44.5604 32.0995 44.3038 31.4807 43.8474 31.0243C43.3911 30.5678 42.7724 30.311 42.127 30.3101Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M88.6593 19.1676C88.4639 19.7594 88.0642 20.2622 87.5317 20.586C86.9992 20.9098 86.3688 21.0333 85.7535 20.9345C85.1382 20.8357 84.5782 20.5209 84.1739 20.0467C83.7696 19.5724 83.5474 18.9697 83.5472 18.3465C83.5472 18.2879 83.5496 18.2306 83.5533 18.1733C85.3439 18.4724 87.0497 18.8038 88.6593 19.1676Z"
          fill="white"
        />
        <path
          d="M86.1687 21.1548C85.424 21.154 84.7101 20.8579 84.1835 20.3313C83.657 19.8048 83.3608 19.0908 83.3601 18.3462C83.3601 18.2835 83.3627 18.222 83.3667 18.1607L83.3803 17.9546L83.584 17.9886C85.3844 18.2893 87.1059 18.6246 88.7008 18.9849L88.9016 19.0304L88.837 19.2259C88.6524 19.7876 88.2949 20.2767 87.8157 20.6231C87.3365 20.9695 86.76 21.1556 86.1687 21.1548ZM83.7346 18.3931C83.7454 18.9501 83.9468 19.4865 84.3052 19.9129C84.6636 20.3393 85.1574 20.6299 85.7042 20.7363C86.251 20.8427 86.8177 20.7585 87.3099 20.4976C87.8021 20.2367 88.1899 19.815 88.4088 19.3027C86.9433 18.9758 85.3729 18.6703 83.7346 18.3931Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M37.8907 18.3465C37.8905 18.9644 37.6721 19.5624 37.2741 20.035C36.876 20.5077 36.3238 20.8245 35.7149 20.9297C35.106 21.0349 34.4795 20.9217 33.9459 20.61C33.4124 20.2983 33.006 19.8082 32.7986 19.2262C34.402 18.8598 36.1016 18.5234 37.887 18.2206C37.8894 18.2617 37.8907 18.304 37.8907 18.3465Z"
          fill="white"
        />
        <path
          d="M35.2692 21.1548C34.6896 21.1539 34.1243 20.9743 33.6506 20.6404C33.1768 20.3065 32.8176 19.8346 32.622 19.289L32.5513 19.0908L32.7564 19.044C34.3678 18.6758 36.0833 18.3368 37.8552 18.0364L38.0605 18.0015L38.0731 18.2093C38.0758 18.2542 38.0771 18.3003 38.0771 18.3465C38.0763 19.091 37.7802 19.8047 37.2538 20.3312C36.7274 20.8576 36.0136 21.1538 35.2692 21.1548ZM33.0544 19.3597C33.2823 19.8575 33.6714 20.264 34.1587 20.5136C34.6461 20.7631 35.2033 20.8411 35.7405 20.735C36.2777 20.6289 36.7634 20.3449 37.1193 19.9288C37.4752 19.5127 37.6804 18.9888 37.702 18.4417C36.0917 18.7184 34.5305 19.0267 33.0544 19.3597Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M64.9383 109.868L40.9722 96.2157L59.5187 63.6582L76.0907 73.0985L79.2726 84.7048L64.9383 109.868Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M68.8259 93.6511C69.6143 93.6511 70.2535 93.012 70.2535 92.2236C70.2535 91.4352 69.6143 90.796 68.8259 90.796C68.0375 90.796 67.3984 91.4352 67.3984 92.2236C67.3984 93.012 68.0375 93.6511 68.8259 93.6511Z"
          fill="#00B5FE"
        />
        <path
          opacity="0.3"
          d="M53.7607 86.1323C54.5491 86.1323 55.1882 85.4932 55.1882 84.7048C55.1882 83.9164 54.5491 83.2772 53.7607 83.2772C52.9723 83.2772 52.3332 83.9164 52.3332 84.7048C52.3332 85.4932 52.9723 86.1323 53.7607 86.1323Z"
          fill="#00B5FE"
        />
        <path
          d="M65.008 110.123L40.7174 96.2856L59.4488 63.4033L76.2503 72.9752L79.4733 84.7306L65.008 110.123ZM41.2271 96.1458L64.8682 109.613L79.0726 84.6794L75.9315 73.2226L59.5887 63.9132L41.2271 96.1458Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          opacity="0.3"
          d="M76.0907 73.0985L71.8786 80.4927L79.2726 84.7048L76.0907 73.0985Z"
          fill="#00B5FE"
        />
        <path
          d="M79.2726 84.8915C79.2402 84.8915 79.2083 84.8831 79.1801 84.8672L71.7859 80.6551C71.7429 80.6305 71.7114 80.5898 71.6983 80.542C71.6852 80.4942 71.6917 80.4431 71.7162 80.4L75.9283 73.0055C75.9466 72.9734 75.974 72.9474 76.007 72.9308C76.0401 72.9143 76.0773 72.9079 76.114 72.9125C76.1506 72.9171 76.1851 72.9325 76.213 72.9567C76.241 72.981 76.2611 73.0129 76.2708 73.0486L79.4529 84.6542C79.4605 84.682 79.4616 84.7111 79.4561 84.7393C79.4505 84.7675 79.4386 84.7941 79.4212 84.8169C79.4037 84.8398 79.3812 84.8583 79.3555 84.871C79.3297 84.8838 79.3014 84.8904 79.2726 84.8904V84.8915ZM72.1335 80.4228L78.973 84.319L76.0297 73.583L72.1335 80.4228Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M58.5587 82.8266C59.181 82.8266 59.6854 82.3221 59.6854 81.6999C59.6854 81.0777 59.181 80.5732 58.5587 80.5732C57.9365 80.5732 57.4321 81.0777 57.4321 81.6999C57.4321 82.3221 57.9365 82.8266 58.5587 82.8266Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M69.1266 87.6317C69.7489 87.6317 70.2533 87.1273 70.2533 86.505C70.2533 85.8828 69.7489 85.3784 69.1266 85.3784C68.5044 85.3784 68 85.8828 68 86.505C68 87.1273 68.5044 87.6317 69.1266 87.6317Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M64.3381 94.542C64.2895 94.5419 64.2427 94.5229 64.2079 94.489C64.173 94.4551 64.1527 94.4089 64.1514 94.3602C64.1279 93.4592 63.8747 92.5792 63.416 91.8034C62.9572 91.0276 62.308 90.3818 61.5298 89.9271C60.7516 89.4724 59.8702 89.2239 58.9691 89.2051C58.068 89.1864 57.1771 89.3979 56.3806 89.8198C56.3589 89.832 56.3349 89.8396 56.3101 89.8424C56.2854 89.8452 56.2603 89.8429 56.2364 89.8359C56.2125 89.8288 56.1903 89.817 56.171 89.8013C56.1517 89.7855 56.1358 89.766 56.1242 89.744C56.1125 89.722 56.1054 89.6978 56.1032 89.673C56.101 89.6482 56.1038 89.6232 56.1115 89.5995C56.1191 89.5757 56.1314 89.5538 56.1476 89.5349C56.1638 89.516 56.1837 89.5005 56.206 89.4894C57.0586 89.0381 58.0124 88.8118 58.9769 88.832C59.9415 88.8523 60.8849 89.1183 61.7179 89.605C62.5509 90.0917 63.2459 90.783 63.7372 91.6133C64.2284 92.4437 64.4996 93.3856 64.5251 94.35C64.5264 94.3996 64.508 94.4476 64.4739 94.4836C64.4398 94.5196 64.3928 94.5406 64.3433 94.542H64.3381Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M59.539 80.5471C58.9321 80.5394 58.34 80.359 57.8319 80.027C57.7919 79.9982 57.7649 79.9548 57.7568 79.9061C57.7486 79.8575 57.76 79.8077 57.7885 79.7674C57.8169 79.7272 57.8602 79.6998 57.9087 79.6912C57.9572 79.6827 58.0072 79.6937 58.0477 79.7218C58.0625 79.7321 59.159 80.4848 60.3842 80.0168C60.4301 80.0005 60.4807 80.0028 60.525 80.0233C60.5693 80.0437 60.6039 80.0807 60.6213 80.1263C60.6387 80.1719 60.6376 80.2225 60.6182 80.2673C60.5988 80.3121 60.5627 80.3475 60.5175 80.366C60.2052 80.4859 59.8735 80.5473 59.539 80.5471Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M71.0793 85.9186C71.0583 85.9186 71.0376 85.9151 71.0178 85.9083C69.442 85.3595 69.263 84.1442 69.2561 84.0927C69.2495 84.0435 69.2626 83.9936 69.2928 83.9541C69.3229 83.9146 69.3675 83.8887 69.4167 83.8821C69.4659 83.8754 69.5158 83.8886 69.5553 83.9187C69.5948 83.9488 69.6207 83.9934 69.6273 84.0427C69.6338 84.0869 69.7933 85.0852 71.1416 85.5553C71.1832 85.5698 71.2184 85.5987 71.2408 85.6367C71.2632 85.6747 71.2715 85.7194 71.2641 85.7629C71.2568 85.8064 71.2342 85.8459 71.2006 85.8744C71.1669 85.9029 71.1242 85.9185 71.0801 85.9186H71.0793Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M9.00441 42.4526C8.97987 42.4526 8.95557 42.4478 8.9329 42.4384C8.91023 42.429 8.88964 42.4152 8.87229 42.3979C8.85495 42.3805 8.8412 42.3599 8.83182 42.3372C8.82244 42.3146 8.81763 42.2903 8.81765 42.2657V36.9925C8.81692 36.9675 8.8212 36.9426 8.83026 36.9193C8.83932 36.896 8.85297 36.8747 8.87039 36.8568C8.88781 36.8389 8.90866 36.8246 8.93169 36.8149C8.95472 36.8051 8.97948 36.8001 9.00449 36.8001C9.0295 36.8001 9.05425 36.8051 9.07728 36.8149C9.10032 36.8246 9.12116 36.8389 9.13858 36.8568C9.15601 36.8747 9.16965 36.896 9.17871 36.9193C9.18777 36.9426 9.19206 36.9675 9.19132 36.9925V42.2657C9.19134 42.2903 9.18652 42.3146 9.17714 42.3373C9.16775 42.36 9.15398 42.3806 9.13662 42.3979C9.11926 42.4153 9.09865 42.4291 9.07596 42.4385C9.05327 42.4478 9.02896 42.4527 9.00441 42.4526Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M10.7251 39.8161H7.2839C7.23433 39.8161 7.18679 39.7964 7.15173 39.7614C7.11668 39.7263 7.09698 39.6788 7.09698 39.6292C7.09698 39.5796 7.11668 39.5321 7.15173 39.497C7.18679 39.462 7.23433 39.4423 7.2839 39.4423H10.7251C10.7747 39.4423 10.8222 39.462 10.8573 39.497C10.8923 39.5321 10.912 39.5796 10.912 39.6292C10.912 39.6788 10.8923 39.7263 10.8573 39.7614C10.8222 39.7964 10.7747 39.8161 10.7251 39.8161Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M98.7638 71.8223C98.7142 71.8223 98.6667 71.8027 98.6316 71.7676C98.5966 71.7325 98.5769 71.685 98.5769 71.6354V63.6141C98.5769 63.5646 98.5966 63.517 98.6316 63.482C98.6667 63.4469 98.7142 63.4272 98.7638 63.4272C98.8134 63.4272 98.8609 63.4469 98.896 63.482C98.931 63.517 98.9507 63.5646 98.9507 63.6141V71.6354C98.9507 71.685 98.931 71.7325 98.8959 71.7676C98.8609 71.8026 98.8134 71.8223 98.7638 71.8223Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M101.382 67.8117H96.147C96.0974 67.8117 96.0498 67.792 96.0148 67.757C95.9797 67.7219 95.9601 67.6744 95.9601 67.6248C95.9601 67.5752 95.9797 67.5277 96.0148 67.4926C96.0498 67.4576 96.0974 67.4379 96.147 67.4379H101.382C101.431 67.4379 101.479 67.4576 101.514 67.4926C101.549 67.5277 101.569 67.5752 101.569 67.6248C101.569 67.6744 101.549 67.7219 101.514 67.757C101.479 67.792 101.431 67.8117 101.382 67.8117Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M111.504 46.4633C111.48 46.4633 111.456 46.4585 111.433 46.4491C111.41 46.4397 111.39 46.426 111.372 46.4086C111.355 46.3912 111.341 46.3706 111.332 46.3479C111.322 46.3252 111.318 46.3009 111.318 46.2764V42.2657C111.318 42.2162 111.337 42.1686 111.372 42.1336C111.407 42.0985 111.455 42.0788 111.504 42.0788C111.554 42.0788 111.602 42.0985 111.637 42.1336C111.672 42.1686 111.691 42.2162 111.691 42.2657V46.2764C111.691 46.3009 111.687 46.3252 111.677 46.3479C111.668 46.3706 111.654 46.3912 111.637 46.4086C111.619 46.426 111.599 46.4397 111.576 46.4491C111.553 46.4585 111.529 46.4633 111.504 46.4633Z"
          fill="black"
          fillOpacity="0.6"
        />
        <path
          d="M112.813 44.4581H110.196C110.171 44.4581 110.147 44.4532 110.124 44.4438C110.102 44.4344 110.081 44.4207 110.064 44.4033C110.046 44.3859 110.033 44.3653 110.023 44.3427C110.014 44.32 110.009 44.2957 110.009 44.2711C110.009 44.2466 110.014 44.2223 110.023 44.1996C110.033 44.1769 110.046 44.1563 110.064 44.139C110.081 44.1216 110.102 44.1078 110.124 44.0984C110.147 44.0891 110.171 44.0842 110.196 44.0842H112.813C112.863 44.0842 112.91 44.1039 112.945 44.139C112.98 44.174 113 44.2216 113 44.2711C113 44.3207 112.98 44.3683 112.945 44.4033C112.91 44.4384 112.863 44.4581 112.813 44.4581Z"
          fill="black"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_32445">
          <rect
            width="105.903"
            height="117.067"
            fill="white"
            transform="translate(7.09698 1.09573)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotFoundIcon;
