import KeyMirror from "keymirror";

export const PREVIEW_TYPES = KeyMirror({
  TOP_10: null,
  BOTTOM_10: null,
  AFTER_BOTTOM: null,
});

export const PREVIEW_TYPES_CONFIG = {
  [PREVIEW_TYPES.TOP_10]: {
    label: "Top 10",
    query: "minSensitivity=prompt&maxSensitivity=1&sortDirection=desc",
  },
  [PREVIEW_TYPES.BOTTOM_10]: {
    label: "Bottom 10",
    query: "minSensitivity=prompt&maxSensitivity=1&sortDirection=asc",
  },
  [PREVIEW_TYPES.AFTER_BOTTOM]: {
    label: "After Bottom",
    query: "minSensitivity=0&maxSensitivity=prompt&sortDirection=desc",
  },
};
