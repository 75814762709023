import { forwardRef } from "react";
import RadarIcon from "@mui/icons-material/Radar";
import {
  Avatar,
  Link,
  SkeletonLoader,
  Tooltip,
} from "@tsc/component-library/lib/components";

import {
  getStakeholderSourceLogo,
  getStakeholderSourceTag,
  getStakeholderSourceUrl,
} from "utilities/stakeholder";

export const ICON_WIDTH_HEIGHT = 32;

const StakeholderSourceAvatar = (
  { loading, stakeholder, stakeholderAttribute },
  ref
) => {
  const stakeholderSourceTag = getStakeholderSourceTag(stakeholderAttribute);
  const stakeholderSourceLogo = getStakeholderSourceLogo(stakeholderAttribute);
  const stakeholderSourceUrl = getStakeholderSourceUrl(
    stakeholder,
    stakeholderAttribute
  );

  const StakeholderSource = stakeholderSourceLogo ? Avatar : RadarIcon;
  const stakeholderSourceProps = stakeholderSourceLogo
    ? {
        variant: "rounded",
        imgProps: { sx: { objectFit: "contain" } },
        src: stakeholderSourceLogo,
      }
    : { color: "primary" };

  return (
    <SkeletonLoader
      variant="rounded"
      loading={loading}
      sx={{ width: ICON_WIDTH_HEIGHT, height: ICON_WIDTH_HEIGHT }}
    >
      <Tooltip title={stakeholderSourceTag}>
        <StakeholderSource
          ref={ref}
          component={stakeholderSourceUrl ? Link : null}
          href={stakeholderSourceUrl}
          target="_blank"
          referrerPolicy="no-referrer"
          sx={{
            width: ICON_WIDTH_HEIGHT,
            height: ICON_WIDTH_HEIGHT,
          }}
          {...stakeholderSourceProps}
        />
      </Tooltip>
    </SkeletonLoader>
  );
};

export default forwardRef(StakeholderSourceAvatar);
