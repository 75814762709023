export const ISSUE_TYPE = {
  ALL: "all",
  LIBRARY_ISSUE: "libraryIssue",
  ORGANIZATION_ISSUE: "organizationIssue",
};

export const ISSUE_TYPE_LABEL = {
  [ISSUE_TYPE.ALL]: "All",
  [ISSUE_TYPE.LIBRARY_ISSUE]: "Issue Library",
  [ISSUE_TYPE.ORGANIZATION_ISSUE]: "Local",
};
