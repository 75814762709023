export const MEDIA_TYPE_LABEL = {
  news: "News",
  facebook: "Facebook",
  twitter: "Twitter",
  market_report: "Market Report",
  stock_report: "Stock Report",
  role_change: "Role Change",
  publication: "Publication",
  recipe: "Recipe",
  listicle: "Listicle",
  paywall: "Paywall",
  error: "Incomplete Content",
  advertisement: "Advertisement",
  job_posting: "Job Posting",
  horoscope: "Horoscope",
};
