import React, { forwardRef, useEffect, useState } from "react";
import { TextField } from "@tsc/component-library/lib/components";
import { isNumber } from "lodash";

import { getNumberOfDecimal } from "utilities/number";

const InputNumber = forwardRef(
  ({ inputProps, onChange, decimal, ...otherProps }, ref) => {
    // To maintain internal state as string while parent state as number.
    // Problem statement: When typing "0.XX", the onChange export value as number, the state from parent component will pass in as number as well. This cause the dot "." will missing.
    const [value, setValue] = useState(otherProps.value ?? "");

    const handleOnChange = (event) => {
      const numberValue = Number(event.target.value);
      const isValidNumber = isNaN(numberValue) === false;
      const min = event.target.min ? Number(event.target.min) : null;
      const max = event.target.max ? Number(event.target.max) : null;

      if (
        !isValidNumber ||
        (isNumber(min) && numberValue < min) ||
        (isNumber(max) && numberValue > max)
      ) {
        return;
      }

      if (
        isNumber(decimal) &&
        getNumberOfDecimal(event.target.value) > decimal
      ) {
        return;
      }

      setValue(event.target.value);
      onChange(event.target.value ? numberValue : null);
    };

    useEffect(() => {
      if (otherProps.value && Number(value) !== Number(otherProps.value)) {
        setValue(otherProps.value.toString());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherProps.value]);

    return (
      <TextField
        inputRef={ref}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*", ...inputProps }}
        onChange={handleOnChange}
        {...otherProps}
        value={value}
      />
    );
  }
);

InputNumber.defaultProps = {
  onChange: () => {},
};

export default InputNumber;
