import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

const ConfirmationDialog = ({
  title,
  message,
  confirmationMessage,
  open,
  isLoading,
  onClose,
  onConfirm,
  confirmButtonColor,
}) => {
  const [confirmation, setConfirmation] = useState("");

  useEffect(() => {
    setConfirmation("");
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} data-testid="confirmationDialog">
      <DialogTitle>
        {title}
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
          disabled={isLoading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 600, pb: 3 }}>
        <Typography>{message}</Typography>
        {confirmationMessage && (
          <>
            <Typography mt={2} variant="body2" fontWeight={700}>
              Confirm by typing [
              <Typography
                variant="span"
                color="error"
                data-testid="confirmationIssueName"
              >
                {confirmationMessage}
              </Typography>
              ] below
            </Typography>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              placeholder={confirmationMessage}
              value={confirmation}
              onChange={(event) => setConfirmation(event.target.value)}
              data-testid="deleteIssueModalConfirmationInputField"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          data-testid="confirmationDialogCancelButton"
          disabled={isLoading}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          color={confirmButtonColor}
          startIcon={
            isLoading ? <CircularProgress size={14} color="inherit" /> : null
          }
          disabled={
            isLoading ||
            (!!confirmationMessage && confirmationMessage !== confirmation)
          }
          onClick={() => onConfirm()}
          data-testid="deleteIssueModalDeleteButton"
        >
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  title: "Confirm",
  message: "Are you sure you want to confirm this action?",
  confirmationMessage: null,
  open: false,
  isLoading: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default ConfirmationDialog;
