export const STAKEHOLDER_CLASS = {
  AI: "ai",
  MANUAL: "manual",
  PRIVATE: "private",
  JUNK: "junk",
  BLACKLIST: "blacklist",
};

export const STAKEHOLDER_CLASS_LABEL = {
  [STAKEHOLDER_CLASS.AI]: "Auto-generated",
  [STAKEHOLDER_CLASS.MANUAL]: "Curated",
  [STAKEHOLDER_CLASS.PRIVATE]: "Private",
};
