import { isEmpty } from "lodash";

import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "organizations/api/v1/organizations";

const prepareFormData = ({
  organization,
  lightLogo,
  darkLogo,
  arrayKeys = [],
}) => {
  const formData = new FormData();

  // Append organization data
  for (const [key, value] of Object.entries(organization)) {
    if (arrayKeys.includes(key)) {
      if (isEmpty(value)) {
        formData.append(key, "");
      } else {
        value.forEach((item) => formData.append(key, item));
      }
    } else {
      formData.append(key, value);
    }
  }

  // Append logo image file
  if (lightLogo) {
    formData.append("lightLogo", lightLogo);
  }
  if (darkLogo) {
    formData.append("darkLogo", darkLogo);
  }

  return formData;
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizations: build.query({
      query: ({ query } = {}) => `${API_ROUTE}?${query ?? ""}`,
      providesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
    getOrganizationLogo: build.query({
      query: ({ organizationId, query }) =>
        `${API_ROUTE}/${organizationId}/logo?${query ?? ""}`,
      providesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
    createOrganization: build.mutation({
      query: ({ organization, lightLogo, darkLogo }) => {
        const formData = prepareFormData({
          organization,
          lightLogo,
          darkLogo,
          arrayKeys: ["featureFlags", "linkedStakeholderIds"],
        });

        return {
          url: API_ROUTE,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
    updateOrganization: build.mutation({
      query: ({ organizationId, organization, lightLogo, darkLogo }) => {
        const formData = prepareFormData({
          organization,
          lightLogo,
          darkLogo,
          arrayKeys: ["featureFlags", "defaultRoleIds", "linkedStakeholderIds"],
        });

        return {
          url: `${API_ROUTE}/${organizationId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
    unarchiveOrganization: build.mutation({
      query: ({ organizationId }) => {
        return {
          url: `${API_ROUTE}/${organizationId}/unarchive`,
          method: "PUT",
        };
      },
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
    deleteOrganization: build.mutation({
      query: ({ organizationId }) => {
        return {
          url: `${API_ROUTE}/${organizationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationLogoQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUnarchiveOrganizationMutation,
} = extendedApi;
