import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@tsc/component-library/lib/components";
import { EMAIL_REGEX } from "@tsc/component-library/lib/utilities/regex";
import { isEmpty } from "lodash";

import RoleInput from "components/AccessControl/RoleInput";

export const AddUserDialog = ({
  open,
  setOpen,
  onSubmit,
  organizationId,
  objectType,
  managementType,
}) => {
  const [emailBlurred, setEmailBlurred] = useState(false);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    setEmail("");
    setDisplayName("");
    setUserRoles([]);
  }, [open]);

  const isEmailValid = EMAIL_REGEX.test(email);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          id="id"
          label="Email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => setEmailBlurred(true)}
          error={emailBlurred && !isEmailValid}
          helperText={
            emailBlurred && !isEmailValid
              ? "Please enter a valid email address"
              : ""
          }
        />
        <TextField
          margin="normal"
          id="id"
          label="Display Name"
          fullWidth
          variant="outlined"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <RoleInput
          value={userRoles}
          onChange={setUserRoles}
          organizationId={organizationId}
          objectType={objectType}
          managementType={managementType}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!isEmailValid || !displayName || isEmpty(userRoles)}
          onClick={() => {
            setOpen(false);
            onSubmit({ email: email.toLowerCase(), displayName, userRoles });
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
