import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { object, string } from "zod";

import { serverErrorNotification } from "features/notifications/notificationSlice";
import { MESSAGES } from "utilities/validation";

const COMMON_INPUT_PROPS = {
  fullWidth: true,
  sx: { mb: 1 },
};

const registerSchema = object({
  email: string().nonempty(MESSAGES.required).email(MESSAGES.email),
  password: string().nonempty(MESSAGES.required),
});

export default function SignInWithPassword({
  onPasswordResetClick,
  onSignUpClick,
}) {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
  });

  const onSubmitHandler = (values) => {
    const { email, password } = values;
    signInWithEmailAndPassword(getAuth(), email, password).catch((error) => {
      dispatch(serverErrorNotification(error));
    });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <TextField
        {...COMMON_INPUT_PROPS}
        placeholder="Email"
        error={!!errors["email"]}
        helperText={errors["email"]?.message}
        {...register("email")}
      />
      <TextField
        {...COMMON_INPUT_PROPS}
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        error={!!errors["password"]}
        helperText={errors["password"]?.message}
        {...register("password")}
      />
      <FormControlLabel
        control={<Checkbox checked={showPassword} />}
        label="Show password"
        onChange={(event) => setShowPassword(event.target.checked)}
      />
      <Button {...COMMON_INPUT_PROPS} variant="contained" type="submit">
        Sign In
      </Button>
      <Typography variant="subtitle1" align="center" color="text.secondary">
        Forgot your password?{" "}
        <Link href="#" onClick={onPasswordResetClick}>
          Reset password now.
        </Link>
      </Typography>
      <Typography variant="subtitle1" align="center" color="text.secondary">
        No account?{" "}
        <Link href="#" onClick={onSignUpClick}>
          Sign up.
        </Link>
      </Typography>
    </Box>
  );
}
