import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { doc, getDoc } from "firebase/firestore";

import { db } from "configurations/firebase";
import {
  setCountries,
  setIndustries,
  setSubcountries,
} from "features/classification/classificationSlice";
import { serverErrorNotification } from "features/notifications/notificationSlice";

export const useClassification = () => {
  const dispatch = useDispatch();

  const fetchCountries = async () => {
    try {
      const { countries } = (
        await getDoc(doc(db, "master_data/country_mapping"))
      ).data();
      dispatch(setCountries({ countries }));
    } catch (error) {
      dispatch(serverErrorNotification());
    }
  };

  const fetchSubCountries = async () => {
    try {
      const { subcountries } = (
        await getDoc(doc(db, "master_data/subcountry_mapping"))
      ).data();
      dispatch(setSubcountries({ subcountries }));
    } catch (error) {
      dispatch(serverErrorNotification());
    }
  };

  const fetchIndustries = async () => {
    try {
      const documents = await getDoc(doc(db, "master_data/industry_mapping"));
      const industries = documents.data()?.industries ?? {};
      dispatch(setIndustries({ industries }));
    } catch (error) {
      dispatch(serverErrorNotification());
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchSubCountries();
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
