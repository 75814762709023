import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import {
  Avatar,
  Box,
  DataGridPro,
  SkeletonLoader,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";

import { hasPermissionToken } from "utilities/data";

const OrganizationTable = ({
  currentUser,
  value,
  isFetching,
  onEdit = () => {},
  onDelete = () => {},
  onUnarchive = () => {},
  ...tableProps
}) => {
  const theme = useTheme();
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const data = isFetching
    ? [...Array(20)].map((_, i) => ({ id: i, isFetching: true }))
    : value;

  const columnDefinition = [
    {
      headerName: "Name",
      field: "name",
      renderCell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          data-testid="SavedFilterRow"
          width="90%"
        >
          <SkeletonLoader
            loading={row.isFetching}
            variant="rounded"
            width={`${140 + Math.abs(Math.sin(row.id)) * 200}px`}
            height="16px"
          >
            <Avatar> {row?.name[0]}</Avatar>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
              color={row?.deletedAt ? "text.disabled" : undefined}
            >
              {(row?.deletedAt ? "[ARCHIVED] " : "") + row?.name}
            </Typography>
          </SkeletonLoader>
        </Stack>
      ),
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Description",
      field: "description",
      renderCell: ({ row, value }) => (
        <SkeletonLoader
          loading={row.isFetching}
          variant="rounded"
          height="24px"
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "90%",
            }}
            color={row?.deletedAt ? "text.disabled" : undefined}
          >
            {value}
          </Typography>
        </SkeletonLoader>
      ),
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "Creator",
      field: "createdById",
      renderCell: ({ row, value }) => (
        <SkeletonLoader
          loading={row.isFetching}
          variant="rounded"
          height="24px"
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "90%",
            }}
          >
            {row?.creator}
          </Typography>
        </SkeletonLoader>
      ),
      sortable: false,
      width: 150,
    },
    {
      headerName: "Action",
      field: "editorPermissions",
      type: "actions",
      getActions: ({ row }) => {
        let actions = [
          <GridActionsCellItem
            key={`edit-${row.id}`}
            disabled={
              !hasPermissionToken(
                row.permissions,
                OBJECT_TYPE.ORGANIZATION,
                OBJECT_NAME.UPDATE
              )
            }
            label="Edit"
            onClick={() => onEdit(row)}
            showInMenu
          />,
        ];

        if (!row?.deletedAt) {
          actions.push(
            <GridActionsCellItem
              key={`delete-${row.id}`}
              disabled={
                !hasPermissionToken(
                  row.permissions,
                  OBJECT_TYPE.ORGANIZATION,
                  OBJECT_NAME.DELETE
                )
              }
              label="Archive"
              onClick={() => onDelete(row)}
              showInMenu
            />
          );
        }

        if (row?.deletedAt) {
          actions.push(
            <GridActionsCellItem
              key={`delete-${row.id}`}
              disabled={
                !hasPermissionToken(
                  row.permissions,
                  OBJECT_TYPE.ORGANIZATION,
                  OBJECT_NAME.UPDATE
                )
              }
              label="Unarchive"
              onClick={() => onUnarchive(row)}
              showInMenu
            />
          );
        }

        return actions;
      },
      headerAlign: "left",
      align: "left",
      sortable: false,
      width: 70,
    },
  ];

  return (
    <DataGridPro
      style={{ border: "none" }}
      columns={columnDefinition}
      rows={data}
      filterMode="server"
      sortingMode="server"
      rowsLoadingMode="server"
      rowSelection={false}
      disableColumnMenu
      hideFooter
      disableColumnResize
      slots={{
        loadingOverlay: Box,
        moreActionsIcon: MoreActionsButton,
      }}
      sortingOrder={["asc", "desc"]}
      columnVisibilityModel={{
        type: isXLargeScreen,
        class: isLargeScreen,
        subscriptionStatus: isLargeScreen || isMediumScreen,
        country: isLargeScreen,
      }}
      sx={{
        "& .MuiDataGrid-columnHeader": {
          display: isFetching ? "none" : "block",
        },
      }}
      {...tableProps}
    />
  );
};

const MoreActionsButton = () => {
  return <MoreHorizIcon sx={{ fontSize: 24 }} />;
};

export default OrganizationTable;
