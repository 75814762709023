import { useRef } from "react";

export const useScrollRef = () => {
  const ref = useRef();

  const scrollToTop = () => {
    ref.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    ref.current?.scrollTo({
      top: ref.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return [ref, scrollToTop, scrollToBottom];
};
