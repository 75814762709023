import { forwardRef } from "react";
import { Stack } from "@tsc/component-library/lib/components";

import NotFound from "components/NotFound/NotFound";
import PageHeader from "components/PageHeader/PageHeader";

export const INTERNAL_PAGE_PADDING = 2;

const Page = ({ children, filters, hidePageHeader, errorCode }, ref) => {
  const isConflictOrNotFound = errorCode === 409 || errorCode === 404;

  if (isConflictOrNotFound) {
    return <NotFound />;
  }

  return (
    <Stack ref={ref} gap={2} p={2}>
      {!hidePageHeader && <PageHeader>{filters}</PageHeader>}
      {children}
    </Stack>
  );
};

export default forwardRef(Page);
