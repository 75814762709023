import React from "react";
import {
  Avatar,
  Box,
  Button,
  Typography,
} from "@tsc/component-library/lib/components";

const OrganizationAvatar = ({
  name,
  avatar,
  organizationName,
  fileInputRef,
  handleAvatarChange,
  handleButtonClick,
  isDarkMode = false,
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "auto",
        width: "fit-content",
        alignItems: "center",
      }}
    >
      {!isDarkMode ? (
        <Typography fontWeight={700}>
          <Typography component="span" color="error">
            *
          </Typography>
          {name}
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography>{name}</Typography>
        </Box>
      )}
      <Avatar
        sx={{ width: "78px", height: "78px" }}
        src={avatar}
        // children={organizationName.substring(0, 1)}
      >
        {organizationName.substring(0, 1)}
      </Avatar>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(e) => handleAvatarChange(e, isDarkMode)}
        accept="image/jpeg, image/jpg, image/png" // Accept only image files
      />
      <Button
        component="label"
        variant="contained"
        sx={{ mt: 1.5 }}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        Change Avatar
      </Button>
    </Box>
  );
};

export default OrganizationAvatar;
