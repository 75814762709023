import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  PaginatedList,
  Stack,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { omit } from "lodash";
import queryString from "query-string";

import {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
  useUnarchiveOrganizationMutation,
  useUpdateOrganizationMutation,
} from "api/organizations";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import {
  selectPermissions,
  selectUser,
} from "features/authentication/authenticationSlice";
import RestrictedAccessTooltip from "features/authentication/RestrictedAccessTooltip/RestrictedAccessTooltip";
import {
  addNotification,
  serverErrorNotification,
} from "features/notifications/notificationSlice";
import { OrganizationDialog } from "features/organizations/OrganizationDialog/OrganizationDialog";
import OrganizationFilter from "features/organizations/OrganizationFilter/OrganizationFilter";
import OrganizationTable from "features/organizations/OrganizationTable/OrganizationTable";
import { useScrollRef } from "hooks/useScrollRef";
import { filter, paginate } from "utilities/array.js";
import { checkGlobalManagementRolePermission } from "utilities/data";

const DEFAULT_FILTER = {
  page: 1,
  pageSize: 20,
  keyword: "",
};

const Organization = () => {
  const [scrollRef, scrollToTop] = useScrollRef();
  const [filters, setFilters] = useState(DEFAULT_FILTER);
  const [open, setOpen] = useState(false);
  const [currentOrg, setCurrentOrg] = useState(undefined);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const permissions = useSelector(selectPermissions);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const { page, pageSize } = filters;

  const handleSearch = (newFilters) => {
    setFilters((filters) => ({ ...filters, ...newFilters }));
    scrollToTop();
  };

  const { data: { data: organizations = [] } = {} } = useGetOrganizationsQuery({
    query: queryString.stringify(filters),
  });

  const [createOrganization] = useCreateOrganizationMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [unarchiveOrganization] = useUnarchiveOrganizationMutation();

  const CreateButton = () => {
    const hasCreatePermission = checkGlobalManagementRolePermission(
      permissions,
      OBJECT_TYPE.ORGANIZATION,
      OBJECT_NAME.CREATE
    );
    return (
      <Stack direction="row" justifyContent="flex-end" width="100%">
        <RestrictedAccessTooltip hasPermission={hasCreatePermission}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ alignSelf: "flex-end" }}
            disabled={!hasCreatePermission}
            onClick={() => {
              setCurrentOrg(undefined);
              setOpen(true);
            }}
          >
            CREATE
          </Button>
        </RestrictedAccessTooltip>
      </Stack>
    );
  };

  const handleSubmit = async (organization, lightLogo, darkLogo) => {
    try {
      if (organization.id) {
        await updateOrganization({
          organizationId: organization.id,
          organization: prepareOrganizationPayload(organization),
          lightLogo,
          darkLogo,
        }).unwrap();
      } else {
        await createOrganization({
          organization,
          lightLogo,
          darkLogo,
        }).unwrap();
      }
      setOpen(false);
      dispatch(
        addNotification({ message: "Organization saved", severity: "success" })
      );
    } catch (e) {
      dispatch(serverErrorNotification(e));
    }
  };

  const handleArchive = async () => {
    await deleteOrganization({ organizationId: currentOrg.id });
    setIsConfirmDialogOpen(false);
  };

  const handleUnarchive = async (organization) => {
    await unarchiveOrganization({ organizationId: organization.id });
  };

  const organizationsFiltered = filterOrgs(organizations, filters);
  return (
    <Page
      ref={scrollRef}
      filters={
        <OrganizationFilter
          parentFilters={filters}
          onSearch={handleSearch}
          disabledSearch={false}
        />
      }
    >
      <SubPage
        data-testid="OrganizationTable"
        empty={!organizationsFiltered?.length}
        emptyMessage="The list of organizations is empty."
        emptyChildren={<CreateButton />}
      >
        <PaginatedList
          loading={false}
          page={page}
          pageSize={pageSize}
          totalCount={organizationsFiltered?.length}
          handleSearch={handleSearch}
        >
          <CreateButton />
          <OrganizationTable
            currentUser={user}
            isLoading={false}
            value={paginate(organizationsFiltered, page, pageSize)}
            onEdit={(organization) => {
              setCurrentOrg(organization);
              setOpen(true);
            }}
            onDelete={(organization) => {
              setCurrentOrg(organization);
              setIsConfirmDialogOpen(true);
            }}
            onUnarchive={(organization) => {
              handleUnarchive(organization);
            }}
            rowHeight={72}
          />
        </PaginatedList>
      </SubPage>
      <OrganizationDialog
        inputOrganization={currentOrg}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        confirmationMessage={currentOrg?.name}
        title="Archive Organization"
        message="Do you really want to archive this Organization?"
        onConfirm={handleArchive}
        onClose={() => setIsConfirmDialogOpen(false)}
      />
    </Page>
  );
};

const filterOrgs = (organizations, filters) => {
  const { keyword } = filters;

  return filter(organizations, {
    name: keyword,
  });
};

export default Organization;

function prepareOrganizationPayload(organization) {
  return omit(organization, ["deletedAt"]);
}
