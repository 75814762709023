export const OBJECT_TYPE = {
  MANAGEMENT: "management",
  ORGANIZATION: "organization",
  PROJECT: "project",
  LIBRARY_ISSUE: "libraryIssue",
  ORGANIZATION_ISSUE: "organizationIssue",
  MEDIA: "media",
  STAKEHOLDER: "stakeholder",
  SUPERPROMPTS: "superprompts",
};
