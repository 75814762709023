import React from "react";
import {
  Box,
  Chip,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import dayjs from "dayjs";

import MediaLink from "features/media/MediaLink/MediaLink";

/**
 * To render Media Card component.
 * @param {object} props Properties of Media Card component.
 * @param {object} props.media Media data.
 * @param {boolean} props.isFetching Loading state of media data.
 */
const MediaCard = ({ media, isFetching }) => {
  if (isFetching) {
    return <MediaCardSkeleton />;
  }

  return (
    <Paper elevation={5} sx={{ p: 2 }}>
      <Typography variant="h6" color="text.primary">
        {media?.title}
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        mt={1}
        spacing={1}
        alignItems="center"
      >
        {media?.publishedAt && (
          <Typography variant="body1" color="text.secondary">
            {dayjs(media.publishedAt).format("D MMM YYYY [at] h:mm A")}
          </Typography>
        )}
        {media?.url && <MediaLink url={media.url} />}
        {media?.score && (
          <Chip
            size="small"
            label={`Similarity score: ${Math.floor(media.score * 100) / 100}`}
            color="error"
            variant="filled"
            sx={{ backgroundColor: "error.light" }}
            elevation={0}
          />
        )}
      </Stack>
      {media?.content && (
        <Typography variant="body1" color="text.secondary" mt={2}>
          {media.content}
        </Typography>
      )}
    </Paper>
  );
};

const MediaCardSkeleton = () => {
  return (
    <Paper elevation={5} sx={{ p: 2 }}>
      <Skeleton height={30} width="75%" />
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        mt={1}
        spacing={1}
        alignItems="center"
      >
        <Skeleton height={24} width={80} />
        <Skeleton height={24} width={80} />
        <Skeleton height={24} width={80} />
      </Stack>
      <Box mt={2} gap={1}>
        <Skeleton height={24} width="100%" />
        <Skeleton height={24} width="100%" />
        <Skeleton height={24} width="100%" />
      </Box>
    </Paper>
  );
};

export default MediaCard;
