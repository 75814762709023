import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

const DeleteIssueDialog = ({ issue, isOpen, isLoading, onClose, onDelete }) => {
  const [confirmedIssueName, setConfirmedIssueName] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setConfirmedIssueName("");
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Delete
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 600, pb: 3 }}>
        <Typography>
          Do you really want to delete this issue? This process cannot be
          undone.
        </Typography>
        <Typography mt={2} variant="body2" fontWeight={700}>
          Confirm by typing{" "}
          <Typography variant="span" color="error">
            [{issue?.name}]
          </Typography>{" "}
          below
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          placeholder={issue?.name}
          value={confirmedIssueName}
          onChange={(event) => setConfirmedIssueName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isLoading}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          startIcon={
            isLoading ? <CircularProgress size={14} color="inherit" /> : null
          }
          disabled={isLoading || confirmedIssueName !== issue?.name}
          onClick={() => onDelete(issue)}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteIssueDialog.defaultProps = {
  issue: null,
  isOpen: false,
  isLoading: false,
  onClose: () => {},
  onDelete: () => {},
};

export default DeleteIssueDialog;
