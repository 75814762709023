import api, { TAG_IDS, TAG_TYPES } from "api";
import {
  addNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "media/api/v1/issues";

const handleQueryStarted = async (result, { dispatch, queryFulfilled }) => {
  await queryFulfilled;
  dispatch(
    addNotification({
      message: "Issue deleted",
      severity: SEVERITY.SUCCESS,
      autoHide: true,
    })
  );
};

const issuesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLibraryIssues: build.query({
      query: ({ query }) => `${API_ROUTE}?${query}`,
      providesTags: providesTagsList(TAG_TYPES.ISSUES),
    }),
    getLibraryIssueById: build.query({
      query: ({ issueId }) => `${API_ROUTE}/${issueId}`,
      providesTags: (result, error, { issueId }) => [
        { type: TAG_TYPES.ISSUES, id: issueId },
      ],
    }),
    createLibraryIssue: build.mutation({
      query: (data) => ({
        url: API_ROUTE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        }
        return [{ type: TAG_TYPES.ISSUES, id: TAG_IDS.LIST }];
      },
    }),
    updateLibraryIssue: build.mutation({
      query: (data) => ({
        url: `${API_ROUTE}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => {
        if (error) {
          return [];
        }
        return [{ type: TAG_TYPES.ISSUES, id }];
      },
    }),
    deleteLibraryIssue: build.mutation({
      query: ({ issueId }) => ({
        url: `${API_ROUTE}/${issueId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { issueId }) => {
        if (error) {
          return [];
        }
        return [{ type: TAG_TYPES.ISSUES, id: issueId }];
      },
      onQueryStarted: handleQueryStarted,
    }),
  }),
});

export const {
  useGetLibraryIssuesQuery,
  useGetLibraryIssueByIdQuery,
  useCreateLibraryIssueMutation,
  useUpdateLibraryIssueMutation,
  useDeleteLibraryIssueMutation,
} = issuesApi;
