import { useState } from "react";
import { useDispatch } from "react-redux";
import reactModal from "@prezly/react-promise-modal";
import {
  Box,
  Button,
  SvgIcon,
  Typography,
} from "@tsc/component-library/lib/components";
import { FIREBASE_AUTH_ERROR } from "enums/firebaseAuthError";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import keyMirror from "keymirror";

import { ReactComponent as GoogleLogo } from "assets/vectors/google_logo.svg";
import { ReactComponent as LinkLogo } from "assets/vectors/link_logo.svg";
import { ReactComponent as MicrosoftLogo } from "assets/vectors/microsoft_logo.svg";
import { auth } from "configurations/firebase";
import {
  accessDeniedNotification,
  serverErrorNotification,
} from "features/notifications/notificationSlice";

import SignInLayout from "../SignInMethods/AuthenticationLayout";
import PasswordDialog from "../SignInMethods/PasswordDialog";
import PasswordReset from "../SignInMethods/PasswordReset";
import SignInWithMagicLink from "../SignInMethods/SignInWithMagicLink";
import SignInWithPassword from "../SignInMethods/SignInWithPassword";
import SignUpWithPassword from "../SignInMethods/SignUpWithPassword";
import TSCLogo from "../SignInMethods/TSCLogo";

const METHOD = keyMirror({
  SIGN_UP: null,
  SIGN_IN: null,
  PASSWORD_RESET: null,
  MAGIC_LINK: null,
});

function SignInButtons() {
  const [method, setMethod] = useState(METHOD.SIGN_UP);
  const dispatch = useDispatch();

  const handleClick = async (provider) => {
    provider.setCustomParameters({ prompt: "select_account" });
    signInWithPopup(auth, provider).catch((error) => {
      if (
        error.code === FIREBASE_AUTH_ERROR.CANCELLED_POPUP_REQUEST ||
        error.code === FIREBASE_AUTH_ERROR.POPUP_CLOSED_BY_USER
      ) {
        return;
      }

      // Step 1.
      // User tries to sign in to Google.
      if (
        error.code ===
        FIREBASE_AUTH_ERROR.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL
      ) {
        // Step 2.
        // User's email already exists.
        // The pending credential.
        const pendingCred = OAuthProvider.credentialFromError(error);
        // The provider account's email address.
        const email = error.customData.email;
        // Get sign-in methods for this email.
        fetchSignInMethodsForEmail(getAuth(), email).then((methods) => {
          // Step 3.
          // If the user has several sign-in methods,
          // the first method in the list will be the "recommended" method to use.
          if (methods[0] === "password") {
            // Asks the user their password.
            reactModal(({ show, onSubmit, onDismiss }) => (
              <PasswordDialog
                open={show}
                onSubmit={onSubmit}
                onClose={onDismiss}
              />
            )).then((result) => {
              if (result) {
                const { password } = result;
                signInWithEmailAndPassword(auth, email, password)
                  .then(function (result) {
                    // Step 4a.
                    return linkWithCredential(result.user, pendingCred);
                  })
                  .catch(() => {
                    dispatch(serverErrorNotification());
                  });
              } else {
                dispatch(serverErrorNotification());
              }
            });

            return;
          }
          // All the other cases are external providers.
          // Construct provider object for that provider.
          const provider = getProviderForProviderId(methods[0]);
          // At this point, you should let the user know that they already have an account
          // but with a different provider, and let them validate the fact they want to
          // sign in with this provider.
          window.alert(
            "You have previously logged in with a different method. Please log in using that method again and we will link it to the new method you have selected."
          );
          // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
          // so in real scenario you should ask the user to click on a "continue" button
          // that will trigger the signInWithPopup.
          signInWithPopup(auth, provider)
            .then((result) => {
              // Remember that the user may have signed in with an account that has a different email
              // address than the first one. This can happen as Firebase doesn't control the provider's
              // sign in flow and the user is free to login using whichever account they own.
              // Step 4b.
              // Link to Google credential.
              // As we have access to the pending credential, we can directly call the link method.
              linkWithCredential(result.user, pendingCred);
            })
            .catch(() => {
              dispatch(serverErrorNotification());
            });
        });
      } else {
        dispatch(
          accessDeniedNotification({
            message:
              "You are not authorized to access this system. If you wish to continue, please contact our team at info@tsc.ai",
          })
        );
      }
    });
  };

  return (
    <SignInLayout>
      {[METHOD.SIGN_UP, METHOD.SIGN_IN].includes(method) && (
        <>
          <TSCLogo />
          <Box mb={2}>
            <Typography variant="h5">Sign up or sign in to start</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Please choose a method to continue.
            </Typography>
          </Box>
          <AuthButton
            onClick={() => handleClick(new GoogleAuthProvider())}
            starIconComponent={GoogleLogo}
          >
            Continue with Google
          </AuthButton>
          <AuthButton
            onClick={() => handleClick(new OAuthProvider("microsoft.com"))}
            starIconComponent={MicrosoftLogo}
          >
            Continue with Microsoft
          </AuthButton>
          <AuthButton
            onClick={() => setMethod(METHOD.MAGIC_LINK)}
            starIconComponent={LinkLogo}
          >
            Continue with a link via email
          </AuthButton>
          <Box m={2}>
            <Typography variant="subtitle1" align="center">
              or
            </Typography>
          </Box>
        </>
      )}
      {method === METHOD.SIGN_UP && (
        <SignUpWithPassword onSignInClick={() => setMethod(METHOD.SIGN_IN)} />
      )}
      {method === METHOD.SIGN_IN && (
        <SignInWithPassword
          onPasswordResetClick={() => setMethod(METHOD.PASSWORD_RESET)}
          onSignUpClick={() => setMethod(METHOD.SIGN_UP)}
        />
      )}
      {method === METHOD.PASSWORD_RESET && (
        <PasswordReset onBack={() => setMethod(METHOD.SIGN_UP)} />
      )}
      {method === METHOD.MAGIC_LINK && (
        <SignInWithMagicLink onBack={() => setMethod(METHOD.SIGN_UP)} />
      )}
    </SignInLayout>
  );
}

export default SignInButtons;

function AuthButton({ onClick, children, starIconComponent }) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={onClick}
      sx={{ width: "100%", mb: 1 }}
      startIcon={
        <SvgIcon
          component={starIconComponent || GoogleLogo}
          viewBox="0 0 18 18"
          sx={{ width: "18px", height: "18px", mr: "22px" }}
        />
      }
    >
      {children}
    </Button>
  );
}

function getProviderForProviderId(providerId) {
  switch (providerId) {
    case "google.com":
      return new GoogleAuthProvider();
    case "microsoft.com":
      return new OAuthProvider("microsoft.com");
    default:
      throw new Error(`No provider found for ${providerId}`);
  }
}
