import { useState } from "react";
import { useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import {
  Box,
  ConfirmationDialog,
  CopyButton,
  MaterialSymbol,
  Tooltip,
  TruncatedText,
} from "@tsc/component-library/lib/components";
import { markdownToHtml } from "@tsc/component-library/lib/utilities/markdown";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import {
  STAKEHOLDER_SOURCE,
  STAKEHOLDER_SOURCE_PRIORITY,
} from "enums/stakeholderSource";
import lodash, { isEmpty, pick, some } from "lodash";

import {
  useDeleteStakeholderAttributeMutation,
  useReingestAttributeMutation,
  useUpsertStakeholderAttributeMutation,
} from "api/stakeholders";
import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { selectPermissions } from "features/authentication/authenticationSlice";
import StakeholderAttributeEditorDialog from "features/stakeholders/StakeholderAttributesTable/StakeholderAttributeEditorDialog";
import StakeholderSourceAvatar from "features/stakeholders/StakeholderSourceAvatar/StakeholderSourceAvatar";
import UnlinkAttributeDialog from "features/stakeholders/UnlinkAttributeDialog/UnlinkAttributeDialog";
import { checkGlobalManagementRolePermission } from "utilities/data";
import { getStakeholderAttribute } from "utilities/stakeholder";
import { convertHtmlToPlain } from "utilities/string";

export const StakeholderAttributesTable = ({ stakeholder }) => {
  const [attributeToUnlink, setAttributeToUnlink] = useState(null);
  const userPermissions = useSelector(selectPermissions);
  const hasUpdatePermission = checkGlobalManagementRolePermission(
    userPermissions,
    OBJECT_TYPE.STAKEHOLDER,
    OBJECT_NAME.UPDATE
  );
  const [attributeToEdit, setAttributeToEdit] = useState(null);
  const [rowReingestedIds, setRowReingestedIds] = useState([]);
  const [attributeToDelete, setAttributeToDelete] = useState(null);

  const [
    upsertStakeholderAttribute,
    { isLoading: isUpsertingStakeholderAttribute },
  ] = useUpsertStakeholderAttributeMutation();
  const [reingestStakeholderAttribute] = useReingestAttributeMutation();
  const [
    deleteStakeholderAttribute,
    { isLoading: isDeletingStakeholderAttribute },
  ] = useDeleteStakeholderAttributeMutation();

  const handleEdit = (stakeholderAttribute) => {
    setAttributeToEdit(stakeholderAttribute);
  };

  const handleSaveStakeholderAttribute = async (stakeholderAttribute) => {
    await upsertStakeholderAttribute({
      stakeholderId: stakeholder.id,
      stakeholderAttribute: pick(stakeholderAttribute, [
        "name",
        "headline",
        "summary",
        "source",
      ]),
    }).unwrap();
    setAttributeToEdit(null);
  };

  const handleReingest = (stakeholderAttribute) => {
    setRowReingestedIds([...rowReingestedIds, stakeholderAttribute.id]);
    reingestStakeholderAttribute({
      stakeholderId: stakeholderAttribute.stakeholderId,
      stakeholderAttributeId: stakeholderAttribute.id,
    });
  };

  const handleDelete = async (stakeholderAttribute) => {
    await deleteStakeholderAttribute({
      stakeholderId: stakeholderAttribute.stakeholderId,
      stakeholderAttributeId: stakeholderAttribute.id,
    }).unwrap();
    setAttributeToDelete(null);
  };

  const handleUnlink = (stakeholderAttribute) => {
    setAttributeToUnlink(stakeholderAttribute);
  };

  const handleClose = () => {
    setAttributeToUnlink(null);
  };

  const stakeholderAttributes = lodash
    .chain(stakeholder?.stakeholderAttributes ?? [])
    .clone()
    .push({ source: STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE })
    .push({ source: STAKEHOLDER_SOURCE.GPT })
    .uniqBy("source")
    .sortBy(
      ({ source }) =>
        STAKEHOLDER_SOURCE_PRIORITY[source] ?? Number.MAX_SAFE_INTEGER
    )
    .value();

  stakeholderAttributes.unshift({
    id: -1,
    photo: getStakeholderAttribute(stakeholder, "photo"),
    name: getStakeholderAttribute(stakeholder, "name"),
    headline: getStakeholderAttribute(stakeholder, "headline"),
    summary: getStakeholderAttribute(stakeholder, "summary"),
  });

  return (
    <>
      <DataGridPro
        data-testid="stakeholderAttributesTable"
        columns={buildColumnDefinition(
          stakeholder,
          rowReingestedIds,
          handleEdit,
          handleUnlink,
          handleReingest,
          setAttributeToDelete,
          hasUpdatePermission
        )}
        rows={stakeholderAttributes}
        getRowId={(row) => row.source ?? Number.MAX_SAFE_INTEGER}
        getRowClassName={(params) => (!params.row.source ? "displayed" : "")}
        sx={{
          "& .displayed": {
            bgcolor: (theme) => `${theme.palette.action.focus} !important`,
          },
        }}
      />
      <StakeholderAttributeEditorDialog
        open={!!attributeToEdit}
        onClose={() => setAttributeToEdit(null)}
        onSubmit={handleSaveStakeholderAttribute}
        stakeholder={stakeholder}
        value={attributeToEdit}
        loading={isUpsertingStakeholderAttribute}
      />
      <UnlinkAttributeDialog
        stakeholder={stakeholder}
        stakeholderAttribute={attributeToUnlink}
        open={attributeToUnlink !== null}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={!!attributeToDelete}
        title="Delete Stakeholder Attribute"
        message="Are you sure you want to delete this stakeholder attribute?"
        isLoading={isDeletingStakeholderAttribute}
        confirmButtonColor="error"
        confirmButtonText="Delete"
        onConfirm={() => handleDelete(attributeToDelete)}
        onClose={() => setAttributeToDelete(null)}
      />
    </>
  );
};

export default StakeholderAttributesTable;

const buildColumnDefinition = (
  stakeholder,
  rowReingestedIds,
  onEdit,
  onUnlink,
  onReingest,
  onDelete,
  hasUpdatePermission
) => [
  {
    headerName: "Source",
    field: "source",
    type: "actions",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: stakeholderAttribute }) =>
      stakeholderAttribute.id !== -1 && (
        <StakeholderSourceAvatar
          stakeholder={stakeholder}
          stakeholderAttribute={stakeholderAttribute}
        />
      ),
    sortable: false,
    minWidth: 100,
  },
  {
    headerName: "Photo",
    field: "photo",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: stakeholderAttribute }) => (
      <StakeholderAvatar
        value={{
          ...stakeholder,
          stakeholderAttributes: [stakeholderAttribute],
        }}
      />
    ),
    sortable: false,
    minWidth: 50,
    width: 80,
  },
  {
    headerName: "Name",
    field: "name",
    renderCell: ({ value }) => <TruncatedText text={value} />,
    sortable: false,
    minWidth: 200,
  },
  {
    headerName: "Headline",
    field: "headline",
    renderCell: ({ value }) => <TruncatedText text={value} />,
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Summary",
    field: "summary",
    renderCell: SummaryCell,
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "",
    field: "action",
    type: "actions",
    headerAlign: "center",
    align: "left",
    sortable: false,
    minWidth: 120,
    getActions: ({ row: stakeholderAttribute }) => {
      let actions = [];
      const isReingestionTriggered = rowReingestedIds.includes(
        stakeholderAttribute.id
      );

      if (
        stakeholderAttribute.source &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GPT
      ) {
        actions.push(
          <Tooltip title="Unlink">
            <GridActionsCellItem
              onClick={() => onUnlink(stakeholderAttribute)}
              disabled={!hasUpdatePermission}
              icon={<MaterialSymbol symbol="link_off" />}
            />
          </Tooltip>
        );
      }

      if (
        stakeholderAttribute.source === STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE ||
        (stakeholderAttribute.source === STAKEHOLDER_SOURCE.GPT &&
          some(
            stakeholder?.stakeholderAttributes,
            (sa) => sa.source !== STAKEHOLDER_SOURCE.GPT && !isEmpty(sa.name)
          ) &&
          some(
            stakeholder?.stakeholderAttributes,
            (sa) =>
              sa.source !== STAKEHOLDER_SOURCE.GPT && !isEmpty(sa.headline)
          ))
      ) {
        actions.push(
          <GridActionsCellItem
            onClick={() => onEdit(stakeholderAttribute)}
            disabled={!hasUpdatePermission}
            icon={<MaterialSymbol symbol="edit" />}
          />
        );
      }

      if (
        stakeholderAttribute.source &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GENFIVE_AUTOMATION &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GPT
      ) {
        actions.push(
          <Tooltip title="Re-ingest">
            <GridActionsCellItem
              onClick={() => onReingest(stakeholderAttribute)}
              disabled={!hasUpdatePermission || isReingestionTriggered}
              icon={
                <MaterialSymbol
                  symbol="sync"
                  sx={{
                    transform: isReingestionTriggered
                      ? "rotate(-180deg)"
                      : "none",
                    transition: "transform 0.5s ease",
                  }}
                />
              }
            />
          </Tooltip>
        );
      }

      if (
        stakeholderAttribute.source &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE &&
        stakeholderAttribute.source !== STAKEHOLDER_SOURCE.GPT
      ) {
        actions.push(
          <Tooltip title="Delete">
            <GridActionsCellItem
              onClick={() => onDelete(stakeholderAttribute)}
              disabled={!hasUpdatePermission}
              icon={<MaterialSymbol symbol="delete" />}
            />
          </Tooltip>
        );
      }

      return actions;
    },
  },
];

const SummaryCell = ({ value }) => {
  const [isHover, setIsHover] = useState(false);
  const htmlText = markdownToHtml(value);
  const plainText = convertHtmlToPlain(htmlText);
  const clipboardItem = new ClipboardItem({
    "text/html": new Blob([htmlText], { type: "text/html" }),
    "text/plain": new Blob([plainText], { type: "text/plain" }),
  });
  return (
    <Box
      display="flex"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <TruncatedText text={value} />
      {isHover && value?.trim() && <CopyButton value={[clipboardItem]} />}
    </Box>
  );
};
