import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { MANAGEMENT_TYPES } from "enums/rbacManagementTypes";

import { selectPermissions } from "features/authentication/authenticationSlice";
import { checkGlobalManagementRolePermission } from "utilities/data";
import { transformUserRoles } from "utilities/user";

import { AddUserDialog } from "./AddUserDialog";

const ResourceUsers = ({
  users,
  roles,
  onCreateUser,
  onUpdateUser,
  organizationId,
  objectType,
  managementType,
}) => {
  const userPermissions = useSelector(selectPermissions);
  const hasUpdatePermission = checkGlobalManagementRolePermission(
    userPermissions,
    OBJECT_TYPE.MANAGEMENT,
    OBJECT_NAME.MANAGE
  );
  const [open, setOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [sortedEmails, setSortedEmails] = useState([]);

  const isCustomer = managementType === MANAGEMENT_TYPES.CUSTOMER;

  const hasRole = (user, roleId) => {
    if (isCustomer) {
      return user?.userRoles?.some((r) => r.roleId === roleId);
    } else {
      return user?.userManagementRoles?.some(
        (r) => r.managementRoleId === roleId
      );
    }
  };

  const mustDisplay = (user, managementType) => {
    if (managementType === MANAGEMENT_TYPES.CUSTOMER) {
      return true;
    } else {
      const isGlobalSelected =
        managementType === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT;
      return user.userManagementRoles.some(
        (role) => role.managementRole.global === isGlobalSelected
      );
    }
  };
  const canDisplay = (user, managementType) =>
    managementType !== MANAGEMENT_TYPES.CUSTOMER &&
    user.email.endsWith("@tsc.ai");

  const eligibleUsers = users
    .filter((user) => !["*", "server"].includes(user.email))
    .filter(
      (user) =>
        mustDisplay(user, managementType) || canDisplay(user, managementType)
    );

  const filteredRoles = roles.filter(({ name }) =>
    name.toLowerCase().includes(roleFilter.toLowerCase())
  );

  const filteredUsers = getUserDefinitions(
    eligibleUsers.filter(({ displayName, email }) =>
      `${displayName} ${email}`.toLowerCase().includes(userFilter.toLowerCase())
    ),
    isCustomer
  );

  useEffect(() => {
    setSortedEmails(
      users
        .toSorted(
          (a, b) =>
            filteredRoles.filter((role) => hasRole(b, role.id)).length -
              filteredRoles.filter((role) => hasRole(a, role.id)).length ||
            a?.meta?.localeCompare(b?.meta)
        )
        .map((user) => user.email)
    );
    // We do not sort again when role assignment change, as it can be confusing to the user
    // We only sort again when the user or role filter changes, and when some users or roles are added or deleted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter, roleFilter, users.length, filteredRoles.length]);

  const sortedUsers = sortedEmails
    .map((email) => filteredUsers.find((user) => user.email === email))
    .filter((user) => user);

  const getColumnDefinition = (roles) => [
    {
      headerName: "User",
      field: "meta",
      renderCell: ({ row, value }) => {
        return (
          <Typography
            color={
              roles.filter((role) => hasRole(row, role.id)).length
                ? "text.primary"
                : grey[400]
            }
          >
            {value}
          </Typography>
        );
      },
      minWidth: 250,
      sortable: false,
    },
    ...roles.map((role) => ({
      headerName: `${role.name} (${
        eligibleUsers?.filter((u) => hasRole(u, role.id)).length
      })`,
      field: role.id.toString(),
      renderCell: ({ row, value }) => {
        const checked = hasRole(row, role.id);
        return (
          <Checkbox
            checked={checked}
            disabled={!hasUpdatePermission}
            sx={{ color: grey[300] }}
            onClick={() => onUpdateUser({ ...row, [role.id]: !checked })}
          />
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
    })),
  ];

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} alignItems="baseline" mt={1}>
        <Typography variant="subtitle1" fontWeight="500">
          Users
        </Typography>
        <TextField
          value={userFilter}
          label="Filter Users"
          variant="outlined"
          size="small"
          onChange={(e) => setUserFilter(e.target.value)}
        />
        <TextField
          value={roleFilter}
          label="Filter Roles"
          variant="outlined"
          size="small"
          onChange={(e) => setRoleFilter(e.target.value)}
        />
        <Box flexGrow={1} />
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          ADD
        </Button>
        <AddUserDialog
          open={open}
          setOpen={setOpen}
          onSubmit={onCreateUser}
          organizationId={organizationId}
          objectType={objectType}
          managementType={managementType}
        />
      </Stack>
      <DataGridPro
        autoHeight
        style={{ border: "none" }}
        columns={getColumnDefinition(filteredRoles)}
        rows={sortedUsers}
        filterMode="server"
        sortingMode="server"
        rowsLoadingMode="server"
        rowSelection={false}
        disableColumnMenu
        hideFooter
        disableColumnResize
        sortingOrder={["asc", "desc"]}
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-cell": {
            border: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeader": {
            display: "block",
            backgroundColor: "#fafafa",
            border: "1px solid #f0f0f0",
          },
        }}
      />
    </Stack>
  );
};

export default ResourceUsers;

function getUserDefinitions(users, isCustomer) {
  return users.map((user) => transformUserRoles(user, isCustomer));
}
