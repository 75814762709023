import React, { useState } from "react";
import { useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  CustomPagination,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { isEmpty } from "lodash";

import { useGetConnectionsQuery } from "api/connections";
import Connection from "features/stakeholders/Connection/Connection";

const PAGE_SIZE = 5;

/**
 * To render connection card for people/organization.
 * @param {Object} properties
 * @param {string} properties.title Title of connection card.
 * @param {"person" | "org"} properties.nodeType Node type of opposite node of current stakeholder.
 * @returns Connection card component.
 */
const ConnectionSection = ({ title, type, sourceNodeType, targetNodeType }) => {
  const { stakeholderId } = useParams();
  const [page, setPage] = useState(1);
  const params = {
    types: [type],
    sourceNodeId: stakeholderId,
    sourceNodeType,
    targetNodeType,
    pageSize: PAGE_SIZE,
    page,
  };
  const { data: { data: connections = [], totalCount = 0 } = {}, isFetching } =
    useGetConnectionsQuery({ params });

  const renderContent = () => {
    if (isFetching) {
      return <Connection isFetching />;
    }

    if (isEmpty(connections)) {
      return (
        <Typography variant="body1" color="text.primary">
          Not Available
        </Typography>
      );
    }

    return (
      <>
        {connections.map((connection) => (
          <Connection
            key={connection.id}
            connection={connection}
            currentStakeholderId={Number(stakeholderId)}
          />
        ))}
        <Box display="flex" justifyContent="center">
          <CustomPagination
            page={page}
            pageSize={PAGE_SIZE}
            totalCount={totalCount}
            onChange={(_, page) => setPage(page)}
          />
        </Box>
      </>
    );
  };

  return (
    <Stack gap={2} m={2}>
      <Typography variant="subtitle2">
        {title || "Connection"} ({totalCount})
        <InfoOutlinedIcon
          fontSize="inherit"
          color="inherit"
          sx={{ ml: "8px", mb: "-2px" }}
        />
      </Typography>
      {renderContent()}
    </Stack>
  );
};

export default ConnectionSection;
