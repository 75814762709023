import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ShowMoreSection,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { isEmpty } from "lodash";

import Connection from "features/stakeholders/Connection/Connection";

const CareerEducationSection = ({ title, isFetching, data, stakeholderId }) => {
  const renderContent = () => {
    if (isFetching) {
      return <Connection isFetching />;
    }

    if (isEmpty(data)) {
      return (
        <Typography variant="body1" color="text.primary">
          Not Available
        </Typography>
      );
    }

    return (
      <ShowMoreSection>
        {data?.map((item) => (
          <Connection
            key={item.id}
            connection={item}
            currentStakeholderId={Number(stakeholderId)}
          />
        ))}
      </ShowMoreSection>
    );
  };

  return (
    <Stack gap={2} m={2}>
      <Typography variant="subtitle2">
        {title}
        <InfoOutlinedIcon
          fontSize="inherit"
          color="inherit"
          sx={{ ml: "8px", mb: "-2px" }}
        />
      </Typography>
      {renderContent()}
    </Stack>
  );
};

export default CareerEducationSection;
