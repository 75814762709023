import React, { useMemo } from "react";
import { buildURI } from "react-csv/src/core";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@tsc/component-library/lib/components";
import { isEmpty } from "lodash";

const ExportCsvButton = ({ header, data, disabled }) => {
  const href = useMemo(() => {
    if (isEmpty(data)) {
      return null;
    }
    return buildURI(data, null, header);
  }, [data, header]);

  return (
    <Button
      variant="outlined"
      startIcon={<DescriptionIcon />}
      disabled={!href || disabled}
      href={href}
    >
      EXPORT
    </Button>
  );
};

export default ExportCsvButton;
