import React from "react";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import { Chip, ChipsSkeleton } from "@tsc/component-library/lib/components";
import {
  STAKEHOLDER_CLASS,
  STAKEHOLDER_CLASS_LABEL,
} from "enums/stakeholderClass";

const CHIP_HEIGHT = 32;

export const StakeholderClassChip = ({
  stakeholder,
  loading,
  ...otherProps
}) => {
  const renderIcon = (stakeholderClass) => {
    switch (stakeholderClass) {
      case STAKEHOLDER_CLASS.AI:
        return <GroupsOutlinedIcon />;
      case STAKEHOLDER_CLASS.MANUAL:
        return <ContactPageOutlinedIcon />;
      case STAKEHOLDER_CLASS.PRIVATE:
        return <PrivacyTipOutlinedIcon />;
      default:
        return null;
    }
  };

  const renderLabel = (stakeholderClass) => {
    return STAKEHOLDER_CLASS_LABEL[stakeholderClass] ?? stakeholderClass;
  };

  if (loading) {
    return <ChipsSkeleton noOfChip={1} height={24} />;
  }

  if (!stakeholder?.class) {
    return null;
  }

  return (
    <Chip
      label={renderLabel(stakeholder?.class)}
      icon={renderIcon(stakeholder?.class)}
      {...otherProps}
      sx={{ pl: 0.6, height: CHIP_HEIGHT }}
    />
  );
};

export default StakeholderClassChip;
