import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  ChipToggle,
  Grid,
  IconButton,
  ShowMoreSection,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";
import { isEmpty, some } from "lodash";

import Stakeholder from "features/stakeholders/Stakeholder/Stakeholder";
import StakeholderDropdownPopup from "features/stakeholders/StakeholderDropdownPopup/StakeholderDropdownPopup";
import { count } from "utilities/array";

const StakeholderTaggingSection = ({
  stakeholders,
  readOnly,
  isFetching,
  onChange,
}) => {
  const [selectedStakeholderType, setSelectedStakeholderType] = useState(null);
  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState(null);
  const filteredStakeholders =
    stakeholders?.filter(
      (stakeholder) =>
        selectedStakeholderType === null ||
        stakeholder.type === selectedStakeholderType
    ) ?? [];

  const viewOptions = [
    { label: `All (${stakeholders?.length ?? 0})`, value: null },
    {
      label: `People (${count(stakeholders, "type", STAKEHOLDER_TYPE.PERSON)})`,
      value: STAKEHOLDER_TYPE.PERSON,
    },
    {
      label: `Organizations (${count(
        stakeholders,
        "type",
        STAKEHOLDER_TYPE.ORGANIZATION
      )})`,
      value: STAKEHOLDER_TYPE.ORGANIZATION,
    },
  ];

  const handleAddStakeholders = (values) => {
    if (!onChange || isEmpty(values)) {
      return;
    }
    onChange([...stakeholders, ...values]);
  };

  const handleDeleteStakeholder = (value) => {
    if (!onChange) {
      return;
    }
    onChange(stakeholders.filter((stakeholder) => stakeholder !== value));
  };

  return (
    <Stack>
      <Stack flexDirection="row" sx={{ position: "relative" }}>
        <Typography variant="subtitle2">Stakeholders</Typography>
        {!isFetching && !readOnly && (
          <>
            <IconButton
              color="secondary"
              disabled={readOnly}
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translate(8px, -8px)",
              }}
              onClick={(event) => setAddButtonAnchorEl(event.currentTarget)}
            >
              <AddIcon />
            </IconButton>
            <StakeholderDropdownPopup
              anchorEl={addButtonAnchorEl}
              open={!!addButtonAnchorEl}
              onClose={(values) => {
                setAddButtonAnchorEl(null);
                handleAddStakeholders(values);
              }}
              getOptionDisabled={(option) =>
                some(stakeholders, ({ id }) => id === option.id)
              }
            />
          </>
        )}
      </Stack>

      <Stack mt={2} gap={1} direction="row">
        <ChipToggle
          loading={isFetching}
          options={viewOptions}
          value={selectedStakeholderType}
          onChange={setSelectedStakeholderType}
        />
      </Stack>

      <Grid
        container
        mt={0}
        spacing={2}
        sx={{ "& > .MuiBox-root": { flexBasis: "100%", pt: 2 } }}
      >
        <ShowMoreSection limit={10}>
          {(isFetching ? [...Array(3)] : filteredStakeholders).map(
            (stakeholder, index) => (
              <Grid key={stakeholder?.id ?? index} item xs={12} md={6} xl={4}>
                <Stakeholder
                  value={stakeholder}
                  isFetching={isFetching}
                  onDelete={!readOnly && handleDeleteStakeholder}
                />
              </Grid>
            )
          )}
        </ShowMoreSection>
      </Grid>
    </Stack>
  );
};

export default StakeholderTaggingSection;
