import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import {
  Box,
  Chip,
  Divider,
  Paper,
  Skeleton,
  SkeletonLoader,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@tsc/component-library/lib/components";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "enums/date";
import { MEDIA_LANGUAGE_LABEL } from "enums/mediaLanguage";
import { MEDIA_TYPE_LABEL } from "enums/mediaType";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import {
  concat,
  forEach,
  isArray,
  isEqual,
  keys,
  map,
  partition,
  sortBy,
  uniq,
} from "lodash";

import api from "api";
import { useContentByIdQuery, usePatchContentMutation } from "api/media";
import Page from "components/Layout/Page";
import { selectPermissions } from "features/authentication/authenticationSlice";
import { selectIndustries } from "features/classification/classificationSlice";
import MediaIssueSection from "features/media/MediaIssueSection/MediaIssueSection";
import MediaLink from "features/media/MediaLink/MediaLink";
import MediaCountrySubcountrySection from "features/media/MediaTaggingSection/MediaCountrySubcountrySection";
import MediaTaggingSection from "features/media/MediaTaggingSection/MediaTaggingSection";
import ShowFullContent from "features/media/ShowFullContent/ShowFullContent";
import StakeholderTaggingSection from "features/stakeholders/StakeholderTaggingSection/StakeholderTaggingSection";
import { getIdArray } from "utilities/array";
import { checkIsState } from "utilities/country";
import {
  checkGlobalManagementRolePermission,
  NON_BREAKABLE_SPACE,
} from "utilities/data";

const GlobalContentSummary = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const industries = useSelector(selectIndustries);

  const permissions = useSelector(selectPermissions);
  const hasUpdatePermission = checkGlobalManagementRolePermission(
    permissions,
    OBJECT_TYPE.MEDIA,
    OBJECT_NAME.UPDATE
  );

  const { contentId } = useParams();
  const contentByIdArgs = { id: contentId };
  const {
    data: { data: media } = {},
    isFetching,
    error,
  } = useContentByIdQuery(contentByIdArgs, { refetchOnMountOrArgChange: true });
  const [patchContent] = usePatchContentMutation();

  const [updatedContent, setUpdatedContent] = useState({});

  const handleInputValueChange = (propertyName, propertyValue) => {
    dispatch(
      api.util.updateQueryData("contentById", contentByIdArgs, (content) => {
        if (content?.data) {
          content.data[propertyName] = propertyValue;
        }
      })
    );
  };

  const handleInputSubmit = async (value) => {
    let hasAnyChanges = false;
    const content = { id: Number(contentId) };

    forEach(value, (propertyValue, propertyName) => {
      switch (propertyName) {
        case "mediaIssues":
          propertyName = "issueIds";
          propertyValue = map(propertyValue, "issueId");
          break;

        case "stakeholders":
          propertyName = "stakeholderIds";
          propertyValue = getIdArray(propertyValue);
          break;

        default:
          break;
      }

      const currentValue = isArray(updatedContent[propertyName])
        ? uniq(sortBy(updatedContent[propertyName]))
        : updatedContent[propertyName];
      const incomingValue = isArray(propertyValue)
        ? uniq(sortBy(propertyValue))
        : propertyValue;

      const isValueChange = !isEqual(currentValue, incomingValue);

      if (isValueChange) {
        hasAnyChanges = true;
        content[propertyName] = propertyValue;
      }
    });

    if (!hasAnyChanges) {
      return;
    }

    await patchContent({ id: contentId, content }).unwrap();

    setUpdatedContent({ ...updatedContent, ...content });
  };

  useEffect(() => {
    if (!isFetching) {
      setUpdatedContent({
        ...media,
        issueIds: map(media?.mediaIssues, "issueId"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <Page errorCode={error?.status}>
      <Paper>
        <Box m={2}>
          <SkeletonLoader loading={isFetching} width="75%">
            {hasUpdatePermission ? (
              <TextField
                fullWidth
                variant="standard"
                sx={(theme) => ({ input: theme.typography.h6 })}
                value={media?.title ?? ""}
                onChange={(event) =>
                  handleInputValueChange("title", event.target.value)
                }
                onBlur={() => handleInputSubmit({ title: media?.title })}
              />
            ) : (
              <Typography
                variant="h6"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {media?.title ?? NON_BREAKABLE_SPACE}
              </Typography>
            )}
          </SkeletonLoader>
          <Stack
            direction="row"
            gap={1}
            mt={0.5}
            divider={
              <Divider orientation="vertical" flexItem sx={{ my: 0.35 }} />
            }
          >
            <SkeletonLoader loading={isFetching} sx={{ minWidth: 100 }}>
              <Typography variant="body1" color="text.secondary">
                {media?.publishedAt
                  ? dayjs(media.publishedAt).format(DEFAULT_DATE_FORMAT)
                  : NON_BREAKABLE_SPACE}
              </Typography>
            </SkeletonLoader>
            <SkeletonLoader loading={isFetching} sx={{ minWidth: 220 }}>
              <MediaLink url={media?.url} />
            </SkeletonLoader>
          </Stack>
        </Box>

        <Divider />

        <Box
          display="grid"
          gridTemplateAreas={
            isLargeScreen
              ? `"summary tagging" "stakeholders tagging"`
              : `"summary" "tagging" "stakeholders"`
          }
          gridTemplateColumns={isLargeScreen ? "1fr 400px" : "1fr"}
          gridTemplateRows={isLargeScreen ? "auto 1fr" : "auto"}
          flex={1}
        >
          <Box
            gridArea="summary"
            borderBottom={1}
            borderColor="divider"
            minWidth={0}
          >
            <Typography variant="subtitle2" mx={2} my={1.5}>
              Summary
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color="text.primary"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                overflowWrap: "anywhere",
              }}
              mx={2}
              my={1.5}
            >
              {isFetching ? (
                <>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    multiline
                    variant="standard"
                    value={media?.summary ?? ""}
                    sx={(theme) => ({ input: theme.typography.body1 })}
                    InputProps={{ readOnly: !hasUpdatePermission }}
                    onChange={(event) =>
                      handleInputValueChange("summary", event.target.value)
                    }
                    onBlur={() =>
                      handleInputSubmit({ summary: media?.summary })
                    }
                  />
                  <ShowFullContent content={media?.content} />
                </>
              )}
            </Typography>
          </Box>

          <Box gridArea="stakeholders" m={2} minWidth={0}>
            <StakeholderTaggingSection
              stakeholders={media?.stakeholders}
              isFetching={isFetching}
              readOnly={!hasUpdatePermission}
              onChange={(value) => {
                handleInputValueChange("stakeholders", value);
                handleInputSubmit({ stakeholders: value });
              }}
            />
          </Box>

          <Box
            gridArea="tagging"
            borderLeft={isLargeScreen ? 1 : 0}
            borderBottom={isLargeScreen ? 0 : 1}
            borderColor="divider"
            minWidth={0}
          >
            <MediaIssueSection
              mediaIssues={media?.mediaIssues ?? []}
              isFetching={isFetching}
              readOnly={!hasUpdatePermission}
              onChange={(event, value) =>
                handleInputValueChange("mediaIssues", value)
              }
              onBlur={() =>
                handleInputSubmit({ mediaIssues: media?.mediaIssues })
              }
            />
            <Divider />
            <MediaTaggingSection
              label={`Industries (${media?.industryIds?.length ?? 0})`}
              icon={<HomeWorkOutlinedIcon />}
              multiple
              readOnly={!hasUpdatePermission}
              isFetching={isFetching}
              value={media?.industryIds ?? []}
              options={sortBy(
                map(keys(industries), Number),
                (id) => industries[id]?.name
              )}
              getOptionLabel={(option) => industries[option]?.name ?? ""}
              onChange={(event, value) =>
                handleInputValueChange("industryIds", value)
              }
              onBlur={() =>
                handleInputSubmit({ industryIds: media?.industryIds })
              }
            />
            <Divider />
            <MediaCountrySubcountrySection
              values={concat(media?.countryIds, media?.subcountryIds)}
              readOnly={!hasUpdatePermission}
              isFetching={isFetching}
              onChange={(values) => {
                const [subcountryIds, countryIds] = partition(
                  values,
                  checkIsState
                );
                handleInputValueChange("countryIds", countryIds);
                handleInputValueChange("subcountryIds", subcountryIds);
              }}
              onBlur={() =>
                handleInputSubmit({
                  countryIds: media?.countryIds,
                  subcountryIds: media?.subcountryIds,
                })
              }
            />
            <Divider />
            <MediaTaggingSection
              label="Content Type"
              icon={<NewspaperIcon />}
              disableClearable
              readOnly={!hasUpdatePermission}
              isFetching={isFetching}
              value={media?.type ?? null}
              options={keys(MEDIA_TYPE_LABEL)}
              getOptionLabel={(option) => MEDIA_TYPE_LABEL[option] ?? ""}
              onChange={(event, value) => handleInputValueChange("type", value)}
              onBlur={() => handleInputSubmit({ type: media?.type })}
            />
            <Divider />
            <MediaTaggingSection
              label="Content Language"
              icon={<LanguageIcon />}
              disableClearable
              readOnly={!hasUpdatePermission}
              isFetching={isFetching}
              value={media?.language ?? null}
              options={keys(MEDIA_LANGUAGE_LABEL)}
              getOptionLabel={(option) => MEDIA_LANGUAGE_LABEL[option] ?? ""}
              onChange={(event, value) =>
                handleInputValueChange("language", value)
              }
              onBlur={() => handleInputSubmit({ language: media?.language })}
            />
            <Divider />
            <MediaTaggingSection
              label={`Scrapers (${media?.scraperIds?.length ?? 0})`}
              icon={<TravelExploreOutlinedIcon />}
              readOnly
              multiple
              isFetching={isFetching}
              value={media?.scraperIds ?? []}
              options={media?.scraperIds ?? []}
              renderTags={(tags, getTagProps) =>
                tags.map((scraperId, index) => (
                  <Chip
                    size="small"
                    label="atium.tsc.ai"
                    icon={<TravelExploreOutlinedIcon />}
                    component={Link}
                    to={`https://atium.tsc.ai/scrapers/${scraperId}`}
                    target="_blank"
                    sx={{ cursor: "pointer" }}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Box>
        </Box>
      </Paper>
    </Page>
  );
};

export default GlobalContentSummary;
