import React from "react";
import {
  Popover,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { MEDIA_TAGGING_LOGIC } from "enums/mediaTaggingLogic";

const MediaIssuePopover = ({ anchorElement, issue, onClose }) => {
  return (
    <Popover
      disableAutoFocus
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
      }}
      elevation={8}
    >
      <Stack gap={0.5} padding={1} maxWidth={380}>
        <Title issue={issue} />
        <Content issue={issue} />
      </Stack>
    </Popover>
  );
};

const Title = ({ issue }) => {
  if (issue?.taggingLogic !== MEDIA_TAGGING_LOGIC.SLIC) {
    return null;
  }
  return (
    <Typography variant="subtitle2">
      This issue was auto-detected in the following piece of content:
    </Typography>
  );
};

const Content = ({ issue }) => {
  let content = "";

  if (issue?.taggingLogic === MEDIA_TAGGING_LOGIC.MANUAL) {
    content = "This issue was manually tagged.";
  }

  if (issue?.taggingLogic === MEDIA_TAGGING_LOGIC.PBC) {
    content = "This issue was detected by a legacy issue tagging logic.";
  }

  if (issue?.taggingLogic === MEDIA_TAGGING_LOGIC.SLIC) {
    content = issue?.mediaChunk?.content ?? "";
  }

  return <Typography variant="caption">{content}</Typography>;
};

export default MediaIssuePopover;
