import React from "react";
import {
  MaterialSymbol,
  SkeletonLoader,
  TooltipAvatar,
} from "@tsc/component-library/lib/components";
import { getWidthHeight } from "@tsc/component-library/lib/utilities/avatar";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";

import { getStakeholderAttribute } from "utilities/stakeholder";

/**
 * To show stakeholder avatar with default person and organization icon.
 * @param {Object} properties Properties of `StakeholderAvatar`.
 * @param {boolean} properties.loading Show skeleton when loading.
 * @param {object} properties.value Stakeholder object.
 * @param {"small" | "medium" | "large" | "extra-large"} properties.size
 */
const StakeholderAvatar = ({ loading, value, size = "medium" }) => {
  const type = value?.type;
  const photo = getStakeholderAttribute(value, "photo");

  const skeletonVariant =
    type === STAKEHOLDER_TYPE.ORGANIZATION ? "rounded" : "circular";
  const avatarVariant =
    type === STAKEHOLDER_TYPE.ORGANIZATION ? "rounded" : "circular";
  const widthHeight = getWidthHeight(size);
  const iconSize = (widthHeight / 4) * 3;

  return (
    <SkeletonLoader
      loading={loading}
      variant={skeletonVariant}
      sx={{ width: widthHeight, height: widthHeight }}
    >
      <TooltipAvatar
        size={size}
        photo={photo}
        avatarProps={{
          variant: avatarVariant,
          imgProps: {
            sx: {
              objectFit:
                type === STAKEHOLDER_TYPE.ORGANIZATION ? "contain" : null,
            },
          },
        }}
        icon={
          type === STAKEHOLDER_TYPE.ORGANIZATION ? (
            <MaterialSymbol symbol="corporate_fare" size={iconSize} />
          ) : (
            <MaterialSymbol symbol="person" size={iconSize} />
          )
        }
      />
    </SkeletonLoader>
  );
};

export default StakeholderAvatar;
