import React, { useState } from "react";
import { Button, Popover, Stack } from "@tsc/component-library/lib/components";
import { cloneDeep } from "lodash";

import StakeholderDropdown from "features/stakeholders/StakeholderDropdown/StakeholderDropdown";

const StakeholderDropdownPopup = ({
  anchorEl,
  open,
  onClose,
  getOptionDisabled,
}) => {
  const [newStakeholders, setNewStakeholders] = useState([]);

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClose(cloneDeep(newStakeholders));
    setNewStakeholders([]);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      slotProps={{ paper: { square: false, sx: { p: 1 } } }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack spacing={1} direction="row">
        <StakeholderDropdown
          multiple
          autoFocus
          sx={{ width: 400 }}
          value={newStakeholders}
          onChange={setNewStakeholders}
          disableCloseOnSelect
          onEnter={handleClose}
          getOptionDisabled={getOptionDisabled}
        />
        <Button variant="contained" onClick={handleClose}>
          Add
        </Button>
      </Stack>
    </Popover>
  );
};

export default StakeholderDropdownPopup;
