import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Link,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";

import NotFoundIcon from "assets/icons/NotFound";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Stack
      height="calc(100vh - 64px)"
      alignItems="center"
      justifyContent="center"
      p={2}
      gap={1}
    >
      <NotFoundIcon />
      <Typography variant="h5" color="text.primary">
        Oops! Page not found!
      </Typography>
      <Typography variant="body1" color="text.secondary" textAlign="center">
        You don't have permission to access this page, or it doesn't exist.
        <br />
        It could also be us. Contact us at{" "}
        <Link href="mailto:support@tsc.ai">support@tsc.ai</Link> if the problem
        persists.
      </Typography>
      <Button variant="contained" onClick={() => navigate("/")} sx={{ mt: 1 }}>
        Go to Home Page
      </Button>
    </Stack>
  );
};

export default NotFound;
