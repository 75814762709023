import { isEmpty, omit } from "lodash";

import { filter } from "utilities/array";

export const NON_BREAKABLE_SPACE = "\u00A0";

export const hasPermissionToken = (permissions, objectType, name) => {
  if (isEmpty(permissions)) {
    return false;
  }
  return !!permissions.find(
    ({ permission }) => permission === `${objectType}.${name}`
  );
};

export const checkGlobalManagementRolePermission = (
  permissions,
  objectType,
  name
) => {
  if (isEmpty(permissions)) {
    return false;
  }
  return !!permissions.find(
    ({ permission, organizationId }) =>
      !organizationId && permission === `${objectType}.${name}`
  );
};

/**
 * This function flatten user's roles into permission
 * and append permission token to every permission object.
 * @param {*} userRoles
 * @param {*} roles
 * @returns Array of permissions.
 */
export const denormalizePermissions = (userRoles, roles) => {
  if (isEmpty(userRoles) || isEmpty(roles)) {
    return [];
  }
  return userRoles
    .flatMap((userRole) => {
      return roles.find(
        (role) => role.id === (userRole.roleId || userRole.managementRoleId)
      )?.permissions;
    })
    .map((permission = {}) => ({
      ...permission,
      permission: `${permission.objectType}.${permission.name}`,
    }));
};

/**
 * To get the total count of the items after filter.
 * @param {any[]} items Array of items before filter.
 * @param {object} searchParams Object to filter array of items.
 * @returns Total count of filtered items.
 */
export const getTotalCount = (items, searchParams) => {
  const filterObject = omit(searchParams, ["page", "pageSize", "sortBy"]);
  const filteredItem = filter(items, filterObject);
  return filteredItem.length;
};
