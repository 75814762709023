import { countBy, isArray, isEmpty } from "lodash";

import { getLowerCase } from "utilities/string";

/**
 * Simulate server filtering.
 * @template T
 * @param {T[]} array The array to filter.
 * @param {T} filterObject The filter object, like { name: 'abc' }.
 * @returns {T[]} Filtered array.
 */
export function filter(array, filterObject) {
  if (isEmpty(array)) {
    return [];
  }

  if (isEmpty(filterObject)) {
    return array;
  }

  return array.filter((element) => {
    let matched = true;

    for (const [key, value] of Object.entries(filterObject)) {
      const sourceValue = getLowerCase(element[key]);
      const targetValue = getLowerCase(value);

      if (typeof sourceValue === "string") {
        matched = sourceValue.includes(targetValue);
      } else {
        matched = sourceValue === targetValue;
      }

      if (matched === false) {
        return false;
      }
    }

    return matched;
  });
}

/**
 * Simulate server pagination.
 * @template T
 * @param {T[]} array The full array to paginate.
 * @param {number} page Page index starting from 1.
 * @param {number} pageSize The number of items per page.
 * @returns {T[]} The paginated array.
 */
export function paginate(array, page, pageSize) {
  const start = (page - 1) * pageSize;
  const end = page * pageSize;
  const pageData = array.slice(start, end);

  return pageData;
}

/**
 * Return an array of each element's id
 * @param {*} array Array input
 * @returns {Array} array of ids
 */
export function getIdArray(array, idKey = "id") {
  return isArray(array) ? array.map((element) => element[idKey]) : [];
}

/**
 * Calculate the number of elements in the array that satisfy the predicate.
 * @param {Array} array
 * @param {string} predicate
 * @param {*} value
 * @returns Number of elements in the array that satisfy the predicate.
 */
export function count(array, predicate, value) {
  return countBy(array, predicate)[value] ?? 0;
}
