import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Divider,
  Link,
  NewTabLink,
  Skeleton,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import dayjs from "dayjs";
import { MONTH_YEAR } from "enums/date";

import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { getStakeholderAttribute } from "utilities/stakeholder";

const Connection = ({ connection, currentStakeholderId, isFetching }) => {
  if (isFetching) {
    return <ConnectionCardSkeleton />;
  }

  const { title, startNode, endNode, startDate, endDate } = connection;
  const stakeholder =
    startNode.id === currentStakeholderId ? endNode : startNode;
  const name = getStakeholderAttribute(stakeholder, "name");
  const country = getStakeholderAttribute(stakeholder, "country")?.name;
  const sourceUrl = getStakeholderAttribute(stakeholder, "sourceUrl");

  return (
    <Stack direction="row" columnGap={2}>
      <StakeholderAvatar value={stakeholder} size="large" />
      <Stack rowGap={0.5}>
        {name && (
          <Link
            to={`/stakeholders/${stakeholder.id}`}
            component={RouterLink}
            fontWeight={500}
            className="notranslate"
          >
            {name}
          </Link>
        )}
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          flexWrap="wrap"
        >
          {title && (
            <Typography fontSize={12} color="text.secondary">
              {title}
            </Typography>
          )}
          {startDate && (
            <Typography fontSize={12} color="text.secondary">
              {dayjs(startDate).format(MONTH_YEAR)} -
              {endDate ? dayjs(endDate).format(` ${MONTH_YEAR}`) : " Current"}
            </Typography>
          )}
          {country && (
            <Typography fontSize={12} color="text.secondary">
              {country}
            </Typography>
          )}
        </Stack>
        {sourceUrl && (
          <NewTabLink label="View source" url={sourceUrl} fontSize={12} />
        )}
      </Stack>
    </Stack>
  );
};

const ConnectionCardSkeleton = () => {
  return (
    <Stack direction="row" columnGap={2}>
      <Skeleton variant="rounded" height={52} width={52} />
      <Stack rowGap={0.75}>
        <Skeleton variant="rounded" height={16} width={150} />
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <Skeleton variant="rounded" height={12} width={80} />
          <Skeleton variant="rounded" height={12} width={80} />
          <Skeleton variant="rounded" height={12} width={80} />
        </Stack>
        <Skeleton variant="rounded" height={12} width={94} />
      </Stack>
    </Stack>
  );
};

export default Connection;
