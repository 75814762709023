import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Link,
  SkeletonLoader,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { upperFirst } from "lodash";

import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { getStakeholderAttribute } from "utilities/stakeholder";

const Stakeholder = ({ value, isFetching, onDelete }) => {
  const [hovered, setHovered] = useState(false);

  const name = getStakeholderAttribute(value, "name");
  const headline = upperFirst(getStakeholderAttribute(value, "headline"));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <StakeholderAvatar value={value} loading={isFetching} />

        <Stack alignItems="flex-start">
          <SkeletonLoader loading={isFetching} width={80}>
            <Typography
              variant="subtitle1"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              title={name}
              className="notranslate"
            >
              <Link
                component={RouterLink}
                to={`/stakeholders/${value?.id}`}
                color="text.primary"
              >
                {name ?? <>&nbsp;</>}
              </Link>
            </Typography>
          </SkeletonLoader>
          <SkeletonLoader loading={isFetching} width={180}>
            <Typography
              color="text.secondary"
              variant="caption"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
              title={headline}
            >
              {headline}&nbsp;
            </Typography>
          </SkeletonLoader>
        </Stack>
      </Stack>

      {hovered && !isFetching && onDelete && (
        <IconButton onClick={() => onDelete(value)}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default Stakeholder;
