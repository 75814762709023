import api from "./index";

const API_ROUTE = "connections/api/v1";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConnections: build.query({
      query: ({ params }) => ({
        url: `${API_ROUTE}/connections`,
        params: params,
      }),
    }),
  }),
});

export const { useGetConnectionsQuery } = extendedApi;
