import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OBJECT_TYPE } from "enums/objectType";

import { useGetRolesQuery } from "api/roles";
import { useGetUserByEmailQuery } from "api/users";
import {
  selectUser,
  setPermissions,
} from "features/authentication/authenticationSlice";
import { denormalizePermissions } from "utilities/data";

const PermissionService = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);

  const {
    isFetching: isFetchingUser,
    currentData: {
      data: { userRoles = [], userManagementRoles = [] } = {},
    } = {},
  } = useGetUserByEmailQuery(
    {
      objectType: OBJECT_TYPE.MANAGEMENT,
      objectId: "*",
      email: currentUser.email,
    },
    { skip: !currentUser?.email }
  );
  const {
    isFetching: isFetchingRoles,
    currentData: { data: roles = [] } = {},
  } = useGetRolesQuery({}, { skip: !currentUser?.email });

  const isFetching = isFetchingUser || isFetchingRoles;

  useEffect(() => {
    if (!isFetching) {
      const permissions = denormalizePermissions(
        [...userRoles, ...userManagementRoles],
        roles
      );
      dispatch(setPermissions({ permissions }));
    }
  }, [isFetching, roles, userRoles, userManagementRoles, dispatch]);
};

export default PermissionService;
