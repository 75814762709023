import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  EmptyContent,
  Link,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { object, string } from "zod";

import { ReactComponent as EmailIcon } from "assets/vectors/email_success.svg";

import { MESSAGES } from "../../../utilities/validation";
import TSCLogo from "../SignInMethods/TSCLogo";

const COMMON_INPUT_PROPS = {
  fullWidth: true,
  sx: { mb: 1 },
};

const registerSchema = object({
  email: string().nonempty(MESSAGES.required).email(MESSAGES.email),
});

const resetPasswordSettings = {
  url: window.location.origin,
  handleCodeInApp: true,
};

export default function PasswordResetForm({ onBack }) {
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
  });

  const onSubmitHandler = (values) => {
    const { email } = values;
    sendPasswordResetEmail(getAuth(), email, resetPasswordSettings)
      .then(() => {
        setResetPasswordEmailSent(true);
      })
      .catch((error) => {
        // We always show a success message to prevent email phishing
        setResetPasswordEmailSent(true);
      });
  };

  return (
    <>
      {!resetPasswordEmailSent && (
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <TSCLogo />
          <Box mb={2}>
            <Typography variant="h5">Forgot your password?</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Please enter your email address and submit. A reset link will be
              sent to your email.
            </Typography>
          </Box>
          <TextField
            {...COMMON_INPUT_PROPS}
            placeholder="Email"
            error={!!errors["email"]}
            helperText={errors["email"]?.message}
            {...register("email")}
          />
          <Button {...COMMON_INPUT_PROPS} variant="contained" type="submit">
            Continue
          </Button>
        </Box>
      )}
      {resetPasswordEmailSent && (
        <EmptyContent
          iconComponent={<EmailIcon />}
          title="Email Sent"
          message="If your account exists, you will get an email with instructions on
            resetting your password."
        >
          <Typography variant="subtitle1" color="text.secondary">
            No email in your inbox or spam folder?{" "}
            <Link
              href="#"
              onClick={() => {
                onBack();
                setResetPasswordEmailSent(false);
              }}
            >
              Back to sign in.
            </Link>
          </Typography>
        </EmptyContent>
      )}
    </>
  );
}
