import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { components } from "@tsc/component-library/lib/commonTheme";
import {
  Autocomplete,
  Box,
  Button,
  InputSearch,
  Stack,
  TextField,
} from "@tsc/component-library/lib/components";

const DEFAULT_FILTER = {
  keyword: "",
};

const OrganizationFilter = ({ parentFilters, onSearch, disabledSearch }) => {
  const [filters, setFilters] = useState({
    ...DEFAULT_FILTER,
    ...parentFilters,
  });
  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  const handleChange = (value) => {
    setFilters((prevValue) => ({ ...prevValue, ...value }));
  };

  const handleSearch = () => {
    onSearch({ ...filters, page: 1 });
  };

  return (
    <Stack p={2} spacing={2}>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems="end"
        spacing={1}
      >
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={1}>
          <InputSearch
            fullWidth
            value={filters.keyword ?? ""}
            onChange={(e) => handleChange({ keyword: e.target.value })}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                filters.keyword !== parentFilters?.keyword
              ) {
                e.preventDefault();
                handleSearch();
              }
            }}
            size="small"
            placeholder="Search"
          />
        </Box>
        <Box width={{ xs: "100%", lg: "184px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <Autocomplete
            data-testid="SortByDropDown"
            value={"Alphabet"}
            options={["Alphabet"]}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sort by"
                placeholder="All"
                InputLabelProps={{
                  ...params.InputLabelProps,
                  ...components.MuiTextField.defaultProps.InputLabelProps,
                }}
              />
            )}
          />
        </Box>
        <Box width={{ xs: "100%", lg: "104px" }} flexGrow={{ xs: 1, lg: 0 }}>
          <Button
            disabled={disabledSearch}
            variant="contained"
            size="small"
            fullWidth
            onClick={handleSearch}
            sx={{ height: "40px" }}
            endIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

OrganizationFilter.defaultProps = {
  onSearch: () => {},
  disabledSearch: false,
};

export default OrganizationFilter;
