import React from "react";
import TwitterLogo from "@mui/icons-material/Twitter";
import {
  Avatar,
  NewTabLink,
  Paper,
  Stack,
  useTheme,
} from "@tsc/component-library/lib/components";

import { isValidUrl } from "utilities/url";

const MediaLink = ({ url, fontSize = 16 }) => {
  const host = isValidUrl(url) ? new URL(url).host : "";
  const isTwitterUrl = host.toLowerCase() === "twitter.com";

  return (
    <Stack direction="row" alignItems="center">
      {isTwitterUrl && (
        <>
          <TwitterIcon fontSize={fontSize} />
          &nbsp;
        </>
      )}
      <NewTabLink label={host} url={url} fontSize={fontSize} />
    </Stack>
  );
};

const TwitterIcon = ({ fontSize }) => {
  const theme = useTheme();

  return (
    <Avatar
      component={Paper}
      elevation={4}
      sx={{
        height: fontSize,
        width: fontSize,
        bgcolor: theme.palette.background.default,
        color: "#00B5FE",
      }}
    >
      <TwitterLogo
        sx={{ width: (fontSize / 4) * 3, height: (fontSize / 4) * 3 }}
      />
    </Avatar>
  );
};

export default MediaLink;
