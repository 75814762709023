import { omit, pick } from "lodash";

const USER_ATTRIBUTES = [
  "createdAt",
  "displayName",
  "email",
  "id",
  "updatedAt",
  "version",
];

export function prepareUserPayload(user, organizationId, isCustomer) {
  const roleObject = omit(user, [...USER_ATTRIBUTES, "meta"]);
  const roleKey = isCustomer ? "userRoles" : "userManagementRoles";
  const roleArray = [];

  Object.keys(roleObject).forEach((id) => {
    if (roleObject[id]) {
      if (isCustomer) {
        roleArray.push({
          roleId: parseInt(id, 10),
          objectId: organizationId,
        });
      } else {
        roleArray.push({
          managementRoleId: parseInt(id, 10),
          userId: user.id,
        });
      }
    }
  });

  return {
    ...pick(user, USER_ATTRIBUTES),
    [roleKey]: roleArray,
  };
}

export function transformUserRoles(user, isCustomer) {
  const roleKey = isCustomer ? "userRoles" : "userManagementRoles";
  const roleIdKey = isCustomer ? "roleId" : "managementRoleId";
  const roleObject = user[roleKey].reduce((result, element) => {
    const roleId = element[roleIdKey];
    result[roleId] = true;

    return result;
  }, {});

  return {
    ...user,
    ...roleObject,
    meta: `${user.displayName} (${user.email})`,
  };
}
