import { omit } from "lodash";

export const ADMIN_ORGANIZATION_ID = -1;

export function preparePermissionPayload(role) {
  const permissionObject = omit(role, [
    "id",
    "name",
    "organizationId",
    "objectType",
    "version",
    "createdAt",
    "updatedAt",
    "permissions",
    "global",
  ]);

  const permissionArray = [];

  Object.keys(permissionObject).forEach((id) => {
    if (permissionObject[id]) {
      permissionArray.push({
        id: parseInt(id, 10),
      });
    }
  });

  return permissionArray;
}

export function transformRolePermissions(role) {
  const permissionObject = role.permissions.reduce((result, { id }) => {
    result[id] = true;

    return result;
  }, {});

  return {
    ...role,
    ...permissionObject,
  };
}

export function checkIfSuperAdminOrg(organizationId) {
  return organizationId === ADMIN_ORGANIZATION_ID;
}
