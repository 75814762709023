import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "organizations/api/v1/featureFlags";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFeatureFlags: build.query({
      query: () => API_ROUTE,
      providesTags: providesTagsList(TAG_TYPES.FEATURE_FLAG),
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = extendedApi;
