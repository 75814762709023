import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "auth/api/v1/roles";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query({
      query: () => API_ROUTE,
      providesTags: providesTagsList(TAG_TYPES.ROLE),
    }),
    createRole: build.mutation({
      query: ({ role }) => ({
        url: API_ROUTE,
        method: "POST",
        body: role,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ROLE),
    }),
    updateRole: build.mutation({
      query: ({ role, roleId }) => ({
        url: `${API_ROUTE}/${roleId}`,
        method: "PUT",
        body: role,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ROLE),
    }),
    deleteRole: build.mutation({
      query: ({ roleId }) => ({
        url: `${API_ROUTE}/${roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.ROLE),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = extendedApi;
