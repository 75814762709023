import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "auth/api/v1";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: ({ objectType, objectId }) =>
        `${API_ROUTE}/${objectType}/${objectId}/users`,
      providesTags: providesTagsList(TAG_TYPES.USER),
    }),
    getUserByEmail: build.query({
      query: ({ objectType, objectId, email }) =>
        `${API_ROUTE}/${objectType}/${objectId}/users/${email}`,
      providesTags: (result, error, { email }) => [
        { type: TAG_TYPES.USER, id: email },
      ],
    }),
    updateUser: build.mutation({
      query: ({ objectType, objectId, userId, user }) => ({
        url: `${API_ROUTE}/${objectType}/${objectId}/users/${userId}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: providesTagsList(TAG_TYPES.USER),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByEmailQuery,
  useUpdateUserMutation,
} = extendedApi;
