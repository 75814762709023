export const FEATURE_FLAGS = {
  ai_connection: "AI Connection",
  ask_genie_custom_prompt: "Ask Genie Custom Prompt",
  ask_genie_global: "Ask Genie Global",
  ask_genie_no_cache: "Ask Genie No Cache",
  engagement_plan: "Engagement Plans",
  experimental_engagement:
    "ChatGPT Enhancements for Engagements (disable for all clients)",
  global_search: "Global Search",
  internal_stakeholder_source_id: "[Internal] Stakeholder Source ID",
  issue_heatmap: "Issue Heatmap",
  narrative_tracker: "Narrative Tracker",
  networks_export: "Networks Export",
  networks_keystory: "Networks Keystory",
  networks: "Networks",
  radar: "Radar",
  stakeholder_insight: "Stakeholder Insight",
  stakeholder_profile_contact_details: "Stakeholder Profile Contact Details",
  stakeholder_profile_widgets: "Stakeholder Profile Widgets",
  stakeholders_profile_export: "Stakeholders Profile Export",
  stories: "Stories (costs up to $500 / month)",
  targeted_sentiment: "Targeted Sentiment",
  touchpoint: "Touchpoints",
};
