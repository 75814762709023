import React from "react";
import {
  Box,
  EmptyContent,
  Paper,
  Stack,
} from "@tsc/component-library/lib/components";

const SubPage = ({
  emptyChildren,
  children,
  empty,
  emptyTitle,
  emptyMessage,
  sx,
  ...props
}) => {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "background.default", flexGrow: 1, p: 2, ...sx }}
      {...props}
    >
      {empty ? (
        <Stack
          direction="column"
          sx={{
            height: "calc(100vh - 325px)",
          }}
        >
          {emptyChildren}
          <Box display="flex" alignItems="center" justifyContent="center"></Box>
          <EmptyContent title={emptyTitle} message={emptyMessage} />
        </Stack>
      ) : (
        children
      )}
    </Paper>
  );
};

export default SubPage;
