import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  EmptyContent,
  Link,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { object, string } from "zod";

import { ReactComponent as EmailIcon } from "assets/vectors/email_success.svg";

import { MESSAGES } from "../../../utilities/validation";
import TSCLogo from "../SignInMethods/TSCLogo";

const COMMON_INPUT_PROPS = {
  fullWidth: true,
  sx: { mb: 1 },
};

const registerSchema = object({
  email: string().nonempty(MESSAGES.required).email(MESSAGES.email),
});

const magicLinkSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${window.location.origin}/auth-callback`,
  // This must be true.
  handleCodeInApp: true,
};

export default function SignInWithMagicLink({ onBack }) {
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
  });

  const onSubmitHandler = (values) => {
    const { email } = values;
    sendSignInLinkToEmail(getAuth(), email, magicLinkSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so we don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        setMagicLinkSent(true);
      })
      .catch(() => {
        // We always show a success message to prevent email phishing
        setMagicLinkSent(true);
      });
  };

  return (
    <>
      {!magicLinkSent && (
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <TSCLogo />
          <Box mb={2}>
            <Typography variant="h5">Welcome!</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Please input your email then we will send you the link to login to
              your account.
            </Typography>
          </Box>
          <TextField
            {...COMMON_INPUT_PROPS}
            placeholder="Email"
            error={!!errors["email"]}
            helperText={errors["email"]?.message}
            {...register("email")}
          />
          <Button {...COMMON_INPUT_PROPS} variant="contained" type="submit">
            Continue
          </Button>
          <Typography variant="subtitle1" align="center" color="text.secondary">
            Want other methods?{" "}
            <Link href="#" onClick={onBack}>
              Select another method.
            </Link>
          </Typography>
        </Box>
      )}
      {magicLinkSent && (
        <EmptyContent
          iconComponent={<EmailIcon />}
          title="Email Sent"
          message="Link was sent successfully. Please check your email. No email in
              your inbox or spam folder?"
        >
          <Link href="#" onClick={() => setMagicLinkSent(false)}>
            Let's resend email.
          </Link>
        </EmptyContent>
      )}
    </>
  );
}
