export const FIREBASE_AUTH_ERROR = {
  // Error codes for signInWithPopup().
  // Reference: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup.
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL:
    "auth/account-exists-with-different-credential",
  AUTH_DOMAIN_CONFIG_REQUIRED: "auth/auth-domain-config-required",
  CANCELLED_POPUP_REQUEST: "auth/cancelled-popup-request",
  OPERATION_NOT_ALLOWED: "auth/operation-not-allowed",
  OPERATION_NOT_SUPPORTED_IN_THIS_ENVIRONMENT:
    "auth/operation-not-supported-in-this-environment",
  POPUP_BLOCKED: "auth/popup-blocked",
  POPUP_CLOSED_BY_USER: "auth/popup-closed-by-user",
  UNAUTHORIZED_DOMAIN: "auth/unauthorized-domain",
};
