import { components } from "@tsc/component-library/lib/commonTheme";
import { Autocomplete, TextField } from "@tsc/component-library/lib/components";
import { OBJECT_TYPE } from "enums/objectType";
import { MANAGEMENT_TYPES } from "enums/rbacManagementTypes";
import { keyBy } from "lodash";

import { useGetOrganizationRolesQuery } from "api/organizationRoles";
import { useGetRolesQuery } from "api/roles";
import { getIdArray } from "utilities/array";

export default function RoleInput({
  value,
  organizationId,
  objectType = "organization",
  managementType,
  onChange,
  disabled,
}) {
  const { data: { data: adminRoles = [] } = {} } = useGetRolesQuery(
    {},
    { skip: objectType !== OBJECT_TYPE.MANAGEMENT }
  );
  const { data: { data: orgRoles = [] } = {} } = useGetOrganizationRolesQuery(
    { organizationId },
    { skip: objectType === OBJECT_TYPE.MANAGEMENT }
  );
  const roles =
    managementType === MANAGEMENT_TYPES.CUSTOMER
      ? orgRoles
      : managementType === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT
      ? adminRoles.filter((role) => role.global)
      : adminRoles.filter((role) => !role.global);
  const organizationRoles = roles.filter(
    (role) => role.objectType === objectType
  );
  const organizationRolesDictionary = keyBy(organizationRoles, "id");

  return (
    <Autocomplete
      multiple
      size="small"
      value={value}
      options={getIdArray(organizationRoles)}
      getOptionLabel={(option) => organizationRolesDictionary[option]?.name}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          margin="normal"
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
        />
      )}
      disabled={disabled}
    />
  );
}
