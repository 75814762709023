import api, { TAG_TYPES } from "api";
import {
  addNotification,
  serverErrorNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";
import { providesTagsList } from "utilities/rtkToolkit";

const MEDIA_API_ENDPOINT = "media/api/v1";

const mediaApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchPrompts: build.query({
      query: ({ query, data }) => ({
        url: `${MEDIA_API_ENDPOINT}/promptsearch?${query ?? ""}`,
        method: "POST",
        body: data,
      }),
      providesTags: providesTagsList(TAG_TYPES.MEDIA),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(serverErrorNotification(error.data));
        }
      },
    }),
    contentById: build.query({
      query: ({ id }) => `${MEDIA_API_ENDPOINT}/media/${id}`,
      providesTags: providesTagsList(TAG_TYPES.MEDIA),
    }),
    patchContent: build.mutation({
      query: ({ id, content }) => ({
        url: `${MEDIA_API_ENDPOINT}/media/${id}`,
        method: "PATCH",
        body: content,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              message: "Saved!",
              severity: SEVERITY.SUCCESS,
              autoHide: true,
            })
          );
        } catch (error) {
          dispatch(serverErrorNotification(error.data));
        }
      },
    }),
  }),
});

export const {
  useSearchPromptsQuery,
  useLazySearchPromptsQuery,
  useContentByIdQuery,
  usePatchContentMutation,
} = mediaApi;
