import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "media/api/admin/v1/issues";

const issuesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIssues: build.query({
      query: ({ query }) => `${API_ROUTE}?${query}`,
      providesTags: providesTagsList(TAG_TYPES.ISSUES),
    }),
  }),
});

export const { useGetIssuesQuery } = issuesApi;
