import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

import { store } from "configurations/store";

export default function PasswordDialog({ open, onSubmit, onClose }) {
  const [password, setPassword] = useState("");

  useEffect(() => {
    setPassword("");
  }, [open]);

  return (
    <Provider store={store}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          Enter your password
          <IconButton
            size="small"
            sx={{ position: "absolute", right: 16, top: 16 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ width: 600, pb: 3 }}>
          <Typography>
            You have previously logged in with a password, please enter it to
            continue. We will link your existing account to the newly selected
            method.
          </Typography>
          <TextField
            fullWidth
            placeholder="Password"
            type="password"
            sx={{ mt: 2 }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            CANCEL
          </Button>
          <Button variant="contained" onClick={() => onSubmit({ password })}>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
    </Provider>
  );
}
