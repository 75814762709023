import React, { forwardRef } from "react";
import {
  Autocomplete,
  TextField,
  useTheme,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { filter, keyBy, keys } from "lodash";

import { useGetOrganizationsQuery } from "api/organizations";
import { hasPermissionToken } from "utilities/data";

const DropdownOrganization = (
  {
    value,
    onChange,
    disabled,
    disableClearable,
    error,
    helperText,
    isCreating,
    ...otherProps
  },
  ref
) => {
  const theme = useTheme();

  const { data: { data: organizations = [] } = {}, isFetching } =
    useGetOrganizationsQuery({});
  const organizationsWithPermissions = filter(organizations, (organization) =>
    hasPermissionToken(
      organization?.permissions,
      OBJECT_TYPE.ORGANIZATION_ISSUE,
      isCreating ? OBJECT_NAME.CREATE : OBJECT_NAME.UPDATE
    )
  );
  const organizationsDictionary = keyBy(organizationsWithPermissions, "id");

  return (
    <Autocomplete
      {...otherProps}
      ref={ref}
      value={value}
      options={keys(organizationsDictionary)}
      getOptionLabel={(option) => organizationsDictionary[option]?.name}
      onChange={onChange}
      disabled={disabled}
      loading={isFetching}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...theme.components.MuiTextField.defaultProps.InputLabelProps,
          }}
        />
      )}
    />
  );
};

export default forwardRef(DropdownOrganization);
