import axios from "axios";

import { auth } from "./firebase";

axios.interceptors.request.use(async (config) => {
  const idToken = await auth.currentUser.getIdToken();
  config.headers.Authorization = `Bearer ${idToken}`;
  return config;
});

export default axios;
