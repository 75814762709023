import React from "react";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import { Box, Chip } from "@tsc/component-library/lib/components";
import { useGeographiesDropdownUtils } from "@tsc/component-library/lib/hooks";

import MediaTaggingSection from "features/media/MediaTaggingSection/MediaTaggingSection";
import { checkIsState } from "utilities/country";

const MediaCountrySubcountrySection = ({
  values = [],
  isFetching = false,
  readOnly,
  onChange,
  onBlur,
}) => {
  const {
    options,
    optionsMap,
    sortValues,
    filterOptions,
    onAddOption,
    onRemoveOption,
  } = useGeographiesDropdownUtils();

  const handleCountriesSubcountriesChange = (
    event,
    changeValues,
    reason,
    details
  ) => {
    let newValues =
      reason === "selectOption" || reason === "removeOption"
        ? values
        : changeValues;

    if (reason === "selectOption") {
      newValues = onAddOption(newValues, details.option);
    }

    if (reason === "removeOption") {
      newValues = onRemoveOption(newValues, details.option);
    }

    onChange(newValues);
  };

  return (
    <MediaTaggingSection
      label={`Countries & States (${values?.length ?? 0})`}
      multiple
      readOnly={readOnly}
      isFetching={isFetching}
      value={sortValues(values)}
      options={options}
      getOptionLabel={(option) => option.name ?? ""}
      isOptionEqualToValue={(option, value) => option.iso_code === value}
      filterOptions={(options, { inputValue }) =>
        filterOptions(options, inputValue)
      }
      renderOption={(props, option) => (
        <li {...props} key={option.iso_code}>
          <Box marginLeft={option.category === "subcountry" ? 2 : 0}>
            {option.name}
          </Box>
        </li>
      )}
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => {
          let { onDelete, ...tagProps } = getTagProps({ index });

          if (onDelete) {
            onDelete = (event) =>
              handleCountriesSubcountriesChange(event, tags, "removeOption", {
                option: optionsMap[tag],
              });
          }

          return (
            <Chip
              size="small"
              label={optionsMap[tag]?.name ?? ""}
              icon={<PublicOutlinedIcon />}
              variant={checkIsState(tag) ? "outlined" : "filled"}
              onDelete={onDelete}
              {...tagProps}
            />
          );
        })
      }
      onChange={handleCountriesSubcountriesChange}
      onBlur={onBlur}
    />
  );
};

export default MediaCountrySubcountrySection;
