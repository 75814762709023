import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import genFiveApi from "api";
import authenticationReducer from "features/authentication/authenticationSlice";
import classificationReducer from "features/classification/classificationSlice";
import notificationReducer from "features/notifications/notificationSlice";
import { isLocal } from "utilities/env";

const excludedActions = ["genFiveApi", "__rtkq"];

const logger = createLogger({
  collapsed: true,
  predicate: (getState, action) =>
    !excludedActions.find((e) => action.type.startsWith(e)),
  // predicate: (getState, action) => true, // Uncomment to disable logging
  duration: true,
});

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    notifications: notificationReducer,
    classification: classificationReducer,
    [genFiveApi.reducerPath]: genFiveApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    isLocal()
      ? getDefaultMiddleware().concat(logger, genFiveApi.middleware)
      : getDefaultMiddleware().concat(genFiveApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
