import React, { useState } from "react";
import { Button, Typography } from "@tsc/component-library/lib/components";

const ShowFullContent = ({ content }) => {
  const [isExpand, setIsExpand] = useState(false);
  return (
    <>
      {isExpand ? (
        <>
          <Typography variant="subtitle2" mt={3} mb={2}>
            Full Content
          </Typography>
          {content}
        </>
      ) : null}
      <br />
      <Button
        onClick={() => setIsExpand(!isExpand)}
        variant="text"
        size="small"
        hidden={!content}
        sx={{ mt: 3 }}
      >
        {isExpand ? "HIDE FULL CONTENT" : "SHOW FULL CONTENT"}
      </Button>
    </>
  );
};

export default ShowFullContent;
