import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@tsc/component-library/lib/components";

const AddPromptButton = ({ onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      fullWidth
      sx={{
        "&": {
          border: "1px dotted #0000001F",
          borderRadius: "unset",
          textTransform: "none",
          color: "#0000008F",
        },
        "&:hover": {
          border: "1px dotted #0000001F",
        },
      }}
      variant="outlined"
      color="secondary"
      onClick={onClick}
    >
      <Stack alignItems="center" gap={2} mt={2} mb={1}>
        <AddIcon />
        Add Prompt
      </Stack>
    </Button>
  );
};

export default AddPromptButton;
