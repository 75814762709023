import { auth } from "configurations/firebase";
import { backendAddress } from "utilities/env";

/**
 * @typedef {object} MessageContext
 * @property {string} [contextId]
 * @property {object} [stakeholder]
 * @property {object} [issue]
 * @property {object} [media]
 * @property {object} [contentFilters]
 */

/**
 * Ask genie with free text with specific message context.
 * @param {string} message Free text message to AskGenie.
 * @param {MessageContext} context Context of free text message.
 * @param {object} [extras] Extra configurations of the response.
 * @param {'text' | 'json'} [extras.outputType] Expected response format.
 * @param {string} [extras.preferredLanguage] Language of AskGenie response.
 * @param {string} [extras.sessionId] Session id of current conversation thread.
 * @param {AbortSignal} signal
 * @returns Stream of messages.
 */
export const askFreetext = async (message, context, extras, signal) => {
  const url = new URL(`${backendAddress()}/chat/api/v1/freetext`);
  const headers = {
    authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
    "Content-Type": "application/json",
  };

  if (extras?.sessionId) {
    headers["session-id"] = extras.sessionId;
  }

  if (extras?.outputType) {
    url.searchParams.append("output-type", extras.outputType);
  }

  if (extras?.preferredLanguage) {
    url.searchParams.append("preferredLanguage", extras.preferredLanguage);
  }

  const response = await fetch(url.href, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ message: message, context: context }),
    signal: signal,
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  return response;
};

/**
 * Ask genie with predefined questions with specific message context.
 * @param {string | number} superpromptId Superprompt ID.
 * @param {MessageContext} context Context of superprompt message.
 * @param {object} [extras] Optional. Extra configurations of the response.
 * @param {boolean} [extras.noCache] Optional. Disable cached response.
 * @param {'text' | 'json'} [extras.outputType] Optional. Expected response format.
 * @param {string} [extras.preferredLanguage] Language of AskGenie response.
 * @param {string} [extras.sessionId] Session id of current conversation thread.
 * @param {AbortSignal} [signal] Optional. Signal to abort the request.
 * @returns Stream of messages.
 */
export const askSuperprompt = async (
  superpromptId,
  context,
  extras,
  signal
) => {
  const url = new URL(
    `${backendAddress()}/chat/api/v1/superprompts/${superpromptId}`
  );
  const headers = {
    authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
    "Content-Type": "application/json",
  };

  if (extras?.sessionId) {
    headers["session-id"] = extras.sessionId;
  }

  if (extras?.noCache) {
    url.searchParams.append("no-cache", extras.noCache.toString());
  }

  if (extras?.outputType) {
    url.searchParams.append("output-type", extras.outputType);
  }

  if (extras?.preferredLanguage) {
    url.searchParams.append("preferredLanguage", extras.preferredLanguage);
  }

  const response = await fetch(url.href, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(context),
    signal: signal,
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  return response;
};
