import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { sha256 } from "js-sha256";
import { merge, uniqueId } from "lodash";

import { askFreetext, askSuperprompt } from "api/chat";
import { selectUser } from "features/authentication/authenticationSlice";

export const QUESTION_TYPE = {
  FREE_TEXT: "FREE_TEXT",
  SUPERPROMPT: "SUPERPROMPT",
};
export const MESSAGE_ROLE = {
  USER: "USER",
  ASSISTANT: "ASSISTANT",
};

export const useGenie = () => {
  const user = useSelector(selectUser);

  const sessionId = useRef();
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState("");
  const [lastMetadata, setLastMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  /**
   * @type {[AbortController | undefined, React.Dispatch<React.SetStateAction<AbortController | undefined>>]}
   */
  const [abortController, setAbortController] = useState();

  // NOTE: This is an unmount cleanup effect to cancel current request.
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const handleAbort = useCallback(() => {
    setAbortController((oldAbortController) => {
      if (oldAbortController) {
        oldAbortController.abort();
      }

      return undefined;
    });
  }, []);

  const handleChangeAbortController = useCallback(() => {
    const newAbortController = new AbortController();
    setAbortController((oldAbortController) => {
      if (oldAbortController) {
        oldAbortController.abort();
      }

      return newAbortController;
    });

    return newAbortController;
  }, []);

  const streamHandler = useCallback(
    async (
      apiResponse,
      analyticsMetadata,
      additionalMetadata,
      /** @type {AbortSignal} */
      signal
    ) => {
      let responseMessage = "";
      let responseMetadata = null;
      setIsLoading(true);
      setIsFetching(true);
      setLastMetadata(additionalMetadata);

      try {
        const response = await apiResponse;
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        if (response.headers.get("session-id")) {
          sessionId.current = response.headers.get("session-id");
        }

        let buffer = "";
        let isChatEnded = false;

        while (true && !isChatEnded) {
          const { done, value } = await reader.read();
          if (done || signal.aborted) break;

          buffer += decoder.decode(value, { stream: true });
          let rawChunks = buffer.split("\n");
          buffer = rawChunks.pop(); // Remaining partial chunk

          for (const rawChunk of rawChunks) {
            if (rawChunk.includes("data:")) {
              const chunk = rawChunk.replaceAll("data:", "").trim();
              const parsedValue = JSON.parse(chunk);

              switch (parsedValue.message_type) {
                case "chat.message.stream":
                  responseMessage += parsedValue.message;
                  setIsLoading(false);
                  setLastMessage(responseMessage);
                  break;

                case "chat.message.metadata":
                  responseMetadata = merge(
                    responseMetadata,
                    parsedValue?.data?.reply_payload
                  );
                  setIsLoading(false);
                  setLastMetadata({
                    ...responseMetadata,
                    ...additionalMetadata,
                  });
                  break;

                case "chat.end":
                  isChatEnded = true;
                  break;

                case "chat.error":
                  if (responseMessage) {
                    responseMessage += "\n\n";
                  }
                  responseMessage +=
                    "An error occurred while processing the response.";
                  isChatEnded = true;
                  break;

                default:
                  break;
              }
              if (isChatEnded) break;
            }
          }
        }
      } catch (err) {
        if (signal.aborted) {
          return;
        }

        if (responseMessage) {
          responseMessage += "\n\n";
        }

        responseMessage += "An error occurred while processing the response.";
      } finally {
        if (responseMessage || responseMetadata || additionalMetadata) {
          pushMessage({
            role: MESSAGE_ROLE.ASSISTANT,
            message: responseMessage,
            metadata: {
              ...responseMetadata,
              ...additionalMetadata,
            },
            analyticsMetadata: analyticsMetadata,
          });
        }
        setLastMessage("");
        setLastMetadata(null);
        setIsLoading(false);
        setIsFetching(false);
      }
    },
    []
  );

  const askFreetextHandler = useCallback(
    async (message, context, additionalMetadata) => {
      const newAbortController = handleChangeAbortController();
      const abortSignal = newAbortController.signal;
      pushMessage({
        role: MESSAGE_ROLE.USER,
        user: user.email,
        message,
        context,
      });

      const response = askFreetext(
        message,
        context,
        {
          sessionId: sessionId.current,
          outputType: "json",
        },
        abortSignal
      );
      await streamHandler(
        response,
        { chat_type: QUESTION_TYPE.FREE_TEXT },
        additionalMetadata,
        abortSignal
      );
    },
    [streamHandler, user.email, handleChangeAbortController]
  );

  const askSuperpromptHandler = useCallback(
    async (superpromptId, message, context, extras, additionalMetadata) => {
      const newAbortController = handleChangeAbortController();
      const abortSignal = newAbortController.signal;
      if (message) {
        pushMessage({
          role: MESSAGE_ROLE.USER,
          user: user.email,
          message,
          context,
        });
      }

      const response = askSuperprompt(
        superpromptId,
        {
          contextId: sha256(JSON.stringify(context)).substring(0, 8),
          context,
        },
        {
          sessionId: sessionId.current,
          outputType: "json",
          ...extras,
        },
        abortSignal
      );
      await streamHandler(
        response,
        {
          chat_type: QUESTION_TYPE.SUPERPROMPT,
          chat_superprompt_id: superpromptId,
        },
        additionalMetadata,
        abortSignal
      );
    },
    [streamHandler, user.email, handleChangeAbortController]
  );

  const addSuperCommandHandler = useCallback(
    (userMessage, systemMessage, additionalMetadata) => {
      pushMessage({
        role: MESSAGE_ROLE.USER,
        user: user.email,
        message: userMessage,
      });

      pushMessage({
        role: MESSAGE_ROLE.ASSISTANT,
        message: systemMessage,
        metadata: {
          ...additionalMetadata,
        },
      });
    },
    [user.email]
  );

  const pushMessage = (message) => {
    const newMessage = {
      ...message,
      id: uniqueId("message-"),
    };
    setMessages((previousMessages) => [...previousMessages, newMessage]);
    return newMessage;
  };

  const clear = useCallback(() => {
    setMessages([]);
    setLastMessage("");
  }, []);

  return {
    messages: messages,
    lastMessage: lastMessage,
    lastMetadata: lastMetadata,
    isLoading: isLoading,
    isFetching: isFetching,
    askFreetext: askFreetextHandler,
    askSuperprompt: askSuperpromptHandler,
    addSuperCommand: addSuperCommandHandler,
    abort: handleAbort,
    clear,
  };
};
