import React from "react";
import RadarIcon from "@mui/icons-material/Radar";
import { Stack, Tooltip } from "@tsc/component-library/lib/components";
import { DISPLAYED_STAKEHOLDER_SOURCE } from "enums/stakeholderSource";
import { isEmpty } from "lodash";

import StakeholderSourceAvatar, {
  ICON_WIDTH_HEIGHT,
} from "features/stakeholders/StakeholderSourceAvatar/StakeholderSourceAvatar";
import {
  attributeSorter,
  getStakeholderSourceTags,
} from "utilities/stakeholder";

const StakeholderSourceAvatarGroup = ({ stakeholder, loading }) => {
  if (loading) {
    return <StakeholderSourceAvatar loading />;
  }

  const stakeholderAttributes = stakeholder?.stakeholderAttributes ?? [];

  const sourcesToShow = stakeholderAttributes
    .filter((sa) => DISPLAYED_STAKEHOLDER_SOURCE.includes(sa.source))
    .sort(attributeSorter);

  const sourcesToCollapse = stakeholderAttributes
    .filter((sa) => !DISPLAYED_STAKEHOLDER_SOURCE.includes(sa.source))
    .sort(attributeSorter);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {sourcesToShow.map((stakeholderAttribute, index) => (
        <StakeholderSourceAvatar
          key={`[${index}]${stakeholderAttribute?.id}`}
          loading={loading}
          stakeholder={stakeholder}
          stakeholderAttribute={stakeholderAttribute}
        />
      ))}
      {!isEmpty(sourcesToCollapse) && (
        <Tooltip title={getStakeholderSourceTags(sourcesToCollapse).join("\n")}>
          <RadarIcon
            color="primary"
            sx={{
              width: ICON_WIDTH_HEIGHT,
              height: ICON_WIDTH_HEIGHT,
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default StakeholderSourceAvatarGroup;
