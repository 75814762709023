import api from "api";

const API_ROUTE = "auth/api/v1/permissions";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissions: build.query({
      query: () => API_ROUTE,
    }),
  }),
});

export const { useGetPermissionsQuery } = extendedApi;
