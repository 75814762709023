import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Checkbox } from "@tsc/component-library/lib/components";
import { isEmpty } from "lodash";

const IssuePromptActions = ({ onSelectAll, onPreview, disabled }) => {
  const { control } = useFormContext();
  const issuePromps = useWatch({ control, name: "issuePrompts" });

  const isAllSelected = issuePromps.every(({ selected }) => selected);
  const isSomeSelected = issuePromps.some(({ selected }) => selected);
  const isSelectedPromptsValid = issuePromps
    .filter(({ selected }) => selected)
    .every(({ prompt }) => prompt);

  if (isEmpty(issuePromps)) {
    return null;
  }

  return (
    <>
      <Checkbox
        disabled={disabled}
        checked={isAllSelected}
        indeterminate={!isAllSelected && isSomeSelected}
        onChange={(_, value) => onSelectAll(value)}
      />
      <Button
        variant="outlined"
        disabled={!isSomeSelected || !isSelectedPromptsValid || disabled}
        onClick={onPreview}
      >
        PREVIEW
      </Button>
    </>
  );
};

IssuePromptActions.defaultProps = {
  disabled: false,
  onSelectAll: () => {},
  onPreview: () => {},
};

export default IssuePromptActions;
