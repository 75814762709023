import { isArray } from "lodash";

import { TAG_IDS } from "api";

export function providesTagsList(type) {
  return (results, error, args) => {
    let tags = [];

    if (results?.data) {
      if (isArray(results?.data)) {
        tags = results?.data.map(({ id }) => ({ type, id }));
      } else if (results?.data?.id) {
        tags = [{ type, id: results?.data?.id }];
      }
    }

    return [{ type, id: TAG_IDS.LIST }, ...tags];
  };
}
