import React from "react";
import {
  Alert,
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
} from "@tsc/component-library/lib/components";
import {
  PREVIEW_TYPES,
  PREVIEW_TYPES_CONFIG,
} from "enums/issuePromptPreviewType";
import { isEmpty, range } from "lodash";

import ExportPreviewMedia from "features/issues/ExportPreviewMedia/ExportPreviewMedia";
import MediaCard from "features/media/MediaCard/MediaCard";

const IssuePreview = ({
  previewType,
  onPreviewTypeChange,
  isFetching,
  media,
  previewedPrompts,
  showPreviewedPromptWarn,
}) => {
  return (
    <Box position="relative" overflow="auto" px={2} my={2}>
      <Paper elevation={0} sx={{ position: "sticky", top: 0, zIndex: 2 }}>
        <Tabs
          centered
          value={previewType}
          onChange={(_, key) => onPreviewTypeChange(key)}
        >
          {Object.values(PREVIEW_TYPES).map((key) => (
            <Tab
              key={key}
              label={PREVIEW_TYPES_CONFIG[key].label}
              value={key}
            />
          ))}
        </Tabs>
      </Paper>

      {showPreviewedPromptWarn && (
        <Alert
          severity="warning"
          variant="filled"
          sx={{ bgcolor: "warning.light", mt: 2, borderRadius: 1 }}
        >
          Your prompts have changed since the last preview. Click "Preview"
          again to see up-to-date results.
        </Alert>
      )}

      <Stack mt={2} mb={1} gap={2}>
        {!isFetching && !isEmpty(media) && (
          <ExportPreviewMedia
            media={media}
            previewType={previewType}
            previewedPrompts={previewedPrompts}
          />
        )}
        {isFetching
          ? range(10).map((index) => <MediaCard key={index} isFetching />)
          : media.map((mediaItem) => (
              <MediaCard key={mediaItem.id} media={mediaItem} />
            ))}
      </Stack>
    </Box>
  );
};

IssuePreview.defaultProps = {
  previewType: PREVIEW_TYPES.AFTER_BOTTOM,
  onPreviewTypeChange: () => {},
  isFetching: false,
  media: [],
  previewedPrompts: [],
  showPreviewedPromptWarn: false,
};

export default IssuePreview;
