import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@tsc/component-library/lib/components";
import PropTypes from "prop-types";

export const LabeledDropdown = ({
  label,
  data,
  getId,
  getValue,
  getOptionLabel,
  value,
  onChange,
  multiple,
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value ?? ""}
        label={label}
        onChange={onChange}
        multiple={multiple}
      >
        {data.map((element) => (
          <MenuItem key={getId(element)} value={getValue(element)}>
            {getOptionLabel(element)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

LabeledDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
  ]).isRequired,
  getId: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

LabeledDropdown.defaultProps = {
  getId: (element) => element?.id,
  getValue: (element) => element?.id,
  getOptionLabel: (element) => element?.name,
  onChange: () => {},
};
