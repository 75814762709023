import api, { TAG_TYPES } from "api";
import { providesTagsList } from "utilities/rtkToolkit";

const API_ROUTE = "organizations/api/admin/v1/organizations";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllOrganizations: build.query({
      query: ({ query }) => `${API_ROUTE}${query ? `?${query}` : ""}`,
      providesTags: providesTagsList(TAG_TYPES.ORGANIZATION),
    }),
  }),
});

export const { useGetAllOrganizationsQuery } = extendedApi;
