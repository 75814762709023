import api, { TAG_IDS, TAG_TYPES } from "api";
import {
  addNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";

const API_ROUTE = "media/api/v1/organizations";

const handleQueryStarted = async (result, { dispatch, queryFulfilled }) => {
  await queryFulfilled;
  dispatch(
    addNotification({
      message: "Issue deleted",
      severity: SEVERITY.SUCCESS,
      autoHide: true,
    })
  );
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationIssueById: build.query({
      query: ({ organizationId, issueId }) =>
        `${API_ROUTE}/${organizationId}/issues/${issueId}`,
      providesTags: (result, error, { issueId }) => [
        { type: TAG_TYPES.ISSUES, id: issueId },
      ],
    }),
    createOrganizationIssue: build.mutation({
      query: (issue) => ({
        url: `${API_ROUTE}/${issue.organizationId}/issues`,
        method: "POST",
        body: issue,
      }),
      invalidatesTags: (_, error) => {
        if (error) return [];
        return [{ type: TAG_TYPES.ISSUES, id: TAG_IDS.LIST }];
      },
    }),
    updateOrganizationIssue: build.mutation({
      query: (issue) => ({
        url: `${API_ROUTE}/${issue.organizationId}/issues/${issue.id}`,
        method: "PUT",
        body: issue,
      }),
      invalidatesTags: (_, error, { issueId }) => {
        if (error) return [];
        return [{ type: TAG_TYPES.ISSUES, id: issueId }];
      },
    }),
    deleteOrganizationIssue: build.mutation({
      query: ({ organizationId, issueId }) => ({
        url: `${API_ROUTE}/${organizationId}/issues/${issueId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, { issueId }) => {
        if (error) return [];
        return [{ type: TAG_TYPES.ISSUES, id: issueId }];
      },
      onQueryStarted: handleQueryStarted,
    }),
  }),
});

export const {
  useGetOrganizationIssueByIdQuery,
  useCreateOrganizationIssueMutation,
  useUpdateOrganizationIssueMutation,
  useDeleteOrganizationIssueMutation,
} = extendedApi;
