import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

import { isDev, isLocal, isProd, isUat } from "../utilities/env";

// BEGIN-NOSCAN
const devFirebaseConfig = {
  apiKey: "AIzaSyDicywv7rZCMsDLowr7tIa0GVcHsCkezs0",
  authDomain: "genie.dev.tsc.ai",
  projectId: "genfive-dev",
  storageBucket: "genfive-dev.appspot.com",
  messagingSenderId: "392418037072",
  appId: "1:392418037072:web:d1a931364dc62be9e3f055",
  measurementId: "G-VGY34Y8J8V",
};

const uatFirebaseConfig = {
  apiKey: "AIzaSyBSW-gkurdcL6wRrd34ho7y8WCgLl7en-U",
  authDomain: "genie.uat.tsc.ai",
  projectId: "genfive-uat",
  storageBucket: "genfive-uat.appspot.com",
  messagingSenderId: "371778226101",
  appId: "1:371778226101:web:59e08ba4579e0a32dbbbfc",
  measurementId: "G-Y6D915423C",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyDE4ixwMvKCHZkiakfhBcaD12A_FU_iJAU",
  authDomain: "genie.tsc.ai",
  projectId: "genfive-prod",
  storageBucket: "genfive-prod.appspot.com",
  messagingSenderId: "421800737625",
  appId: "1:421800737625:web:5b121fccf627a590bb6a48",
  measurementId: "G-WJ0QG0N5XM",
};
// END-NOSCAN

let config;

if (isLocal() || isDev()) config = devFirebaseConfig;
if (isUat()) config = uatFirebaseConfig;
if (isProd()) config = prodFirebaseConfig;

export const app = initializeApp(config);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  semanticSearch: false,
};
