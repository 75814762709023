import React from "react";
import {
  Box,
  SkeletonLoader,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";

import Autocomplete from "components/EnhancedAutocomplete/EnhanceAutocomplete";

const MediaTaggingSection = ({
  label = "",
  icon,
  readOnly,
  loading,
  isFetching,
  disableClearable,
  multiple,
  inputValue,
  value,
  options,
  getOptionKey,
  getOptionLabel,
  isOptionEqualToValue,
  noOptionsText,
  renderOption,
  filterOptions,
  renderTags,
  onChange,
  onBlur,
  onInputChange,
}) => {
  return (
    <Box m={2}>
      <Typography variant="subtitle2">{label}</Typography>
      <Stack direction="row" flexWrap="wrap" mx={-0.5} my={1}>
        <SkeletonLoader
          loading={isFetching}
          variant="rounded"
          height={24}
          sx={{ borderRadius: 12, minWidth: 100, width: 100 }}
        >
          <Autocomplete
            fullWidth
            multiple={multiple}
            size="small"
            ChipProps={{ icon }}
            readOnly={readOnly}
            loading={loading}
            disableClearable={disableClearable}
            inputValue={inputValue}
            value={value}
            options={options}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            noOptionsText={noOptionsText}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            renderOption={renderOption}
            filterOptions={filterOptions}
            renderTags={renderTags}
            onChange={onChange}
            onBlur={onBlur}
            onInputChange={onInputChange}
          />
        </SkeletonLoader>
      </Stack>
    </Box>
  );
};

export default MediaTaggingSection;
