import React, { useState } from "react";
import { Autocomplete, Chip } from "@tsc/component-library/lib/components";

export default function EnhancedAutocomplete({
  readOnly,
  onBlur,
  onFocus,
  onChange,
  value,
  ...autocompleteProps
}) {
  const [focused, setFocused] = useState(false);

  // Override readonOnly behavior.
  if (readOnly) {
    autocompleteProps.forcePopupIcon = false;

    const renderInput = autocompleteProps.renderInput;
    autocompleteProps.renderInput = (params) => {
      params.InputProps.disableUnderline = true;
      params.InputProps.disabled = true;
      params.inputProps.disabled = true;
      return renderInput(params);
    };
  }

  // Override single value to display as Chip.
  if (!autocompleteProps.multiple) {
    const renderInput = autocompleteProps.renderInput;
    autocompleteProps.renderInput = (params) => {
      const value = params.inputProps.value;
      if (value) {
        params.InputProps.startAdornment = (
          <Chip
            size={autocompleteProps.size}
            label={value}
            {...(autocompleteProps.ChipProps ?? {})}
          />
        );
        params.inputProps.value = "";
      }
      return renderInput(params);
    };
  }

  return (
    <Autocomplete
      onFocus={() => {
        setFocused(true);
        if (typeof onFocus === "function") {
          onFocus();
        }
      }}
      onBlur={(event) => {
        if (typeof onBlur === "function") {
          onBlur(event);
        }
        setFocused(false);
      }}
      onChange={onChange}
      value={value}
      open={focused}
      readOnly={readOnly || !focused}
      {...autocompleteProps}
    />
  );
}
