import React from "react";
import {
  Autocomplete,
  TextField,
  useTheme,
} from "@tsc/component-library/lib/components";
import { keyBy, keys } from "lodash";

import { useGetOrganizationsQuery } from "api/organizations";

const DropdownOrganization = ({ value, onChange, disabled }) => {
  const theme = useTheme();

  const { data: { data: organizations = [] } = {}, isFetching } =
    useGetOrganizationsQuery({});
  const organizationsDictionary = keyBy(organizations, "id");

  return (
    <Autocomplete
      value={value}
      options={keys(organizationsDictionary)}
      getOptionLabel={(option) => organizationsDictionary[option]?.name}
      onChange={onChange}
      disabled={disabled}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Organization"
          InputLabelProps={{
            ...params.InputLabelProps,
            ...theme.components.MuiTextField.defaultProps.InputLabelProps,
          }}
        />
      )}
    />
  );
};

export default DropdownOrganization;
