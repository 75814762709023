import React, { useEffect, useState } from "react";
import {
  FilterAutocomplete,
  StandardFilters,
} from "@tsc/component-library/lib/components";
import { STAKEHOLDER_CLASS_LABEL } from "enums/stakeholderClass";
import { STAKEHOLDER_SOURCE_LABEL } from "enums/stakeholderSource";
import { STAKEHOLDER_TYPE_LABEL } from "enums/stakeholderType";

const DEFAULT_FILTER = {
  name: "",
  type: null,
  class: null,
  source: null,
};

export const STAKEHOLDER_VISIBILITY = {
  VISIBLE: true,
  HIDDEN: false,
};

export const STAKEHOLDER_VISIBILITY_LABEL = {
  [STAKEHOLDER_VISIBILITY.VISIBLE]: "Visible",
  [STAKEHOLDER_VISIBILITY.HIDDEN]: "Hidden",
};

const StakeholderFilter = ({ parentFilters, onSearch, disabledSearch }) => {
  const [filters, setFilters] = useState({
    ...DEFAULT_FILTER,
    ...parentFilters,
  });

  const handleChange = (value) => {
    setFilters((prevValue) => ({ ...prevValue, ...value }));
  };

  const handleSearch = () => {
    onSearch({ ...filters, page: 1 });
  };

  useEffect(() => {
    setFilters(parentFilters);
  }, [parentFilters]);

  return (
    <StandardFilters
      filters={filters}
      parentFilters={parentFilters}
      onChange={handleChange}
      onSearch={handleSearch}
      disabledSearch={disabledSearch}
      searchFilterName={"name"}
      placeholder={"Search Stakeholders"}
    >
      <FilterAutocomplete
        data-testid="stakeholderTypeDropDown"
        key="stakeholderTypeDropDown"
        label={"Stakeholder Types"}
        options={Object.keys(STAKEHOLDER_TYPE_LABEL)}
        getOptionLabel={(key) => STAKEHOLDER_TYPE_LABEL[key] || ""}
        value={filters?.type ?? null}
        onChange={(_, type) => handleChange({ type })}
      />
      <FilterAutocomplete
        data-testid="profileTypeDropDown"
        key="profileTypeDropDown"
        label={"Profile Types"}
        options={Object.keys(STAKEHOLDER_CLASS_LABEL)}
        getOptionLabel={(key) => STAKEHOLDER_CLASS_LABEL[key] || ""}
        value={filters?.classes ?? null}
        onChange={(_, classes) => handleChange({ classes })}
      />
      <FilterAutocomplete
        data-testid="sourceTypeDropDown"
        key="sourceTypeDropDown"
        label={"Sources"}
        options={Object.keys(STAKEHOLDER_SOURCE_LABEL)}
        getOptionLabel={(key) => STAKEHOLDER_SOURCE_LABEL[key] || ""}
        value={filters?.source ?? null}
        onChange={(_, source) => handleChange({ source })}
      />
      <FilterAutocomplete
        data-testid="visibilityDropDown"
        key="visibilityDropDown"
        label="Visibility"
        options={Object.keys(STAKEHOLDER_VISIBILITY_LABEL)}
        getOptionLabel={(key) => STAKEHOLDER_VISIBILITY_LABEL[key] || ""}
        value={filters?.isVisible ?? null}
        onChange={(_, isVisible) => handleChange({ isVisible })}
      />
    </StandardFilters>
  );
};

StakeholderFilter.defaultProps = {
  onSearch: () => {},
  disabledSearch: false,
};

export default StakeholderFilter;
