import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Divider,
  EmptyContent,
  Stack,
  Tooltip,
} from "@tsc/component-library/lib/components";
import {
  PREVIEW_TYPES,
  PREVIEW_TYPES_CONFIG,
} from "enums/issuePromptPreviewType";
import { TAGGING_LOGIC } from "enums/issueTaggingLogic";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { cloneDeep, isEmpty, pick } from "lodash";

import api from "api";
import {
  useCreateLibraryIssueMutation,
  useGetLibraryIssueByIdQuery,
  useUpdateLibraryIssueMutation,
} from "api/libraryIssue";
import { useLazySearchPromptsQuery } from "api/media";
import {
  useCreateOrganizationIssueMutation,
  useGetOrganizationIssueByIdQuery,
  useUpdateOrganizationIssueMutation,
} from "api/organizationIssue";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import SplitButton from "components/SplitButton/SplitButton";
import { selectPermissions } from "features/authentication/authenticationSlice";
import IssueForm from "features/issues/IssueForm/IssueForm";
import IssuePreview from "features/issues/IssuePreview/IssuePreview";
import {
  addNotification,
  serverErrorNotification,
  SEVERITY,
} from "features/notifications/notificationSlice";
import { checkGlobalManagementRolePermission } from "utilities/data";

const DEFAULT_ISSUE = {
  taggingLogic: TAGGING_LOGIC.PBC,
  name: "",
  definition: "",
  organizationId: null,
  issuePrompts: [],
};

const IssueEditor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { issueId, organizationId } = useParams();
  const [previewType, setPreviewType] = useState(PREVIEW_TYPES.TOP_10);
  const [previewedPrompts, setPreviewedPrompts] = useState([]);
  const [isPreviewedPromptsChanged, setIsPreviewedPromptsChanged] =
    useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [shouldCloseAfterSave, setShouldCloseAfterSave] = useState(null);

  const form = useForm({ defaultValues: DEFAULT_ISSUE, mode: "onChange" });

  const { data: libraryIssue, isFetching: isFetchingLibraryIssue } =
    useGetLibraryIssueByIdQuery(
      { issueId },
      { skip: isEmpty(issueId) || !isEmpty(organizationId) }
    );
  const { data: organizationIssue, isFetching: isFetchingOrganizationIssue } =
    useGetOrganizationIssueByIdQuery(
      { issueId, organizationId },
      { skip: isEmpty(issueId) || isEmpty(organizationId) }
    );
  const [
    trigger,
    { data: { data: media = [] } = {}, isFetching: isFetchingMedia },
  ] = useLazySearchPromptsQuery();
  const [createLibraryIssue, { isLoading: isCreatingLibraryIssue }] =
    useCreateLibraryIssueMutation();
  const [createOrganizationIssue, { isLoading: isCreatingOrganizationIssue }] =
    useCreateOrganizationIssueMutation();
  const [updateLibraryIssue, { isLoading: isUpdatingLibraryIssue }] =
    useUpdateLibraryIssueMutation();
  const [updateOrganizationIssue, { isLoading: isUpdatingOrganizationIssue }] =
    useUpdateOrganizationIssueMutation();

  const issue = organizationId ? organizationIssue : libraryIssue;

  const isAnyLoading =
    isFetchingLibraryIssue ||
    isFetchingOrganizationIssue ||
    isFetchingMedia ||
    isCreatingLibraryIssue ||
    isCreatingOrganizationIssue ||
    isUpdatingLibraryIssue ||
    isUpdatingOrganizationIssue;

  const permissions = useSelector(selectPermissions);
  const hasCreateLibraryIssuePermission = checkGlobalManagementRolePermission(
    permissions,
    OBJECT_TYPE.LIBRARY_ISSUE,
    OBJECT_NAME.CREATE
  );
  const hasUpdateLibraryIssuePermission = checkGlobalManagementRolePermission(
    permissions,
    OBJECT_TYPE.LIBRARY_ISSUE,
    OBJECT_NAME.UPDATE
  );
  const hasSaveLibraryIssuePermission = issue?.id
    ? hasUpdateLibraryIssuePermission
    : hasCreateLibraryIssuePermission;

  const createIssue = (issue) => {
    if (issue.organizationId) {
      return createOrganizationIssue(issue);
    }
    return createLibraryIssue(issue);
  };

  const updateIssue = (issue) => {
    if (issue.organizationId) {
      return updateOrganizationIssue(issue);
    }
    return updateLibraryIssue(issue);
  };

  const getSelectedPrompts = () => {
    const prompts = form.getValues().issuePrompts ?? [];
    const selectedPrompts = prompts
      .filter(({ selected }) => selected)
      .map(({ prompt, sensitivity }) => ({ prompt, sensitivity }));
    return selectedPrompts;
  };

  const resetMedia = () => {
    const { query } = PREVIEW_TYPES_CONFIG[previewType];
    dispatch(
      api.util.updateQueryData(
        "searchPrompts",
        { query, data: previewedPrompts },
        () => ({})
      )
    );
  };

  const handlePreview = async () => {
    const { query } = PREVIEW_TYPES_CONFIG[previewType];
    const selectedPrompts = getSelectedPrompts();

    setIsPreviewedPromptsChanged(false);
    setPreviewedPrompts(selectedPrompts);
    trigger({ query, data: selectedPrompts });
  };

  const handlePreviewTypeChange = async (key) => {
    setPreviewType(key);
    const { query } = PREVIEW_TYPES_CONFIG[key];
    trigger({ query, data: previewedPrompts });
  };

  const handleReset = () => {
    const defaultValues = form.formState.defaultValues;
    form.reset(defaultValues);

    setPreviewedPrompts([]);
    setIsPreviewedPromptsChanged(false);

    resetMedia();
  };

  const handleSaveButtonClick = async (closeAfterSave) => {
    const formValue = form.getValues();
    const isOrgChanged =
      issue.data.id && issue.data.organizationId !== formValue.organizationId;

    if (isOrgChanged) {
      setIsConfirmDialogOpen(true);
      setShouldCloseAfterSave(closeAfterSave);
      return;
    }

    handleSave(closeAfterSave);
  };

  const handleSave = async (closeAfterSave) => {
    const formValue = cloneDeep(form.getValues());
    const save = formValue.id ? updateIssue : createIssue;

    // Set issuePrompt or definition to empty to prevent validation failed on BE.
    if (formValue.taggingLogic === TAGGING_LOGIC.SLIC) {
      formValue.issuePrompts = [];
    }

    if (formValue.taggingLogic === TAGGING_LOGIC.PBC) {
      formValue.definition = "";
    }

    // Remove `selected` from issue prompts.
    for (const issuePrompt of formValue.issuePrompts) {
      delete issuePrompt.selected;
    }

    try {
      await save(formValue).unwrap();
      dispatch(
        addNotification({
          message: formValue.id ? "Saved successfully" : "Issue created",
          severity: SEVERITY.SUCCESS,
          autoHide: true,
        })
      );

      if (closeAfterSave) {
        navigateToIssueIndexPage();
        return;
      }

      if (isEmpty(formValue.organizationId)) {
        navigate(`/issues/${formValue.id}`);
        return;
      }

      if (!isEmpty(formValue.organizationId)) {
        navigate(
          `/organizations/${formValue.organizationId}/issues/${formValue.id}`
        );
      }
    } catch (error) {
      dispatch(serverErrorNotification(error?.data));
    }
  };

  const handleClose = () => {
    navigateToIssueIndexPage();
  };

  const navigateToIssueIndexPage = () => {
    navigate("/issues");
  };

  useEffect(() => {
    if (issue?.data) {
      const formData = pick(issue.data, [
        "definition",
        "id",
        "issuePrompts",
        "name",
        "category",
        "organizationId",
        "version",
      ]);
      formData.taggingLogic = issue.data.taggingLogic ?? TAGGING_LOGIC.PBC;
      form.reset(formData);
    }
  }, [issue, form]);

  useEffect(() => {
    const subscription = form.watch((value, changedInfo) => {
      if (
        form.getFieldState("issuePrompts").isDirty &&
        changedInfo.name &&
        changedInfo.name.startsWith("issuePrompts") &&
        changedInfo.name.endsWith("active") === false &&
        changedInfo.name.endsWith("selected") === false &&
        isPreviewedPromptsChanged === false
      ) {
        setIsPreviewedPromptsChanged(true);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <Page>
      <SubPage sx={{ height: "calc(100vh - 265px)", overflow: "hidden" }}>
        <Stack
          direction="row"
          sx={{ height: "100%" }}
          divider={<Divider orientation="vertical" flexItem sx={{ my: 2 }} />}
        >
          <Box width={{ md: 400 }}>
            <FormProvider {...form}>
              <IssueForm
                issue={issue?.data}
                hasSaveLibraryIssuePermission={hasSaveLibraryIssuePermission}
                onPreview={handlePreview}
                disabled={isAnyLoading}
              />
            </FormProvider>
          </Box>

          <Stack flex={1} minWidth={0}>
            {!isEmpty(previewedPrompts) && (
              <IssuePreview
                previewType={previewType}
                onPreviewTypeChange={handlePreviewTypeChange}
                media={media}
                previewedPrompts={previewedPrompts}
                isFetching={isFetchingMedia}
                showPreviewedPromptWarn={isPreviewedPromptsChanged}
              />
            )}
            {!isFetchingMedia && isEmpty(media) && (
              <Stack flex={1} alignSelf="center" justifyContent="center">
                <EmptyContent
                  message={
                    isEmpty(previewedPrompts)
                      ? "We couldn't find any media matching. Please select at least 1 prompt."
                      : "We couldn't find any media matching."
                  }
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        {!isFetchingLibraryIssue && !isFetchingOrganizationIssue && (
          <AppBar
            position="fixed"
            color="inherit"
            sx={{ top: "auto", bottom: 0 }}
          >
            <Stack direction="row" p={1} gap={1} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                disabled={isAnyLoading}
              >
                CLOSE
              </Button>
              {issue?.data.id && (
                <Tooltip title="Undo changes and restore previous">
                  <Button
                    variant="outlined"
                    onClick={handleReset}
                    disabled={isAnyLoading}
                  >
                    RESTORE
                  </Button>
                </Tooltip>
              )}
              {issue?.data.id ? (
                <SplitButton
                  disabled={isAnyLoading || !form.formState.isValid}
                  options={[
                    {
                      label: "Save",
                      onClick: () => handleSaveButtonClick(false),
                    },
                    {
                      label: "Save & close",
                      onClick: () => handleSaveButtonClick(true),
                    },
                  ]}
                />
              ) : (
                <Button
                  disabled={isAnyLoading || !form.formState.isValid}
                  variant="contained"
                  onClick={handleSave}
                >
                  CREATE
                </Button>
              )}
            </Stack>
          </AppBar>
        )}
      </SubPage>

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        confirmationMessage={form.getValues().name}
        title="Reassignment Issue"
        message="This action will remove the issue from its original organization. Are you sure you want to proceed?"
        onConfirm={() => {
          setIsConfirmDialogOpen(false);
          handleSave(shouldCloseAfterSave);
        }}
        onClose={() => setIsConfirmDialogOpen(false)}
      />
    </Page>
  );
};

export default IssueEditor;
