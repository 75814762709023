import React, { useMemo, useRef, useState } from "react";
import { Chip, Typography } from "@tsc/component-library/lib/components";
import { useDebounce } from "@uidotdev/usehooks";
import { ISSUE_TYPE } from "enums/issueType";
import { MEDIA_TAGGING_LOGIC } from "enums/mediaTaggingLogic";
import { keyBy } from "lodash";
import queryString from "query-string";

import { useGetAllOrganizationsQuery } from "api/adminOrganizations";
import { useGetIssuesQuery } from "api/issues";
import AIIcon from "assets/icons/AIIcon";
import MediaIssuePopover from "features/media/MediaIssuePopover/MediaIssuePopover";
import MediaTaggingSection from "features/media/MediaTaggingSection/MediaTaggingSection";
import { getUniqMediaIssues, sortMediaIssues } from "utilities/media";

const HOVER_DELAY = 750;

const MediaIssueSection = ({
  mediaIssues = [],
  isFetching: isFetchingContent = false,
  readOnly,
  onChange,
  onBlur,
}) => {
  const [hoveredIssue, setHoveredIssue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const debounceSearchTerm = useDebounce(inputValue, 300);
  const timeoutRef = useRef(null);

  const {
    data: { data: organizations = [] } = {},
    isFetching: isFetchingOrganizations,
  } = useGetAllOrganizationsQuery({});
  const organizationsDictionary = keyBy(organizations, "id");
  const query = queryString.stringify({
    page: 1,
    pageSize: 10,
    sortBy: "issues.name",
    type: ISSUE_TYPE.ORGANIZATION_ISSUE,
    name: debounceSearchTerm,
  });
  const { data: { data: issues = [] } = {}, isFetching: isFetchingIssues } =
    useGetIssuesQuery({ query }, { skip: !debounceSearchTerm });

  const handleMediaIssueHover = (event, issue) => {
    event.stopPropagation();
    const anchorElement = event?.currentTarget;
    timeoutRef.current = setTimeout(
      () => {
        setHoveredIssue({ anchorElement: anchorElement, issue: issue });
      },
      readOnly ? 0 : HOVER_DELAY
    );
  };

  const handleMediaIssuePopoverClose = () => {
    clearTimeout(timeoutRef.current);
    setHoveredIssue(null);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const mapIssuesToMediaIssues = (issues) => {
    return issues.map((issue) => ({
      issue: issue,
      issueId: issue.id,
      taggingLogic: MEDIA_TAGGING_LOGIC.MANUAL,
      score: 0,
    }));
  };

  const value = useMemo(
    () => getUniqMediaIssues(sortMediaIssues(mediaIssues)),
    [mediaIssues] // To prevent reference change when update the value of inputValue cause inputValue reset to empty.
  );
  const options = sortMediaIssues(mapIssuesToMediaIssues(issues));

  return (
    <>
      <MediaTaggingSection
        label={`Issues (${value.length})`}
        multiple
        readOnly={readOnly}
        loading={isFetchingIssues}
        isFetching={isFetchingContent || isFetchingOrganizations}
        inputValue={inputValue}
        value={value}
        options={options}
        getOptionKey={(option) => option.issueId}
        getOptionLabel={(option) => option.issue.name}
        isOptionEqualToValue={(option, value) =>
          option?.issueId === value.issueId
        }
        noOptionsText={inputValue ? "No matching issues" : "Type and select"}
        renderOption={(props, option) => (
          <li key={option.issueId} {...props}>
            <IssueLabel
              mediaIssue={option}
              organizationsDictionary={organizationsDictionary}
            />
          </li>
        )}
        renderTags={(tags, getTagProps) =>
          tags.map((tag, index) => (
            <Chip
              {...getTagProps({ index })}
              key={tag.issueId}
              size="small"
              icon={<AIIcon />}
              label={
                <IssueLabel
                  mediaIssue={tag}
                  organizationsDictionary={organizationsDictionary}
                />
              }
              onMouseEnter={(event) => handleMediaIssueHover(event, tag)}
              onMouseLeave={handleMediaIssuePopoverClose}
              onDelete={
                getTagProps({ index })?.onDelete
                  ? (event) => {
                      handleMediaIssuePopoverClose();
                      getTagProps({ index }).onDelete(event);
                    }
                  : undefined
              }
            />
          ))
        }
        onChange={onChange}
        onBlur={onBlur}
        onInputChange={handleInputChange}
      />

      <MediaIssuePopover
        anchorElement={hoveredIssue?.anchorElement}
        issue={hoveredIssue?.issue}
        onClose={handleMediaIssuePopoverClose}
      />
    </>
  );
};

const IssueLabel = ({ mediaIssue, organizationsDictionary }) => {
  const { organizationId, name: issueName } = mediaIssue.issue;
  return (
    <Typography variant="span">
      {organizationId && (
        <Typography variant="span" color="text.secondary">
          [{organizationsDictionary[organizationId]?.name}]{" "}
        </Typography>
      )}
      {issueName}
    </Typography>
  );
};

export default MediaIssueSection;
