export const MANAGEMENT_TYPES = {
  GLOBAL_MANAGEMENT: "global_management",
  ORGANIZATION_MANAGEMENT: "organization_management",
  CUSTOMER: "customer",
};

export const MANAGEMENT_TYPES_CONFIG = {
  [MANAGEMENT_TYPES.GLOBAL_MANAGEMENT]: {
    label: "Global Management Roles",
    value: MANAGEMENT_TYPES.GLOBAL_MANAGEMENT,
  },
  [MANAGEMENT_TYPES.ORGANIZATION_MANAGEMENT]: {
    label: "Organization Management Roles",
    value: MANAGEMENT_TYPES.ORGANIZATION_MANAGEMENT,
  },
  [MANAGEMENT_TYPES.CUSTOMER]: {
    label: "Customer Roles",
    value: MANAGEMENT_TYPES.CUSTOMER,
  },
};
