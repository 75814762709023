import React from "react";
import { Tooltip } from "@tsc/component-library/lib/components";

const RestrictedAccessTooltip = ({
  hasPermission,
  placement,
  title = "Access to this feature is restricted. Please contact your administrator for permission.",
  children,
}) => {
  if (hasPermission) {
    return children;
  }
  return (
    <Tooltip title={title} placement={placement}>
      {/** Wrap in <div></div> to prevent tooltip not showing when children is disabled. */}
      <div>{children}</div>
    </Tooltip>
  );
};

export default RestrictedAccessTooltip;
