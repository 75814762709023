import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { object, string } from "zod";

import { serverErrorNotification } from "features/notifications/notificationSlice";
import { MESSAGES } from "utilities/validation";

const COMMON_INPUT_PROPS = {
  fullWidth: true,
  sx: { mb: 1 },
};

const signUpSettings = {
  url: window.location.origin,
  handleCodeInApp: true,
};

const registerSchema = object({
  firstName: string().nonempty(MESSAGES.required),
  lastName: string().nonempty(MESSAGES.required),
  email: string().nonempty(MESSAGES.required).email(MESSAGES.email),
  password: string().nonempty(MESSAGES.required),
  passwordConfirm: string().nonempty(MESSAGES.required),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "These passwords do not match. Try again?",
});

export default function SignUpWithPassword({ onSignInClick }) {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(registerSchema),
  });

  const onSubmitHandler = (values) => {
    const { firstName, lastName, email, password } = values;
    createUserWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
        sendEmailVerification(user, signUpSettings);
      })
      .catch(() => {
        dispatch(
          serverErrorNotification({
            message: "Unsuccessful registration. We're on it.",
          })
        );
      });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Stack direction="row" gap={1}>
        <TextField
          {...COMMON_INPUT_PROPS}
          placeholder="First Name"
          error={!!errors["firstName"]}
          helperText={errors["firstName"]?.message}
          {...register("firstName")}
        />
        <TextField
          {...COMMON_INPUT_PROPS}
          placeholder="Last Name"
          error={!!errors["lastName"]}
          helperText={errors["lastName"]?.message}
          {...register("lastName")}
        />
      </Stack>
      <TextField
        {...COMMON_INPUT_PROPS}
        placeholder="Email"
        error={!!errors["email"]}
        helperText={errors["email"]?.message}
        {...register("email")}
      />
      <TextField
        {...COMMON_INPUT_PROPS}
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        error={!!errors["password"]}
        helperText={errors["password"]?.message}
        {...register("password")}
      />
      <TextField
        {...COMMON_INPUT_PROPS}
        placeholder="Confirm Password"
        type={showPassword ? "text" : "password"}
        error={!!errors["passwordConfirm"]}
        helperText={errors["passwordConfirm"]?.message}
        {...register("passwordConfirm")}
      />
      <FormControlLabel
        control={<Checkbox checked={showPassword} />}
        label="Show password"
        onChange={(event) => setShowPassword(event.target.checked)}
      />
      <Button {...COMMON_INPUT_PROPS} variant="contained" type="submit">
        Create Account
      </Button>
      <Policy />
      <Typography variant="subtitle1" align="center" color="text.secondary">
        Already have an account?{" "}
        <Link href="#" onClick={onSignInClick}>
          Sign in now.
        </Link>
      </Typography>
    </Box>
  );
}

function Policy() {
  return (
    <Typography variant="subtitle1" align="center" color="text.secondary">
      By clicking "Create account" or continuing with any method above, you
      agree to{" "}
      <Link href="https://www.tsc.ai/terms-and-conditions">TSC Policy</Link> and{" "}
      <Link href="https://www.tsc.ai/privacy-note">Privacy Policy</Link>.
    </Typography>
  );
}
