import React from "react";
const { Divider } = require("@tsc/component-library/lib/components");

const VerticalDivider = () => (
  <Divider
    orientation="vertical"
    variant="middle"
    flexItem
    sx={{ height: "32px", alignSelf: "center" }}
  />
);

export default VerticalDivider;
