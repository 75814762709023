import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { get, isEqual, set, sortBy, union } from "lodash";

import {
  useGetSuperpromptsQuery,
  useUpdateSuperpromptMutation,
} from "api/superprompts";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import { selectPermissions } from "features/authentication/authenticationSlice";
import { checkGlobalManagementRolePermission } from "utilities/data";

const Generation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSuperprompt, setSelectedSuperprompt] = useState(
    searchParams.get("id")
  );
  const [currentSuperprompt, setCurrentSuperprompt] = useState(undefined);

  const permissions = useSelector(selectPermissions);
  const hasCreatePermission = checkGlobalManagementRolePermission(
    permissions,
    OBJECT_TYPE.SUPERPROMPTS,
    OBJECT_NAME.CREATE
  );

  const { data: { data: superprompts = [] } = {} } = useGetSuperpromptsQuery();
  const [updateSuperprompt] = useUpdateSuperpromptMutation();

  useEffect(() => {
    setCurrentSuperprompt(
      superprompts.find((superprompt) => superprompt.id === selectedSuperprompt)
    );
  }, [selectedSuperprompt, superprompts]);

  const handleSelect = (e, value) => {
    setSelectedSuperprompt(value);
    setSearchParams({ id: value });
  };

  const handleChange = (key, value) => {
    setCurrentSuperprompt({
      ...currentSuperprompt,
      [key]: value,
    });
  };

  const handleSave = () => {
    updateSuperprompt(currentSuperprompt);
  };

  const parseableContext = currentSuperprompt?.context
    ?.match(/{(.*?)}/g)
    ?.reduce((acc, field) => {
      return acc.replaceAll(field, "null");
    }, currentSuperprompt?.context);

  let parsedContext;
  try {
    parsedContext = JSON.parse(parseableContext ?? "{}");
  } catch (e) {}

  return (
    <Page>
      <SubPage>
        <Stack gap={2}>
          <Autocomplete
            fullWidth
            onChange={handleSelect}
            value={selectedSuperprompt ?? ""}
            options={sortBy(superprompts, "id").map(
              (superprompt) => superprompt.id
            )}
            groupBy={(option) =>
              option.substring(0, option.indexOf("_", option.indexOf("_") + 1))
            }
            renderInput={(params) => (
              <TextField {...params} label="Super Prompt" />
            )}
          />
          {currentSuperprompt && (
            <>
              <TextField
                disabled={!hasCreatePermission}
                label="Prompt"
                value={currentSuperprompt.prompt ?? ""}
                multiline
                minRows={20}
                maxRows={20}
                placeholder="Type your prompt here. Use {variable_name} for variables to be inserted in the prompt."
                onChange={(e) => handleChange("prompt", e.target.value)}
              />
              <Stack direction="row" gap={1} alignItems="baseline">
                <Autocomplete
                  disabled={!hasCreatePermission}
                  freeSolo
                  multiple
                  fullWidth
                  value={currentSuperprompt.requiredFields ?? []}
                  options={[]}
                  onChange={(_, value) => handleChange("requiredFields", value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Required Fields"
                      multiline
                      maxRows={1}
                      minRows={1}
                    />
                  )}
                />
                <Autocomplete
                  disabled={!hasCreatePermission}
                  freeSolo
                  multiple
                  fullWidth
                  value={currentSuperprompt.interpolateFields ?? []}
                  options={[]}
                  onChange={(_, value) =>
                    handleChange("interpolateFields", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fields to interpolate"
                      multiline
                      maxRows={1}
                      minRows={1}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" gap={1} alignItems="baseline">
                <Box flex={1} flexGrow={1}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    component={Box}
                    sx={{ m: 1 }}
                  >
                    Context
                  </Typography>
                  <Box border="1px solid #000000de">
                    <CodeEditor
                      value={currentSuperprompt.context ?? ""}
                      disabled={!hasCreatePermission}
                      language="json"
                      placeholder="Please enter JSON code."
                      onChange={(e) => handleChange("context", e.target.value)}
                      padding={15}
                      style={{
                        backgroundColor: "#ffffff",
                        fontFamily:
                          "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                      }}
                    />
                  </Box>
                </Box>
                <Box flex={1} flexGrow={1}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    component={Box}
                    sx={{ m: 1 }}
                  >
                    Make sure the frontend sends:
                  </Typography>
                  <Typography variant="caption" component={Box} sx={{ m: 1 }}>
                    <pre>
                      {parsedContext
                        ? JSON.stringify(
                            union(
                              currentSuperprompt.prompt
                                ?.match(/{(.*?)}/g)
                                ?.map((field) => field.slice(1, -1)),
                              currentSuperprompt.context
                                ?.match(/{(.*?)}/g)
                                ?.map((field) => field.slice(1, -1)),
                              currentSuperprompt.requiredFields,
                              currentSuperprompt.interpolateFields
                            )
                              .filter(
                                (field) => !get(parsedContext, field)?.tool
                              )
                              .reduce((acc, field) => {
                                if (!get(acc, field)) {
                                  set(acc, field, null);
                                }
                                return acc;
                              }, {}),
                            null,
                            2
                          )
                        : "Invalid context"}
                    </pre>
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" gap={1} alignItems="baseline">
                <Box flexGrow={1} />
                <Button
                  disabled={
                    !hasCreatePermission ||
                    isEqual(
                      currentSuperprompt,
                      superprompts.find(
                        (superprompt) => superprompt.id === selectedSuperprompt
                      )
                    ) ||
                    !parsedContext
                  }
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </SubPage>
    </Page>
  );
};

export default Generation;
