import { isNumber } from "lodash";

/**
 * To get the number of decimal places.
 * @param {number|string} value
 * @returns Number of decimal places.
 */
export function getNumberOfDecimal(value) {
  const stringValue = typeof value === "number" ? value.toString() : value;
  if (!isNumber(Number(stringValue))) {
    return;
  }
  return stringValue.split(".")[1]?.length || 0;
}

/**
 * Check if a value is a positive integer
 * @param {*} value Any type of data
 */
export function checkPositiveInteger(value) {
  if (Number.isInteger(value) && value > 0) {
    return true;
  }
  return false;
}
