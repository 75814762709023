import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signOut } from "firebase/auth";

import { auth } from "../../configurations/firebase";

const initialState = {
  user: null,
  userLoaded: false,
  dialogOpen: false,
  permissions: [],
  permissionsLoaded: false,
};

export const signout = createAsyncThunk(
  "authentication/signout",
  async (_, { dispatch, getState }) => {
    signOut(auth);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser: (state, { payload: user }) => {
      state.user = user;
      state.userLoaded = true;
    },
    setPermissions: (state, { payload: { permissions } }) => {
      state.permissions = permissions;
      state.permissionsLoaded = true;
    },
    openSigninDialog: (state) => {
      state.dialogOpen = true;
    },
    closeSigninDialog: (state) => {
      state.dialogOpen = false;
    },
  },
});

export const { setUser, setPermissions, openSigninDialog, closeSigninDialog } =
  authenticationSlice.actions;

export const selectUser = (state) => state.authentication.user;
export const selectUserLoaded = (state) => state.authentication.userLoaded;
export const selectPermissionsLoaded = (state) =>
  state.authentication.permissionsLoaded;
export const selectSignInDialogOpen = (state) =>
  state.authentication.dialogOpen;
export const selectPermissions = (state) => state.authentication.permissions;

export default authenticationSlice.reducer;
