import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logout, Person, Settings } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@tsc/component-library/lib/components";

import {
  selectUser,
  signout,
} from "../../features/authentication/authenticationSlice";
export const topMenuHeight = "64px";

const ProfileMenu = ({ anchor, open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  return (
    <>
      <PopperMenu
        anchorEl={anchor}
        id="account-menu"
        disablePortal
        open={open}
        onClose={onClose}
        onClick={onClose}
        placement="bottom-end"
      >
        <MenuList>
          <Box px={2} width="220px">
            <Typography variant="body1">{user?.displayName}</Typography>
            <Typography variant="body2" color="text.secondary">
              {user?.email}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <MenuItem disabled onClick={onClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem disabled onClick={onClose}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            Person
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => dispatch(signout())}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </MenuList>
      </PopperMenu>
    </>
  );
};

export default ProfileMenu;

const PopperMenu = ({ open, onClose, children, ...props }) => {
  return (
    <Popper open={open} transition {...props}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
          <Paper elevation={8}>
            <ClickAwayListener onClickAway={onClose}>
              {children}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
