import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@tsc/component-library/lib/components";
import { getWidthHeight } from "@tsc/component-library/lib/utilities/avatar";
import { STAKEHOLDER_CLASS } from "enums/stakeholderClass";
import { upperFirst } from "lodash";

import StakeholderCuratedIcon from "assets/icons/StakeholderCuratedIcon";
import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { NON_BREAKABLE_SPACE } from "utilities/data";
import { getStakeholderAttribute } from "utilities/stakeholder";

const StakeholderCardItem = ({
  stakeholder,
  isFetching,
  avatarSize = "small",
  showLink = false,
  openNewTab = false,
  hideHeadline,
  actionComponent = null,
}) => {
  const [hovered, setHovered] = useState(false);

  if (isFetching) {
    return <StakeholderCardItemSkeleton avatarSize={avatarSize} />;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      height="48px"
      data-testid="stakeholderRow"
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <StakeholderAvatar value={stakeholder} size={avatarSize} />
        <Stack alignItems="flex-start">
          <StakeholderName
            stakeholder={stakeholder}
            showLink={showLink}
            openNewTab={openNewTab}
          />
          {!hideHeadline && <StakeholderHeadline stakeholder={stakeholder} />}
        </Stack>
      </Stack>
      {hovered && actionComponent}
    </Stack>
  );
};

const StakeholderCardItemSkeleton = ({ avatarSize }) => {
  const avatarWidthHeight = getWidthHeight(avatarSize);
  return (
    <Stack direction="row" columnGap={2}>
      <Skeleton
        variant="rounded"
        height={avatarWidthHeight}
        width={avatarWidthHeight}
      />
      <Stack rowGap={0.75} flexGrow={1}>
        <Skeleton variant="rounded" height={16} width={150} />
        <Skeleton variant="rounded" height={12} />
      </Stack>
    </Stack>
  );
};

export default StakeholderCardItem;

export const StakeholderName = ({ stakeholder, showLink, openNewTab }) => {
  const name = getStakeholderAttribute(stakeholder, "name");
  return (
    <Typography
      variant="subtitle1"
      sx={{
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
      className="notranslate"
    >
      {showLink && (
        <Link
          data-testid="stakeholderRowLink"
          target={openNewTab ? "_blank" : "_self"}
          component={RouterLink}
          to={`/stakeholders/${stakeholder.id}`}
          color="primary"
        >
          {name ?? NON_BREAKABLE_SPACE}
        </Link>
      )}
      {!showLink && (name ?? NON_BREAKABLE_SPACE)}
      {stakeholder?.class === STAKEHOLDER_CLASS.MANUAL && (
        <Tooltip title="Curated by TSC analyst">
          <StakeholderCuratedIcon
            sx={{ ml: 0.5, fontSize: 16, verticalAlign: "middle" }}
          />
        </Tooltip>
      )}
    </Typography>
  );
};

export const StakeholderHeadline = ({ stakeholder }) => {
  const headline = getStakeholderAttribute(stakeholder, "headline");
  if (!headline) return null;
  return (
    <Typography
      color="text.secondary"
      variant="caption"
      sx={{
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
      title={upperFirst(headline)}
    >
      {upperFirst(headline)}&nbsp;
    </Typography>
  );
};
