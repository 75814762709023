import api, { TAG_TYPES } from "api";

const API_ROUTE = "chat/api/v1";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSuperprompts: build.query({
      query: () => `${API_ROUTE}/superprompts`,
      providesTags: (result, error) => [
        { type: TAG_TYPES.SUPERPROMPTS, id: "ALL" },
      ],
    }),
    updateSuperprompt: build.mutation({
      query: (data) => ({
        url: `${API_ROUTE}/superprompts/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        }
        return [{ type: TAG_TYPES.SUPERPROMPTS, id: "ALL" }];
      },
    }),
  }),
});

export const { useGetSuperpromptsQuery, useUpdateSuperpromptMutation } =
  extendedApi;
