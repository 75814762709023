import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import keyMirror from "keymirror";

import { auth } from "configurations/firebase";
import {
  accessDeniedNotification,
  serverErrorNotification,
} from "features/notifications/notificationSlice";
import { backendAddress } from "utilities/env";

export const TAG_TYPES = keyMirror({
  ORGANIZATION: null,
  ISSUES: null,
  MEDIA: null,
  ROLE: null,
  USER: null,
  ORGANIZATION_ROLE: null,
  ORGANIZATION_USER: null,
  FEATURE_FLAG: null,
  SUPERPROMPTS: null,
});

export const TAG_IDS = keyMirror({ LIST: null });

const defaultFetchQuery = fetchBaseQuery({
  baseUrl: backendAddress(),
  prepareHeaders: async (headers) => {
    const token = await auth.currentUser.getIdToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Initialize an empty api service that we'll inject endpoints into later as needed
const genFiveApi = createApi({
  reducerPath: "genFiveApi",
  baseQuery: async (args, api, extraOptions) => {
    const result = await defaultFetchQuery(args, api, extraOptions);
    if (result.error) {
      if (result.error?.status >= 500) {
        api.dispatch(serverErrorNotification());
      } else if (result.error?.status === 403) {
        api.dispatch(accessDeniedNotification());
      } else if (
        result.error?.status >= 400 &&
        result.error?.status !== 404 &&
        result.error?.status !== 401
      ) {
        api.dispatch(serverErrorNotification(result.error?.data));
      }
    }
    return result;
  },
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});

export default genFiveApi;
